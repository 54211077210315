import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { Collapse } from "react-bootstrap";
import { CollapseComponentContainer, CollapseLine } from "./styles";

interface ICollapseCompound {
  children: JSX.Element;
  isOpen?: boolean;
  toggleCollapse: Function;
  title: string;
}

export function CollapseComponent({
  isOpen,
  toggleCollapse,
  children,
  title
}: ICollapseCompound) {
  return (
    <>
      <CollapseComponentContainer>
        <h5>{title}</h5>
        <CollapseLine title={`TEste`}>
          {isOpen ? (
            <IoIosArrowDropup
              aria-controls="collapse"
              onClick={() => toggleCollapse()}
            />
          ) : (
            <IoIosArrowDropdown
              aria-controls="collapse"
              onClick={() => toggleCollapse()}
            />
          )}
        </CollapseLine>
      </CollapseComponentContainer>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
}

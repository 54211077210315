import { useMemo } from "react";
import { IChart } from "../../../store/interfaces";
import { IAnalysis } from "../AnalysisTools/types";
import useChartAnalysis from "../useChartAnalysis";
import queryString from "../../../utils/queryString";
import { Loading } from "../../../components/Loading";
import LuminaStatus from "../../../components/charts/LuminaStatus";
import { EmptyChart } from "../styles";
import { RiFileChartLine } from "react-icons/ri";
import { Regression } from "../Regression";
import { CascadeGraph } from "../../../components/charts/cascade";
import { ColumnChart } from "../../../components/charts/ColumnChart";
import DynamicChart from "../../../components/charts/DynamicChart";
import { ISerieControl } from "../../../hooks/useSeriesControl";
import { Chart } from "../../../components/charts/chart";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface IAnalysisChartRender {
  chart: IChart;
  analysis: IAnalysis;
  isLoading?: boolean;
  positionId?: any;
  chartData: any;
  seriesControl: ISerieControl[];
  selectedSerie: any;
  setSelectedSerie: (value: any) => void;
  setAnalysis: (value: any) => void;
  viewMode: "LINE" | "COLUMN";
}

export function AnalysisChartRender({
  chart,
  analysis,
  isLoading,
  positionId,
  chartData,
  selectedSerie,
  seriesControl,
  setAnalysis,
  setSelectedSerie,
  viewMode,
}: IAnalysisChartRender) {
  const location = useLocation();
  const { t } = useTranslation();
  const { config } = useChartAnalysis();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
    // eslint-disable-next-line
  }, [location.search]);

  switch (true) {
    case chart.notFound:
      return <></>;
    case isLoading && config.LOADING:
      return <Loading height="100%" />;
    case queryParams.chartName === "LUM-STT":
      return <LuminaStatus positionId={positionId ? +positionId : 0} />;
    case !chartData?.data?.length:
      return (
        <EmptyChart>
          <RiFileChartLine size={45} />
          <span>{t("analysis.emptyGraph")}</span>
        </EmptyChart>
      );
    case analysis?.regression:
      return <Regression analysis={analysis} />;
    case analysis?.cascade:
      return (
        <CascadeGraph
          chart={chart}
          selectDate={
            queryParams.txDate ? +queryParams.txDate : new Date().getTime()
          }
          selectedSerie={selectedSerie}
        />
      );
    case viewMode === "COLUMN":
      return (
        <ColumnChart
          chart={chart}
          chartData={chartData}
          seriesControl={seriesControl}
        />
      );
    case chart?.type === "DYNAMIC":
      return (
        <DynamicChart
          chart={chart}
          chartData={chartData}
          seriesControl={seriesControl}
          analysis={analysis}
          setAnalysis={setAnalysis}
          date={new Date(
            queryParams?.txDate ? +queryParams.txDate : new Date(),
          ).getTime()}
        />
      );
    default:
      return (
        <Chart
          chart={chart}
          chartData={chartData}
          seriesControl={seriesControl}
          analysis={analysis}
        />
      );
  }
}

import { Form } from "formik";
import styled, { css } from "styled-components";
import { ButtonSave } from "../../components/Modal/styles";

export const Body = styled(Form)`
  padding: 1.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  h2 {
    ${(props) => props.theme.fonts.primary.H3SemiBold};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
`;

export const AdviseContent = styled.div`
  text-align: center;
  span {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }
  span:first-child {
    color: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    color: ${(props) => props.theme.colors.iconsSecondary};
    background-color: ${(props) =>
      props.theme.colors.inputBackground} !important;
  }

  & > div {
    label {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
    align-items: flex-start;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button:first-child {
    span {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;


export const SaveButton = styled(ButtonSave)`
 
 ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    `}
`

import { useTranslation } from "react-i18next";
import Frame from "../../../components/frame";
import { FrameBodyMain } from "../../../components/frame/styles";
import { SidebarConfiguration } from "../../../components/SidebarConfiguration";
import {
  AssetTypesContainer,
  AssetTypesContent,
  AssetTypesHeader,
} from "./styles";
import SimpleTable from "../../../components/SimpleTable";
import { ISimpleTableRecord } from "../../../components/SimpleTable/types";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import { AssetTypeRegister } from "./AssetTypeRegister";
import { MdAdd, MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import RoundIconButton from "../../../components/RoundIconButton";
import { isMobile, isMobileOnly } from "react-device-detect";
import useTranslator from "../../../hooks/useTranslator";
import { success } from "../../../components/Toast";

interface IAssetType {
  url: string;
  name: string;
  countDiagnostics?: number;
  contAssets?: number;
  origin?: "SYSTEM" | "CUSTOM";
  companyName?: string;
}

export function AssetTypes() {
  const [register, setRegister] = useState({
    show: false,
    originId: 0,
    assetTypeId: 0,
  });
  const [data, setData] = useState<IAssetType[]>([]);

  const { t } = useTranslation();
  const translator = useTranslator("AssetTypePage");
  const { request, processing } = useApi({ path: "/asset-types" });

  function loadAssetTypes() {
    request({ method: "get" }).then((response: any) => {
      setData(response);
    });
  }

  useEffect(() => {
    loadAssetTypes();
  }, []);

  const handleDelete = (record: any) => {
    request({ method: "del", pathParameters: record.id }).then(() => {
      success(`${t("AssetTypePage.successMessageDelete")}`);
      loadAssetTypes();
    });
  };

  const tableResources: any = {
    properties: {
      image: {
        id: "image",
        type: "IMAGE",
        label: t("dictionary.labels.Image"),
        component: {
          list: ({ record, id }: ISimpleTableRecord) => (
            record.imageUrl && (
            <img style={{ width: "100px", height: "100px" }} src={record.imageUrl || ""} id={id} />
            ) || <div style={{ width: "100px", height: "100px" }} id={id} />
          ),
        },
        isVisible: {
          list: true,
          new: true,
          edit: true,
        },
      },
      name: {
        id: "name",
        type: "TEXT",
        label: t("dictionary.labels.Name"),
      },
      coundDiagnostics: {
        id: "countDiagnostics",
        type: "TEXT",
        label: t("AssetTypePage.Diagnostics"),
        isVisible: {
          list: !isMobile,
        },
      },
      countAssets: {
        id: "countAssets",
        type: "TEXT",
        label: t("dictionary.labels.Assets"),
        isVisible: {
          list: !isMobile,
        },
      },
      // origin: {
      //   id: "origin",
      //   type: "TEXT",
      //   label: "Origem",
      // },
      // companyName: {
      //   id: "companyName",
      //   type: "TEXT",
      //   label: "Empresa",
      // },
      actions: {
        id: "actions",
        component: {
          list: ({ record }: ISimpleTableRecord) => (
            <div
              style={{ gap: "16px", flexDirection: "row", width: "60px" }}
              className="flex gap_1"
            >
              <RoundIconButton
                handleClick={() =>
                  setRegister({
                    show: true,
                    assetTypeId: record.id,
                    originId: 0,
                  })
                }
              >
                <MdEdit />
              </RoundIconButton>
              <RoundIconButton handleClick={() => handleDelete(record)}>
                <IoMdTrash />
              </RoundIconButton>
            </div>
          ),
        },
      },
    },
  };

  const handleRegister = () => {
    setRegister({ show: true, assetTypeId: 0, originId: 0 });
  };

  if (register.show) {
    return (
      <Frame hasMargin={false}>
        <FrameBodyMain>
          <SidebarConfiguration />
          <AssetTypesContainer visible backgroundWhite>
            <AssetTypesHeader>
              <h2>{translator("Asset Types")}</h2>
            </AssetTypesHeader>
            <AssetTypesContent>
              <AssetTypeRegister
                assetTypeId={register.assetTypeId}
                setRegister={setRegister}
                refresh={loadAssetTypes}
              />
            </AssetTypesContent>
          </AssetTypesContainer>
        </FrameBodyMain>
      </Frame>
    );
  }

  return (
    <Frame hasMargin={false}>
      <FrameBodyMain>
        <SidebarConfiguration />
        <AssetTypesContainer visible backgroundWhite>
          <AssetTypesHeader>
            <h2>{translator("Asset Types")}</h2>
            <div className="flex gap_1">
              <button onClick={handleRegister}>
                {isMobileOnly ? <MdAdd size={32}/> : translator("New Type")}
              </button>
            </div>
          </AssetTypesHeader>
          <AssetTypesContent>
            <SimpleTable
              data={data}
              resource={tableResources}
              isLoading={processing}
            />
          </AssetTypesContent>
        </AssetTypesContainer>
      </FrameBodyMain>
    </Frame>
  );
}

import styled from "styled-components";

export const DiagnosticDrawerAddButtonWrapper = styled.div`
  background-color: transparent;
  & > button {
    position: absolute !important;
    right: 24px;
    top: 28px;
  }
`;

export const DiagnosticListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    margin-bottom: 1px;
  }
  & > div:last-child {
    display: flex;
    gap: 8px;
  }

  button {
    height: 25px;
    width: 25px;
    padding: 0.5px;
    border-radius: 4px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.cardHighlight};
    }

    & > svg {
      font-size: 14px;
    }
  }
`;

export const DiagnosticListLabel = styled.h5`
  ${({ theme }) => theme.fonts.primary.H3SemiBold};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  margin: 0;
  line-height: 16px;
`;

export const DiagnosticListButton = styled.button``;

import styled, { css } from "styled-components";

export const TitleMonitoring = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > div {
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > button {
    }
  }

  svg {
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      gap: 0.8rem;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        color: ${(props) => props.theme.colors.iconsSecondary};
        border-radius: 0.3rem;
        background-color: transparent;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

export const SidebarContainer = styled.div<{
  show: boolean;
  assetsLevel?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 75px);
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  /* border-bottom-right-radius: 6px; */
  padding: 10px 5px 10px 5px;
  width: ${({ show }) => (show ? "358px" : "0px")};
  min-width: ${({ show }) => (show ? "358px" : "0px")};
  margin-left: ${({ show }) => (show ? "0.2rem" : "-1rem")};
  margin-right: ${({ show }) => (show ? "0.5rem" : "0")};
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: hidden;

  * > {
    flex-wrap: nowrap;
    span {
      overflow: hidden;
      user-select: none;
      word-break: keep-all;
      word-wrap: unset;
      flex-wrap: nowrap;
      overflow-x: hidden;
      height: 20px;
    }
  }
  /* @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    margin-left: 16px;
    margin-right: 0px;
    width: 298px;

    input,
    select,
    textarea {
      font-size: 16px;
    }
  } */

  @media (max-width: 767px) {
    transition: width 0.2s linear, opacity 0.1s linear;
    margin-left: 30px;
    margin-right: 0px;
    padding: 10px;
    border: 1px solid white;

    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
  /* Tablet Landscape */
  @media screen and (min-width: 827px) and (max-width: 1279px) {
    height: calc(100vh - 150px);
  }
  /* Tablet Portrait */
  @media screen and (min-width: 799px) and (max-width: 826px) {
    height: calc(100vh - 200px);
  }

  ${({ assetsLevel }) =>
    assetsLevel &&
    css`
      @media screen and (min-width: 767px) and (max-width: 1536px) {
        & > div:last-child {
          height: calc(100% + 2rem);
        }
      }
    `}

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    height: calc(100vh - 10.7rem);
  }
`;

export const SidebarInsideTab = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    z-index: 5;
    display: flex;
    bottom: 0;
    width: 100vw;
    height: 100%;
    background: ${(props) => props.theme.colors.cardBackground};
    align-items: center;
    & > div:first-child {
      margin-top: 0px;
      width: 90%;
    }
    & > div:last-child {
      height: 100%;
      padding-bottom: 70px !important;
    }
  }
`;

export const SidebarDesktopToggle = styled.button<{ show?: boolean }>`
  z-index: 5;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cardBackground};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  border: none;
  padding: 4px;
  top: -4px;
  width: 28px !important;
  height: 42px;
  left: ${({ show }) => (show ? 0 : "-28px")};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 0.7s;

  opacity: 0.15;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  border-left: none;

  svg {
    font-size: 20px;
  }

  &:hover {
    opacity: 1;
    transition: 0.3s;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border-left: none;
  }
`;

export const SidebarMobileToggle = styled.div<{ show?: boolean }>`
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.cardBackground};
  padding: 4px;
  top: -4px;
  width: 36px !important;
  height: 42px;
  /* right: ${(props) => (props.show === false ? "0" : "none")}; */
  left: ${({ show }) => (show === false ? "0" : "none")};

  @media screen and (max-width: 767px) {
    z-index: 101;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.cardBackground};
    padding: 4px;
    top: -70px;
    width: 44px !important;
    height: 60px;
    right: ${(props) => (props.show === false ? "0" : "none")};

    svg {
      width: 1.55rem;
      height: auto;
      opacity: 0.75;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

export const SidebarMobile = styled.div<{ visible: boolean }>`
  @media screen and (max-width: 767px) {
    z-index: 12;
    position: fixed;
    display: ${({ visible }) => (visible ? "flex" : "none")};
    top: 3.7rem;
    bottom: 0;
    width: 100vw;
    height: 100%;
    background: ${(props) => props.theme.colors.cardBackground};
    flex-direction: column;
    align-items: center;

    & > div:first-child {
      margin-top: 1.3rem;
      width: 100%;
      height: 2.5rem;
      right: 0;
      z-index: 20;
      transform: rotate(180deg);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      display: none;

      button {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0.2rem;
        margin-left: 0.7rem;
        color: ${(props) => props.theme.colors.iconsSecondary};

        svg {
          width: 1.4rem;
          height: auto;
        }

        &:active {
          background-color: ${(props) => props.theme.colors.cardHighlight};
          border-radius: 2rem;
          padding: 0.2rem;
        }
      }
    }
    & > div:nth-child(2) {
    }

    & > div:last-child {
      & > a:last-child {
        margin-bottom: 100px;
      }
    }
  }

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    display: none;
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
`;
// Ícone para abrir e fechar o sidebar
export const FrameSidebarIcon = styled.div`
  background-color: ${(props) => props.theme.colors.cardHighlight};
  cursor: pointer;
  display: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 1.5em;
  height: 35px;
  width: 24px;
  text-align: center;
  position: absolute;
  top: 16px;
  z-index: 1;
  @media (max-width: 767px) {
    display: none;
    padding-left: 6px;
    padding-right: 6px;
  }
  @media (min-width: 1023px) {
    display: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
  }
`;

export const SidebarTitle = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: -10px;

  & > div:first-child {
    padding-left: 4px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    & > div:first-child {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    & > div:nth-child(2) {
      div:first-child {
        font-size: 1rem;
        height: 28px;
        display: flex;
        align-items: center;
      }
    }
    input {
      font-size: 1rem;
    }
  }
`;

export const AddNewContainer = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    scale: 1.05;
    transition: all 0.3s;
  }

  @media screen and (max-width: 767px) {
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 8px;
    transition: filter 0.2s;

    &:hover {
      background-color: transparent;
      filter: brightness(0.8);
    }

    svg {
      width: 2rem;
      height: auto;
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const SidebarList = styled.div<{ assetsLevel?: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > button:last-child {
    margin-bottom: 88px;
  }

  @media screen and (max-width: 767px) {
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    scrollbar-width: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.passwordBackground};
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
    border-top: 3px solid ${(props) => props.theme.colors.inputBlock};
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    & > a:last-child {
      margin-bottom: 8rem;
    }
  }
`;

export const FrameSidebarFilters = styled.div<{ bolt?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: ${(props) => (props.bolt ? "8px" : null)};
  gap: 5px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

// SIDEBAR ITEM ------------------------------------------------------------------------------------------------------

export const SidebarItem = styled.div<{ sectorColor?: string | null }>`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  padding: 0px;
  max-width: 100%;
  margin: 0 4px;
  height: auto;
  text-align: center;
  display: flex !important;
  justify-content: space-between !important;

  text-decoration: none;

  &:hover {
    border-color: ${(props) => props.theme.colors.textPrimary};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  @media (max-width: 767px) {
    margin-left: 0 !important;
    width: 100vw !important;

    & > &:last-child {
      margin-bottom: 10rem !important;
    }
  }
`;

export const SidebarItemDiv = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  text-align: left;
  width: 100%;
  max-width: 298px;
  margin-left: 8px;
  border-radius: 4px;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 767px) {
    margin-left: 6%;
  }
`;

export const CardDivName = styled.div`
  display: flex;
  height: 25px;
  align-items: center;
  gap: 6px;
  width: 100%;

  label {
    max-width: 260px;
    ${(props) => props.theme.fonts.primary.H4Bold};
    color: ${(props) => props.theme.colors.textPrimary}!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    gap: 3px;
    height: 100%;
    align-items: flex-end;

    div {
      min-width: 25px;
      cursor: pointer;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.iconsSecondary};

      &:hover {
        background-color: ${(props) => props.theme.colors.cardHighlight};
        color: ${(props) => props.theme.colors.ibbxOrange};
        border-radius: 60px;
        cursor: pointer;
      }
    }
  }

  svg {
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 1px;
  }
`;

export const CardDivInfo = styled.div`
  ${(props) => props.theme.fonts.secondary.H1SemiBold};
  color: ${(props) => props.theme.colors.textSecondary};
  height: 14px;
  font-weight: 600px;
  display: flex;
  gap: 15px;
`;

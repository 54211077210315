import { MdOpenInNew } from "react-icons/md";
import {
  SimpleTableCell,
  SimpleTableColumn,
  SimpleTableContainer,
} from "./styles";
import ReactTable from "../ReactTable";
import { Column } from "react-table";

interface ISimpleTable {
  data: any;
  resource: any;
  refresh?: () => void;
  isLoading?: boolean;
  messageWhenTableIsEmpty?: string;
}

const SimpleTable = ({
  data,
  resource,
  isLoading,
  messageWhenTableIsEmpty,
}: ISimpleTable) => {
  const columns: Column<object>[] = [
    ...Object.values(resource.properties)
      .filter(
        (property: any) => !property.isVisible || property.isVisible?.list,
      )
      .map((property: any) => {
        const renderFunction = property.component?.list;
        const formatedValue = property.formatedValue;
        const openLink = property.openLink;

        function handleClick({ record, id }: any) {
          if (openLink) {
            const link = openLink({ record, id });
            window.open(link, "_blank");
          }
        }

        return {
          id: property.id,
          accessor: property.id,
          Header: () => (
            <SimpleTableColumn>
              <span>{property.label}</span>
            </SimpleTableColumn>
          ),
          windth: `${property?.width ?? "112px"}`,
          Cell: ({ value, row }: any) => {
            if (renderFunction) {
              return renderFunction({
                record: row.original,
                id: property.id,
                label: property.label,
              });
            }
            let text = value;

            if (formatedValue) {
              text = formatedValue({
                record: row.original,
                id: property.id,
                label: property.label,
              });
            }

            return (
              <SimpleTableCell>
                {text}{" "}
                {openLink && (
                  <MdOpenInNew
                    onClick={() =>
                      handleClick({ record: row.original, id: property.id })
                    }
                  />
                )}
              </SimpleTableCell>
            );
          },
        };
      }),
  ];

  return (
    <SimpleTableContainer>
      <ReactTable
        responsive
        data={data || []}
        columns={columns}
        isEmpty={!data?.length}
        isLoading={isLoading}
        messageWhenTableIsEmpty={messageWhenTableIsEmpty}
      />
    </SimpleTableContainer>
  );
};

export default SimpleTable;

import { Component } from "react";

const canUseDOM = (): boolean => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export const ZendeskAPI = {
  call: (...args: any[]): void => {
    const maxRetries = 5;
    const retryInterval = 2000;

    const attemptCall = (retries: number) => {
      if (canUseDOM() && (window as any).zE) {
        (window as any).zE.apply(null, args);
      } else {
        if (retries > 0) {
          console.warn(`Zendesk is not initialized yet. Retrying in ${retryInterval / 1000} seconds...`);
          setTimeout(() => attemptCall(retries - 1), retryInterval);
        } else {
          console.error("Zendesk is not initialized after multiple attempts.");
        }
      }
    };

    attemptCall(maxRetries);
  },
  open: (): void => {
    ZendeskAPI.call("messenger", "open");
  },
  close: (): void => {
    ZendeskAPI.call("messenger", "close");
  },
  show: (): void => {
    ZendeskAPI.call("messenger", "show");
  },
  hide: (): void => {
    ZendeskAPI.call("messenger", "hide");
  },
  loginUser: (token: string): void => {
    ZendeskAPI.call("messenger", "loginUser", function (callback: (arg0: any) => void) {
      callback(token);
    });
  }
};

interface ZendeskProps {
  zendeskKey: string;
  defer?: boolean;
  onLoaded?: () => void;
  [key: string]: any;
}

export default class Zendesk extends Component<ZendeskProps> {
  constructor(props: ZendeskProps) {
    super(props);
    this.insertScript = this.insertScript.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
  }

  onScriptLoaded(): void {
    if (typeof this.props.onLoaded === "function") {
      this.props.onLoaded();
    }
  }

  insertScript(zendeskKey: string, defer?: boolean): void {
    const script = document.createElement("script");
    if (defer) {
      script.defer = true;
    } else {
      script.async = true;
    }
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener("load", this.onScriptLoaded);
    document.body.appendChild(script);
  }

  componentDidMount(): void {
    if (canUseDOM() && !(window as any).zE) {
      const { defer, zendeskKey, ...other } = this.props;
      this.insertScript(zendeskKey, defer);
      (window as any).zESettings = other;
    }
  }

  componentWillUnmount(): void {
    if (!canUseDOM() || !(window as any).zE) {
      return;
    }
    delete (window as any).zE;
    delete (window as any).zESettings;
  }

  render(): null {
    return null;
  }
}
import { Formik, FormikProps } from "formik";
import { AnalyticsComponent } from "../../../../../components/Analytics/AnalyticsComponent";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExplanationLabel } from "../../../../../components/ExplanationLabel";
import { FormikTextInput } from "../../../../../components/textInput";
import { DiagnosticForm } from "./styles";
import ModalCompound from "../../../../../components/ModalCompound";
import InputCompound from "../../../../../components/InputCompound";
import useApi from "../../../../../hooks/useApi";
import { CollapseComponent } from "../../../../../components/CollpaseComponent";
import { success } from "../../../../../components/Toast";
import useTranslator from "../../../../../hooks/useTranslator";

interface IDiagnosticDrawerForm {
  form: any;
  setForm: (form: any) => void;
  refresh: () => void;
}

export function DiagnosticDrawerForm({
  form,
  setForm,
  refresh,
}: IDiagnosticDrawerForm) {
  const [values, setValues] = useState({
    nameInPT: "",
    nameInEN: "",
    nameInES: "",
  });
  const [errors, setErrors] = useState({
    nameInPT: "",
    nameInEN: "",
    nameInES: "",
  });

  const { t, i18n } = useTranslation();
  const translator = useTranslator("AssetTypePage");
  const { request, processing } = useApi({ path: "/diagnostics" });

  const handleAdd = () => {
    if (processing) {
      return;
    }

    const pathParameters = form.diagnosticId || undefined;
    const method = form.diagnosticId ? "put" : "post";
    request({ method, pathParameters, body: form }).then(() => {
      success("Sucess");
      refresh();
      setForm({
        ...form,
        diagnosticId: 0,
        nameInPT: "",
        nameInEN: "",
        nameInES: "",
      });
    });
  };

  const handleCancel = () => {
    setForm({
      expanded: false,
      diagnosticId: 0,
      nameInPT: "",
      nameInEN: "",
      nameInES: "",
    });
  };

  return (
    <CollapseComponent
      isOpen={form.expanded}
      toggleCollapse={() => {
        setForm((form: any) => ({ ...form, expanded: !form.expanded }));
      }}
      title={`${translator(form.diagnosticId ? "Edit" : "Add")} ${translator(
        "Diagnostic",
      )}`}
    >
      <DiagnosticForm>
        <InputCompound>
          <InputCompound.Label>
            {translator("Name in Portuguese")}
          </InputCompound.Label>
          <InputCompound.Field
            value={form.nameInPT}
            onChange={({ target }) => {
              setForm((current: any) => ({
                ...current,
                nameInPT: target.value,
              }));
            }}
          />
          {errors && <span className="error">{errors.nameInPT}</span>}
        </InputCompound>
        <InputCompound>
          <InputCompound.Label>
            {translator("Name in English")}
          </InputCompound.Label>
          <InputCompound.Field
            value={form.nameInEN}
            onChange={({ target }) => {
              setForm((current: any) => ({
                ...current,
                nameInEN: target.value,
              }));
            }}
          />
          {errors && <span className="error">{errors.nameInEN}</span>}
        </InputCompound>
        <InputCompound>
          <InputCompound.Label>
            {translator("Name in Spanish")}
          </InputCompound.Label>
          <InputCompound.Field
            value={form.nameInES}
            onChange={({ target }) => {
              setForm((current: any) => ({
                ...current,
                nameInES: target.value,
              }));
            }}
          />
          {errors && <span className="error">{errors.nameInES}</span>}
        </InputCompound>
        <div className="flex space_between">
          <ModalCompound.Cancel handleClose={handleCancel}>
            {translator(form.diagnosticId ? "Cancel" : "Clean")}
          </ModalCompound.Cancel>
          <ModalCompound.Save handleConfirm={handleAdd}>
          {translator(form.diagnosticId ? "Update" : "Add")}
          </ModalCompound.Save>
        </div>
      </DiagnosticForm>
    </CollapseComponent>
  );
}

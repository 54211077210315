import { useField, useFormikContext } from "formik";
import { MdAdd, MdDelete } from "react-icons/md";
import { FormikTextInput } from "../textInput";
import {
  InputListDeleteItem,
  EmptyField,
  InputListRow,
  LabelContainer,
  InputListContainer,
  InputListColumn,
  InputListDeletePlaceholder,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { appSelect } from "../../store/slices/appSlice";

interface IDelete {
  handleClick: () => void;
  disabled?: boolean;
  hoverLabel?: string;
  order?: number;
}

function Delete({ handleClick, disabled, hoverLabel, order }: IDelete) {
  return (
    <InputListDeleteItem
      type="button"
      onClick={handleClick}
      disabled={disabled}
      hoverLabel={hoverLabel}
      order={order}
    >
      <MdDelete />
    </InputListDeleteItem>
  );
}

interface IDeletePlaceholder {
  order?: number;
}

function DeletePlaceholder({ order }: IDeletePlaceholder) {
  return <InputListDeletePlaceholder order={order} />;
}

interface IRow {
  children: JSX.Element | JSX.Element[];
  position?: "relative";
  hidden?: boolean;
}

function Column({ children, hidden }: IRow) {
  return <InputListColumn hidden={hidden}>{children}</InputListColumn>;
}

function Row({ children, position }: IRow) {
  return <InputListRow position={position}>{children}</InputListRow>;
}

interface IRowContainer {
  children: JSX.Element[];
}

function RowContainer({ children }: IRowContainer) {
  return <div style={{ display: "flex", gap: "8px" }}>{children}</div>;
}

interface IInputList {
  name: string;
  empty?: any;
  build?: (value: any, i: number, formRef?: any) => JSX.Element;
  label?: string | JSX.Element;
  labelType?: "h2" | "label";
  limit?: number;
  disabled?: boolean;
  keepDeleted?: boolean;
  neverEmpty?: boolean;
  loading?: boolean;
  reverseOrder?: boolean;
  hideAddButton?: boolean;
}
function InputList({
  label,
  name,
  empty,
  limit,
  labelType = "label",
  neverEmpty,
  disabled,
  build,
  keepDeleted,
  loading,
  reverseOrder,
  hideAddButton,
}: IInputList) {
  const { isReadOnly } = useAppSelector(appSelect);
  const { setFieldValue } = useFormikContext();
  const [{ value: array = [] }] = useField(name);
  function handleAdd() {
    if (!limit?.toString() || array.length < limit) {
      setFieldValue(name, [empty || "", ...(array || [])]);
    }

    if (neverEmpty && array.length === 0) {
      setFieldValue(name, [empty, empty]);
    }
  }

  const { t } = useTranslation();

  function defaultBuild(item: any, index: number) {
    return <FormikTextInput name={name + `[${index}]`} />;
  }

  function showOnEmpty() {
    if (neverEmpty) {
      return build ? build(empty, 0) : defaultBuild(empty, 0);
    }
    return (
      <EmptyField>
        <span>{t("commonText.noRegister")}</span>
      </EmptyField>
    );
  }
  return (
    <InputListContainer>
      {label ? (
        <LabelContainer>
          {labelType === "label" && <label>{label}</label>}
          {labelType === "h2" && <h2>{label}</h2>}
          {!isReadOnly && !hideAddButton && (
            <MdAdd size={24} onClick={handleAdd} />
          )}
        </LabelContainer>
      ) : (
        !isReadOnly && !hideAddButton && <MdAdd size={24} onClick={handleAdd} />
      )}
      {!array.length && showOnEmpty()}
      {reverseOrder
        ? array.map(build ?? defaultBuild).reverse()
        : array.map(build ?? defaultBuild)}
    </InputListContainer>
  );
}

InputList.Delete = Delete;
InputList.Row = Row;
InputList.RowContainer = RowContainer;
InputList.Column = Column;
InputList.DeletePlaceholder = DeletePlaceholder;
export default InputList;

import React, { useEffect, useMemo, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "./utils/queryString";
import CryptoJS from "crypto-js";
import "./i18n";

// Store
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  appSelect,
  appSetProcessing,
  appSetCurrentDarkMode,
  setDefaultLogo,
  setColorTheme,
  setShowAnnouncement,
  setApps,
  setAppLoaded,
} from "./store/slices/appSlice";
import { userSession, userSelect, userLogin } from "./store/slices/userSlice";

// Styled
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/global";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";

// Pages
import { AppLoading } from "./components/Loading";
import { Toast } from "./components/Toast";
import useApi from "./hooks/useApi";
import { NewRelease } from "./components/NewRelease";
import { Routes } from "./routes/Routes";
import { RetinaChat } from "./components/RetinaChat";
import { getUrlPattern } from "./utils/getUrlPattern";
import useQueryParams from "./hooks/useQueryParams";
import { isMobile } from "react-device-detect";
import { useApps } from "./pages/Configuration/CompanyDetails/CompanyApps/useCases";
import useApp from "./hooks/useApp";
import useUser from "./hooks/useUser";
import { getScreenResolution } from "./utils/mediaQuery";
import { useSegment } from "./hooks/useSegmentTrackEvent ";
import {
  getUserHomeUrl,
  isUserClient,
  isUserRepresentative,
} from "./utils/userUtils";
import { IUserSlice } from "./store/interfaces";
import { MissingInformationModal } from "./modals/MissingInformationModal";
import Zendesk, { ZendeskAPI } from "./components/ZendexConfig";

function App() {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);

  const { handleTrackEvent } = useSegment();

  const screenResolution = getScreenResolution();

  const { request: requestLogo } = useApi({ path: "/representative-theme" });

  const {
    loadNotifications,
    loadVersion,
    loadUser,
    loadAnnouncements,
    shouldOpenMissingInformationModal,
    handleCloseMissingInformationModal,
    shouldShowMissingInformationModal,
  } = useUser();

  const { queryParams } = useQueryParams();

  const [announcementInformation, setAnnouncementInformation] = useState<any>();

  function loadRepresentative() {
    requestLogo({ method: "get" }).then(({ logoUrl, theme }) => {
      dispatch(setDefaultLogo(logoUrl));
      dispatch(setColorTheme(theme));
    });
  }

  useEffect(() => {
    if (!user) return;

    const interval = setInterval(() => {
      loadVersion();
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { retrieveAppsOnLoad } = useApps({ user });

  const loadApp = (user: IUserSlice) => {
    retrieveAppsOnLoad(user)
      .then((response) => {
        dispatch(setApps(response));
      })
      .finally(() => {
        dispatch(appSetProcessing(false));
      });
  };

  useEffect(() => {
    if (user.id && user.terms) {
      Promise.all([
        loadAnnouncements(setAnnouncementInformation),
        loadNotifications(),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, user.terms]);

  // Antes de carregar as rotas, verifica se o usuário está logado e se aceitou os termos de uso
  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    dispatch(userSession())
      .unwrap() // verifica se tem usuário ativo no localStorage
      .then((user) => {
        if (!user.terms) {
          navigate("/terms");
          dispatch(appSetProcessing(false));
        } else {
          loadUser(user).then((response) => {
            if (isUserRepresentative(user.profile)) {
              loadRepresentative();
            }

            if (isUserClient(user.profile)) {
              loadApp(user);
            }

            shouldOpenMissingInformationModal(response);

            dispatch(
              setAppLoaded({
                homeUrl: getUserHomeUrl({
                  ...user,
                  user: response,
                }),
              }),
            );
          });

          handleTrackEvent({
            event: "Dispositivo: ",
            screenResolution,
          });
        }
      })
      .catch((err) => {
        if (queryParams?.token) {
          const bytes = CryptoJS.AES.decrypt(
            queryParams?.token as string,
            process.env.REACT_APP_RETINA_MANUSIS_KEY as string,
          );
          const params = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          dispatch(
            userLogin({
              email: params.email as string,
              password: params.password as string,
            }),
          )
            .unwrap()
            .then(() => {
              // navigate("/");
              dispatch(appSetProcessing(false));
              handleTrackEvent({
                event: "Dispositivo: ",
                screenResolution,
              });
            });
        } else {
          dispatch(appSetProcessing(false));
        }
      });

    dispatch(appSetCurrentDarkMode());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (announcementInformation?.read === false) {
      dispatch(setShowAnnouncement(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementInformation]);

  const { loadDiagnostics } = useApp();

  useEffect(() => {
    if (user.id && user.terms) {
      loadDiagnostics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user.id]);

  const params = useParams();

  const shouldRenderRetinaChart = useMemo(() => {
    const isUserNotAllowed = !user.id || !user.terms;
    const isAppNotLoaded = !!app.processing;
    const isEmbeded = !!queryParams.embeded;
    const isRunningLocal = process.env.REACT_APP_IS_LOCAL === "true";

    if (isUserNotAllowed || isAppNotLoaded || isEmbeded || isRunningLocal) {
      return false;
    }

    return true;
  }, [app, user]);

  const isRetinaChartVisible = useMemo(() => {
    // eslint-disable-next-line
    const urlPattern = getUrlPattern(location, params);

    if (urlPattern === "/hub") {
      return false;
    }

    if (urlPattern.includes("analysis")) {
      return false;
    }

    if (
      queryParams?.tab === "occurrences" ||
      queryParams?.tab === "assetoccurrences" ||
      queryParams?.tab === "status"
    ) {
      return false;
    }
    if (queryParams?.tab === "maps") {
      return false;
    }

    return true;
    // eslint-disable-next-line
  }, [location, params]);

  const isDevelopment = process.env.REACT_APP_ENV === "development";

  useEffect(() => {
    if (location.pathname !== "/help") {
      ZendeskAPI.hide();
    }
  }, [location.pathname]);

  const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || "";

  function onZendeskLoad() {
    ZendeskAPI.hide();
  }

  return (
    <>
      <ThemeProvider theme={app.darkMode ? dark : light}>
        <GlobalStyle />

        <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={onZendeskLoad} />

        {app.processing ? <AppLoading /> : <Routes />}
        <Toast />
        <NewRelease
          announcements={announcementInformation}
          show={app.showAnnouncement}
        />
          <MissingInformationModal
            show={shouldShowMissingInformationModal}
            handleClose={handleCloseMissingInformationModal}
          />
        {shouldRenderRetinaChart && (
          <RetinaChat
            isVisible={isRetinaChartVisible && app.showRetinaChatButton}
          />
        )}
      </ThemeProvider>
    </>
  );
}

export default App;

import styled from "styled-components";
import { HeaderDiv, ProfileScreen } from "../Profile/styles";
import {
  ConfigurationPagesContent,
  FrameBodyMain,
} from "../../../components/frame/styles";

export const FrameBodyMyAPI = styled(FrameBodyMain)`
  height: 100%;
  padding: 0;
`;

export const MyApiArea = styled(ConfigurationPagesContent)`
  padding: 2% 4% 4%;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};

  @media screen and (max-width: 767px) {
    opacity: 1;
    padding: 4px;
    margin-left: 0;

    & > div {
      & > div:nth-child(2) {
        div {
          font-size: 1.2rem;
        }
        & > div:first-child {
          margin-left: 1rem;
          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        }
        & > div:last-child {
          margin-left: 0;
        }
      }
    }
  }
`;

export const ContainerMyAPI = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
  }
`;

export const Header = styled(HeaderDiv)`
  width: 100%;

  @media screen and (max-width: 767px) {
    /* margin-bottom: 0; */
  }
`;

export const Content = styled(ProfileScreen)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-top: 16px;
  padding-bottom: 32px;

  table {
    width: 100%;
  }

  h2 {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    font-size: 1.25rem;
    width: 90%;
    margin: 0;
  }

  p {
    ${({ theme }) => theme.fonts.primary.H4Regular};
    margin: 0;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 1rem;
  }

  .method {
    width: 5%;
  }

  .trigger {
    width: 20%;
  }

  .url {
    width: 30%;
  }

  .auth {
    width: 30%;
  }
  .action {
    width: 5%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 1.75rem;
    padding: 1.5rem 1rem;

    justify-content: none;
    h2 {
      font-size: 1.25rem;
      width: 100%;
    }
    & > div {
      width: 100% !important;
    }

    & > button {
      padding: 0.5rem 1.75rem;
      font-size: 1.25rem;
    }
  }
`;

export const ClipBoardToCopy = styled.div<{ copyToClipboard?: boolean }>`
  position: relative;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.inputBlock};
  border: 1px solid
    ${({ copyToClipboard, theme }) =>
      copyToClipboard ? theme.colors.ibbxOrange : "transparent"};
  cursor: pointer;
  gap: 1rem;
  padding: 1.5rem;
  position: relative;
  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.iconsPrimary};
    opacity: 0.7;
    svg {
      width: 1.25rem;
      height: auto;
    }

    &:hover {
      transition: all 0.3s ease;
      scale: 1.1;
      opacity: 1;
    }
  }

  span {
    ${({ theme }) => theme.fonts.primary.H5Regular};
    position: absolute;
    bottom: 0.5rem;
    color: ${(props) => props.theme.colors.ibbxOrange};
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  color: ${(props) => props.theme.colors.iconsPrimary};

  & > span {
    margin-top: 12px;
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
    text-align: center;
  }

  & > div {
    ${(props) => ({ ...props.theme.fonts.primary.H4Regular })};
    display: flex;
    flex: 1;
    gap: 2rem;
    justify-content: center;
    padding: 2rem 1rem 1rem;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > div:last-child {
        border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
        padding: 1rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20rem;
        height: fit-content;
        box-shadow: inset 4px 4px 18px -3px rgba(0, 0, 0, 0.2);

        svg {
          width: 48px;
          height: auto;
          margin-bottom: 8px;
          color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
        }

        span {
          text-align: center;
          white-space: normal;
          ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
          font-weight: 400;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1197px) {
      flex-direction: column;
    }
  }

  .form-check-input[type="checkbox"] {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    cursor: pointer;
  }
  .form-check-input {
    scale: 1.3 !important;
  }

  .form-check-input[type="checkbox"]:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange};
    border: transparent;
    stroke: red;
  }

  .form-check-input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem rgb(243, 112, 53, 0.25);
    border-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  }

  @media screen and (max-width: 767px) {
    padding: 0 1rem;
    & > div {
      flex-direction: column;

      & > div > div:last-child {
        width: 100%;
        margin-top: 0.5rem;

        span {
          width: 80%;
        }
      }
    }
    .form-check-input {
      scale: 1.2 !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1197px) {
    padding: 0 1rem;
    & > span {
      line-height: 1.5rem;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 1rem;
    margin-top: 0.5rem;

    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  border: none;
  background: none !important;

  & > div {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
`;

export const TableHead = styled.thead`
  width: 100%;
  height: 3rem;
  ${({ theme }) => theme.fonts.primary.H5Bold};
  background: ${({ theme }) => theme.colors.headerBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};

  th {
    padding: 8px 20px 8px 14px;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  ${({ theme }) => theme.fonts.primary.H4Regular};
  font-size: 0.75rem;

  td {
    padding: 8px 18px;

    & > div {
      max-width: 26rem;
      max-height: 3.5rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.cardBackground};
  }
`;

export const TriggerHeader = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: ${(props) => props.theme.colors.ibbxOrange};
  border-radius: 100%;
  border: none;
  color: white;

  svg {
    width: 22px;
    height: auto;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: none;
  background: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
`;

export const EmptyContainer = styled.div`
  ${(props) => props.theme.fonts.primary.H3SemiBold};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
`;

export const APITabContainer = styled(ProfileScreen)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-top: 16px;
  padding-top: 25px;
  gap: 25px;

  h2 {
    ${({ theme }) => theme.fonts.primary.H2Bold};
    font-size: 1.25rem;
    width: 90%;
    margin: 0;
  }

  p {
    ${({ theme }) => theme.fonts.primary.H4Regular};
    margin: 0;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 1rem;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 1.75rem;
    padding: 1.5rem 1rem;

    justify-content: none;
    h2 {
      font-size: 1.25rem;
      width: 100%;
    }
    & > div {
      width: 100% !important;
    }

    & > button {
      padding: 0.5rem 1.75rem;
      font-size: 1.25rem;
    }
  }
`;

export const SecondTableContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  table {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }
`;

export const DocContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`;

export const DocRedirect = styled.button`
  margin-left: 0.5rem;
  border: none;
  background: none;
  color: ${(props) => props.theme.colors.iconsSecondary};
  transition: 0.14s ease-in-out;

  :hover {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
`;

import { useEffect, useMemo } from "react";
import useQueryParams from "./useQueryParams";
import { userSelect } from "../store/slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { isUserIbbx } from "../utils/userUtils";

interface IuseAnalysisOwner {
  loadStorage?: boolean;
  triggerOnChange?: Function;
}

export default function useAnalysisOwner({
  loadStorage,
  triggerOnChange = () => null,
}: IuseAnalysisOwner) {
  const user = useAppSelector(userSelect);
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    if (localStorage.getItem("analysisOwner") && isUserIbbx(user.profile)) {
      const analysisOwner: any = getAnalysisOwner();
      analysisOwner[user.id] = localStorage.getItem("analysisOwner");
      localStorage.setItem(
        "analysisOwnerByUser",
        JSON.stringify(analysisOwner),
      );
      localStorage.removeItem("analysisOwner");
    } else if (localStorage.getItem("analysisOwner")) {
      localStorage.removeItem("analysisOwner");
    }

    const shouldUpdateQueryString = loadStorage && !queryParams.analisysOwner;
    const analysisOwner = getAnalysisOwner();

    if (shouldUpdateQueryString && analysisOwner[user.id]) {
      setQueryParams({
        ...queryParams,
        analysisOwner: analysisOwner[user.id],
      });
    }
    // eslint-disable-next-line
  }, []);

  function getAnalysisOwner() {
    const analysisOwnerAsString = localStorage.getItem("analysisOwnerByUser");
    try {
      const analysisOwner = JSON.parse(analysisOwnerAsString || "{}");
      return analysisOwner;
    } catch {
      return {};
    }
  }

  const filterValue = useMemo(() => {
    return queryParams.analysisOwner ?? null;
  }, [queryParams]);

  // eslint-disable-next-line
  useEffect(() => {
    if (triggerOnChange) {
      triggerOnChange();
    }
    // eslint-disable-next-line
  }, [filterValue]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const analysisOwner: any = getAnalysisOwner();
    const newAnalysisOwnerValue = event.target.value;

    if (!newAnalysisOwnerValue) {
      delete queryParams.analysisOwner;
      delete analysisOwner[user.id];
    } else {
      analysisOwner[user.id] = newAnalysisOwnerValue;
      queryParams.analysisOwner = newAnalysisOwnerValue;
    }

    localStorage.setItem("analysisOwnerByUser", JSON.stringify(analysisOwner));
    setQueryParams(queryParams);
  };

  return { filterValue, handleFilterChange };
}

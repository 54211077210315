import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { useState, useMemo, useEffect, memo } from "react";

import { LineChartProps } from "./types";
import { TableHarmonics } from "../../TableHarmonics";
import { ChartArea, ContainerChart, TableArea, TableButton } from "./styles";
import { eventCallbacksArgs } from "../chart/types";
import { eventAnimation } from "../../../utils/charts/chartEvents";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { NamePlusTooltip } from "../../NamePlusTooltip";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function LineChartFunction({
  data,
  options,
  chartEvents = [],
  animated,
  harmonics,
  chart,
}: LineChartProps) {
  const [hovered, setHovered] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isRerendering, setIsRerendering] = useState(false);

  const location = useLocation();

  const isAnalysis = location.pathname.includes("analysis");

  const { t } = useTranslation();

  const events: ReactGoogleChartEvent[] = useMemo(() => {
    if (animated) {
      const animationEvent: ReactGoogleChartEvent = {
        eventName: "ready",
        callback: ({ chartWrapper }: eventCallbacksArgs) => {
          eventAnimation({
            chartWrapper,
            hovered,
            data,
          });
        },
      };

      return chartEvents.concat([animationEvent]);
    }

    return chartEvents;
    // eslint-disable-next-line
  }, [chartEvents, hovered]);

  const eventsAsString = useMemo(() => {
    return JSON.stringify(events);
  }, [events]);

  useEffect(() => {
    if (isAnalysis) {
      setIsRerendering(true);
    }
  }, [eventsAsString]);

  useEffect(() => {
    if (isRerendering) {
      setTimeout(() => {
        setIsRerendering(false);
      }, 1);
    }
  }, [isRerendering]);

  return (
    <ContainerChart
      isGlobalNotAnalysis={animated && !hovered}
      hasHarmonics={harmonics}
      isHovered={hovered}
    >
      {!isRerendering && (
        <ChartArea
          hasHarmonics={harmonics}
          onMouseEnter={() => animated && setHovered(true)}
          onMouseLeave={() => animated && setHovered(false)}
        >
          <Chart
            chartType={"LineChart"}
            style={{ width: "inherit", height: "100%" }}
            data={data}
            options={options}
            chartEvents={events}
            chartLanguage={"pt-Br"}
          />
        </ChartArea>
      )}

      {!isRerendering && harmonics && (
        <TableArea>
          <TableButton onClick={() => setShowTable(!showTable)}>
            {showTable ? (
              <div>
                <span>{t("LineChart.hideTable")}</span>
                <FaChevronRight />
              </div>
            ) : (
              <NamePlusTooltip
                tooltip={`${t("LineChart.showTable")}`}
                position={"left"}
              >
                <div>
                  <FaChevronLeft />
                </div>
              </NamePlusTooltip>
            )}
          </TableButton>
          {showTable ? (
            <TableHarmonics
              data={data}
              isMechanical={chart?.series.includes("X")}
              chart={chart}
            />
          ) : (
            <></>
          )}
        </TableArea>
      )}
    </ContainerChart>
  );
}

export default memo(LineChartFunction);

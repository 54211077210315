import styled from "styled-components";

export const AssetTypeImageWrapper = styled.div`
  width: 100%;
  max-width: inherit;

  // Container
  & > div {
    padding: 0px;
    width: 100%;

    // ImageContainer
    & > div {
      max-width: inherit;

      // ButtonSelectFile
      & > div {
        max-width: inherit;
        padding: 16px;
        // Img
        & > img {
          max-width: inherit;
        }
      }
    }
  }
`;

import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useLocation, useParams } from "react-router-dom";
import {
  clearDashboards,
  dashboardSelect,
  setAssetRiskRank,
  setAssetStops,
  setAssetTypes,
  setAssetsStatus,
  setDiagnostics,
  setElectricConsumption,
  setFacilityReliability,
  setHourMeter,
  setIotConnectivity,
  setOccurrencesCount,
  setOccurrencesHistory,
  setOccurrencesStatus,
  setRiskChart,
  setStoredSensors,
} from "../store/slices/dashboardSlice";
import { getUrlPattern } from "../utils/getUrlPattern";
import { ROUTES_PATH } from "../routes/RoutesPath";
import useApi from "./useApi";
import { PROFILES, userSelect } from "../store/slices/userSlice";
import queryString from "../utils/queryString";

function useDashboard() {
  const dispatch = useAppDispatch();
  const dashboard = useAppSelector(dashboardSelect);
  const { profile } = useAppSelector(userSelect);
  const location = useLocation();
  const params = useParams();

  const urlPattern = useMemo(
    () => getUrlPattern(location, params),
    [location, params],
  );
  const { request } = useApi({ path: "" });

  const queryStringParameters = useMemo(() => {
    if (urlPattern === ROUTES_PATH.OCCURRENCES) {
      return queryString.parse(location.search) || {};
    }

    return params || {};
    //eslint-disable-next-line
  }, [params, location]);

  async function loadOccurrencesStatus(analysisOwner?: string | null) {
    dispatch(setOccurrencesStatus({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/occurrences-status`,
      queryStringParameters: {
        ...queryStringParameters,
        ...(analysisOwner ? { analysisOwner } : {}),
      },
      skipToast: true,
    });

    dispatch(setOccurrencesStatus({ data: result, processing: false }));
    return result;
  }

  // eslint-disable-next-line
  async function loadOccurrencesCount() {
    dispatch(setOccurrencesCount({ data: null, processing: true }));
    
    const result = await request({
      method: "get",
      pathParameters: `dashboards/occurrences-count`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setOccurrencesCount({ data: result, processing: false }));
    return result;
  }

  async function loadStoredSensors() {
    if (
      ![
        PROFILES.EXECUTIVO_MASTER,
        PROFILES.MESA_MASTER,
        // PROFILES.REPRESENTANTE,
      ].includes(profile)
    ) {
      return;
    }

    dispatch(setStoredSensors({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/stored-sensors`,
      skipToast: true,
    });

    dispatch(setStoredSensors({ data: result, processing: false }));
    return result;
  }

  async function loadIotConnectivity() {
    dispatch(setIotConnectivity({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/positions-count`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setIotConnectivity({ data: result, processing: false }));
    return result;
  }

  async function loadAssetTypes() {
    dispatch(setAssetTypes({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/asset-types-count`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setAssetTypes({ data: result, processing: false }));
    return result;
  }

  async function loadAssetsStatus() {
    dispatch(setAssetsStatus({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/assets-status`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setAssetsStatus({ data: result, processing: false }));
    return result;
  }

  async function loadDianostics() {
    dispatch(setDiagnostics({ data: null, processing: true }));
    const result = await request({
      method: "get",
      pathParameters: `dashboards/occurrences-diagnostics`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setDiagnostics({ data: result, processing: false }));
    return result;
  }

  async function loadAssetRiskRank() {
    dispatch(setAssetRiskRank({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/asset-risk-rank`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setAssetRiskRank({ data: result, processing: false }));
    return result;
  }

  async function loadOccurrencesHistory() {
    dispatch(setOccurrencesHistory({ data: null, processing: true }));

    const result = await request({
      method: "get",
      pathParameters: `dashboards/occurrence-history`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setOccurrencesHistory({ data: result, processing: false }));
    return result;
  }

  async function loadAssetStops() {
    dispatch(setAssetStops({ data: null, processing: true }));

    let response = await request({
      method: "get",
      pathParameters: `dashboards/asset-stops`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setAssetStops({ data: response, processing: false }));
  }

  async function loadReliability() {
    dispatch(setFacilityReliability({ data: null, processing: true }));

    const facilityId = params?.facilityId || 0;
    const result = await request({
      method: "get",
      pathParameters: `facilities/${facilityId}/reliability-chart`,
      queryStringParameters,
      skipToast: true,
    });

    dispatch(setFacilityReliability({ data: result, processing: false }));
    return result;
  }

  async function loadElectricConsumption(period = "DAY") {
    dispatch(
      setElectricConsumption({
        data: null,
        processing: true,
        period,
      }),
    );

    const result = await request({
      method: "get",
      pathParameters: `electric-consumption`,
      queryStringParameters: { ...queryStringParameters, period },
      skipToast: true,
      useGmtOffset: true,
    });

    dispatch(
      setElectricConsumption({
        data: result,
        processing: false,
        period,
      }),
    );
    return result;
  }

  async function loadRiskChart() {
    dispatch(setRiskChart({ data: null, processsing: true }));

    const assetId = queryStringParameters.assetId;
    const result = await request({
      method: "get",
      pathParameters: `assets/${assetId}/risk-chart`,
      skipToast: true,
      useGmtOffset: true,
    });

    dispatch(setRiskChart({ data: result, processsing: false }));
    return result;
  }

  async function loadHourMeter(period = "DAY") {
    dispatch(
      setHourMeter({
        data: null,
        processing: true,
        period: period,
      }),
    );

    const assetId = queryStringParameters.assetId;
    let route = `assets/${assetId}/hourmeter-summary`;
    if (period === "WEEK" || period === "MONTH") {
      route = `assets/${assetId}/hourmeter-chart`;
    }

    const result = await request({
      method: "get",
      pathParameters: route,
      queryStringParameters: { period },
      skipToast: true,
    });

    dispatch(
      setHourMeter({
        data: result,
        processing: false,
        period: period || "DAY",
      }),
    );
    return result;
  }

  function loadOccurrencesPage() {
    Promise.all([
      loadOccurrencesStatus(),
      loadIotConnectivity(),
      loadAssetsStatus(),
    ]);
  }

  function loadPageDashboards() {
    if (process.env.REACT_APP_IS_LOCAL === "true") {
      return;
    }
    dispatch(clearDashboards());

    switch (urlPattern) {
      case ROUTES_PATH.COMPANIES: {
        Promise.all([
          loadOccurrencesStatus(),
          loadIotConnectivity(),
          loadAssetTypes(),
          loadDianostics(),
          loadAssetsStatus(),
          loadStoredSensors(),
        ]);
        break;
      }
      case ROUTES_PATH.FACILITIES: {
        Promise.all([
          loadAssetRiskRank(),
          loadIotConnectivity(),
          loadOccurrencesHistory(),
          loadAssetsStatus(),
          loadDianostics(),
        ]);
        break;
      }
      case ROUTES_PATH.ASSETS: {
        Promise.all([
          loadAssetStops(),
          loadReliability(),
          loadAssetRiskRank(),
          loadDianostics(),
          loadIotConnectivity(),
          loadElectricConsumption(dashboard?.electricConsumption?.period),
        ]);
        break;
      }
      case ROUTES_PATH.ASSET: {
        Promise.all([
          loadRiskChart(),
          loadElectricConsumption(dashboard?.electricConsumption?.period),
          loadDianostics(),
          loadHourMeter(dashboard?.hourMeter?.period || "DAY"),
          loadOccurrencesStatus(),
          loadAssetStops(),
        ]);
      }
    }
  }

  return {
    loadPageDashboards,
    loadRiskChart,
    loadReliability,
    loadHourMeter,
    loadElectricConsumption,
    queryStringParameters,
    loadOccurrencesPage,
    loadOccurrencesCount,
    loadStoredSensors,
    loadOccurrencesStatus,
  };
}

export default useDashboard;

import React, { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import useApi from "../../hooks/useApi";
import { Loading } from "../Loading";

import {
  Container,
  ContainerFilter,
  ContainerList,
  EmptyPositions,
  UpdateButton,
  UpdateContainer,
  WrapperButtons,
} from "./styles";
import CardSensor from "../CardSensor";
import { SensorListProps } from "./types";
import { MdClose, MdOutlineSyncDisabled, MdSync } from "react-icons/md";
import { RiDeviceLine } from "react-icons/ri";
import CountUp from "react-countup";
import { TbDatabaseOff } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { pt, enUS, es } from "date-fns/locale";
import { isMobile } from "react-device-detect";
import { BiSync } from "react-icons/bi";

const SensorList = ({
  filteredBolt,
  handleClearFilteredBolt,
  facilityId,
}: SensorListProps) => {
  const params = useParams();

  const { processing, request } = useApi({
    path: `/gateways-positions`,
  });

  const [pagination, setPagination] = useState<any>({});
  const [positions, setPositions] = useState<any>([]);
  const [filter, setFilter] = useState<any>("SYNCHRONIZED");
  const [lastUpdate, setLastUpdate] = useState<any>("");
  const [lastUpdateDate, setLastUpdateDate] = useState<any>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const { t, i18n } = useTranslation();

  const languages = {
    en: enUS,
    pt: pt,
    es: es,
  };

  useEffect(() => {
    getPositions({ page: 1, noProcessing: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filteredBolt, facilityId]);

  const getTxTime = (position?: any) => {
    try {
      const { parameters } = position?.bolt;

      const positionSchedule = position.schedule.schedulePosition;

      const globalTxWindow = 5 * 1000; // Duração de transmissão via RF entre sensor e bolt ( fica enviando para o bolt)
      const dynamicTxWindow = 180 * 1000; // 3 min dinamica
      const configWindow = (parameters?.configWindow || 600) * 1000; //ou é customizado ou é travado em 10min
      const radioWindowInterval =
        (parameters?.radioWindowInterval || 3600) * 1000; //tempo total de transmissão de um ciclo : De quanto em quanto tempo vai chegar globais
      const radioWindowNumBoardSlots =
        parameters?.radioWindowNumBoardSlots || 240; //

      const date = new Date().getTime();

      const globalWindow = radioWindowNumBoardSlots * globalTxWindow; // janela que vai ser enviado global de todos os sensores cadastrados no bolt

      const numBoardsPerCycle =
        (radioWindowInterval - globalWindow - configWindow) / dynamicTxWindow;

      const numCycles = radioWindowNumBoardSlots / numBoardsPerCycle;

      const txTotalCycles = radioWindowInterval * numCycles;

      const startWindow = new Date(
        Math.floor(date / txTotalCycles) * txTotalCycles,
      ).getTime();

      const endWindow = new Date(
        Math.ceil(date / txTotalCycles) * txTotalCycles,
      ).getTime();

      const startCycle = new Date(
        Math.floor(date / radioWindowInterval) * radioWindowInterval,
      ).getTime();

      const endCycle = new Date(
        Math.ceil(date / radioWindowInterval) * radioWindowInterval,
      ).getTime();

      const dynamicPos = Math.floor(positionSchedule / numBoardsPerCycle);
      const dynamicPosMod = positionSchedule % numBoardsPerCycle;

      let TxGlobal = startCycle + positionSchedule * globalTxWindow;

      if (TxGlobal < new Date().getTime()) {
        TxGlobal = endCycle + positionSchedule * globalTxWindow;
      }

      let TxDynamic =
        startWindow +
        dynamicPos * radioWindowInterval +
        dynamicPosMod * dynamicTxWindow +
        globalWindow +
        configWindow;

      if (TxDynamic < new Date().getTime()) {
        TxDynamic =
          endWindow +
          dynamicPos * radioWindowInterval +
          dynamicPosMod * dynamicTxWindow +
          globalWindow +
          configWindow;
      }

      const txTimeArray = [];

      txTimeArray.push({
        date: TxGlobal,
        label: "G",
      });

      txTimeArray.push({
        date: TxDynamic,
        label: "D",
      });

      const sortedArray = txTimeArray.sort((a, b) => a.date - b.date);
      return sortedArray;
    } catch {
      return [];
    }
  };

  const paginationLimit = window.innerWidth >= 1921 ? 50 : 35;

  useEffect(() => {
    setLastUpdateDate(new Date().getTime());
  }, [])

  useEffect(() => {
    if (lastUpdateDate === null) {
      return;
    }

    let interval: any;
    const date = new Date().getTime();

    interval = setInterval(() => {
      const { language } = i18n;
      setLastUpdate(
        formatDistanceToNow(date, {
          locale: languages[language as "pt" | "en" | "es"],
          addSuffix: true,
        }),
      );
    }, 1000);

    setPositions([]);
    setPagination({});
    getPositions({ page: 1, noProcessing: false });
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdateDate]);

  function handleFilterSync() {
    setFilter("SYNCHRONIZED");
    setLastUpdateDate(new Date().getTime());
  }

  function handleFilterUnsync() {
    setFilter("NOT_SYNCHRONIZED");
    setLastUpdateDate(new Date().getTime());
  }

  function handleFilterAll() {
    setFilter("");
    setLastUpdateDate(new Date().getTime());
  }

  const getPositions = useCallback(
    ({ page = 1, limit = paginationLimit, noProcessing = true }: any) => {
      const queryStringParameters: any = { ...params, page, limit };

      if (filter) {
        queryStringParameters.filter = filter;
      }

      if (filteredBolt) {
        queryStringParameters.gatewayId = filteredBolt.gatewayId;
      }

      if (facilityId) {
        queryStringParameters.facilityId = facilityId;
      }

      request({
        method: "get",
        queryStringParameters,
        noProcessing,
        skipToast: true,
      }).then((response) => {
        setPagination(response?.pagination || {});
        setPositions(
          page === 1
            ? response?.data || []
            : [...positions, ...(response.data || [])],
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request, positions, filter, filteredBolt, facilityId],
  );

  const renderLoadPagination =
    window.innerWidth >= 1921 ? positions.length > 50 : positions.length > 35;

  function handleClickUpdate() {
    setLastUpdateDate(new Date().getTime());
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 1100);
  }

  const renderList = () => {
    if (positions.length === 0) {
      return (
        <EmptyPositions>
          <TbDatabaseOff size={60} />
          <span>{t("SensorList.noSensor")}</span>
        </EmptyPositions>
      );
    }

    return positions.map((position: any) => (
      <CardSensor
        key={position.positionId}
        position={position}
        txTime={getTxTime(position)}
      />
    ));
  };

  return (
    <Container>
      {!isMobile && (
        <>
          <ContainerFilter disabled={!!filteredBolt}>
            <div style={{
               justifyContent: "space-between"
               }}>
              <WrapperButtons>
                <button
                  onClick={() => !filteredBolt && handleFilterSync()}
                  disabled={!!filteredBolt}
                >
                  {!!filteredBolt ||
                  (filter.includes("SYNCHRONIZED") &&
                    !filter.includes("NOT_SYNCHRONIZED")) ? (
                    <>
                      <MdSync size={20} color={"#FF9B30"} />
                      <span style={{ color: "#FF9B30" }}>
                        {t("SensorList.synchronized")}
                      </span>
                    </>
                  ) : (
                    <>
                      <MdSync size={20} />
                      <span>{t("SensorList.synchronized")}</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => !filteredBolt && handleFilterUnsync()}
                  disabled={!!filteredBolt}
                >
                  {!filteredBolt && filter.includes("NOT_SYNCHRONIZED") ? (
                    <>
                      <MdOutlineSyncDisabled size={20} color={"#FF9B30"} />
                      <span style={{ color: "#FF9B30" }}>
                        {t("SensorList.unSynchronized")}
                      </span>
                    </>
                  ) : (
                    <>
                      <MdOutlineSyncDisabled size={20} />
                      <span>{t("SensorList.unSynchronized")}</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => !filteredBolt && handleFilterAll()}
                  disabled={!!filteredBolt}
                >
                  {!filteredBolt &&
                  filter.includes("") &&
                  !filter.includes("SYNCHRONIZED") ? (
                    <span style={{ color: "#FF9B30" }}>
                      {t("SensorList.all")}
                    </span>
                  ) : (
                    <span>{t("SensorList.all")}</span>
                  )}
                </button>
                <button>
                  <RiDeviceLine size={20} />
                  <span>
                    <CountUp
                      start={pagination?.totalResults || 0}
                      end={pagination?.totalResults}
                      duration={0.8}
                      separator={"."}
                    />
                  </span>
                  <span>
                    &nbsp;Sensor{+pagination?.totalResults !== 1 && "es"}
                  </span>
                </button>
                {filteredBolt && (
                  <button onClick={() => handleClearFilteredBolt()}>
                    <span>{filteredBolt.name}</span>

                    <MdClose size={16} />
                  </button>
                )}
              </WrapperButtons>
              <UpdateContainer>
                {lastUpdate && (
                  <span
                    style={{
                      opacity: 0.7,
                      fontSize: 12.4,
                      padding: 4,
                      fontWeight: "normal",
                    }}
                  >
                      {t("SensorList.updatePrefix").concat(lastUpdate)}
                  </span>
                )}
               <UpdateButton isAnimating={isAnimating}>
                  <button
                    onClick={handleClickUpdate}
                    disabled={isAnimating}
                  >
                  <BiSync />
                  </button>
                </UpdateButton>
              </UpdateContainer>
            </div>
          </ContainerFilter>
          <ContainerList id="scrollableDiv">
            <InfiniteScroll
              next={() => getPositions({ page: +pagination.page + 1 })}
              dataLength={positions.length}
              hasMore={+pagination.page < +pagination.totalPages}
              loader={renderLoadPagination && +pagination.page > 1}
              scrollableTarget="scrollableDiv"
            >
              {processing ? <Loading height="100%" /> : renderList()}
            </InfiniteScroll>
          </ContainerList>
        </>
      )}
      {isMobile && (
        <>
          <ContainerFilter disabled={!!filteredBolt}>
            <div>
              <WrapperButtons>
                <button
                  onClick={() => !filteredBolt && setFilter("SYNCHRONIZED")}
                  disabled={!!filteredBolt}
                >
                  {!!filteredBolt ||
                  (filter.includes("SYNCHRONIZED") &&
                    !filter.includes("NOT_SYNCHRONIZED")) ? (
                    <>
                      <MdSync size={20} color={"#FF9B30"} />
                      <span style={{ color: "#FF9B30" }}>
                        {t("SensorList.synchronized")}
                      </span>
                    </>
                  ) : (
                    <>
                      <MdSync size={20} />
                      <span>{t("SensorList.synchronized")}</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => !filteredBolt && setFilter("NOT_SYNCHRONIZED")}
                  disabled={!!filteredBolt}
                >
                  {!filteredBolt && filter.includes("NOT_SYNCHRONIZED") ? (
                    <>
                      <MdOutlineSyncDisabled size={20} color={"#FF9B30"} />
                      <span style={{ color: "#FF9B30" }}>
                        {t("SensorList.unSynchronized")}
                      </span>
                    </>
                  ) : (
                    <>
                      <MdOutlineSyncDisabled size={20} />
                      <span>{t("SensorList.unSynchronized")}</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => !filteredBolt && setFilter("")}
                  disabled={!!filteredBolt}
                >
                  {!filteredBolt &&
                  filter.includes("") &&
                  !filter.includes("SYNCHRONIZED") ? (
                    <span style={{ color: "#FF9B30" }}>
                      {t("SensorList.all")}
                    </span>
                  ) : (
                    <span>{t("SensorList.all")}</span>
                  )}
                </button>
                <button>
                  <RiDeviceLine size={20} />
                  <span>
                    <CountUp
                      start={pagination?.totalResults || 0}
                      end={pagination?.totalResults}
                      duration={0.8}
                      separator={"."}
                    />
                  </span>
                  <span>
                    &nbsp;Sensor{+pagination?.totalResults !== 1 && "es"}
                  </span>
                </button>
                {filteredBolt && (
                  <button onClick={() => handleClearFilteredBolt()}>
                    <span>{filteredBolt.name}</span>

                    <MdClose size={16} />
                  </button>
                )}
                <UpdateContainer>
                 <UpdateButton isAnimating={isAnimating}>
                  <button
                    onClick={handleClickUpdate}
                    disabled={isAnimating}
                  >
                    <BiSync />
                  </button>
                </UpdateButton>
                </UpdateContainer>
                </WrapperButtons>
                {lastUpdate && (
                  <span
                    style={{
                      opacity: 0.7,
                      fontSize: 12.4,
                      padding: 4,
                      fontWeight: "normal",
                    }}
                    >
                      {t("SensorList.updatePrefix").concat(lastUpdate)}
                  </span>
                )}
             
            </div>
          </ContainerFilter>
          <ContainerList id="scrollableDiv">
            <InfiniteScroll
              next={() => getPositions({ page: +pagination.page + 1 })}
              dataLength={positions.length}
              hasMore={+pagination.page < +pagination.totalPages}
              loader={renderLoadPagination && +pagination.page > 1}
              scrollableTarget="scrollableDiv"
            >
              {processing ? <Loading height="100%" /> : renderList()}
            </InfiniteScroll>
          </ContainerList>
        </>
      )}
    </Container>
  );
};

export default SensorList;

import styled from "styled-components";
import { ConfigurationPagesContent } from "../../../components/frame/styles";
import { ProfileScreen } from "../Profile/styles";

export const AssetTypesContainer = styled(ConfigurationPagesContent)`
  padding: 2% 4% 4%;
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};

  @media screen and (max-width: 767px) {
    opacity: 1;
    padding: 4px;
    margin-left: 0;
  }
  // Modificado de 1536px para 1533 para não cortar no Zoom 125%
  @media screen and (min-width: 767px) and (max-width: 1533px) {
    height: calc(100% - 8rem);
  }
`;

export const AssetTypesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;

  & > h2 {
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  button {
    height: 40px;
    width: 160px;
    background-color: ${(props) => props.theme.colors.IconsTools};
    border-radius: 8px;
    ${(props) => props.theme.fonts.primary.H4Bold};
    color: ${(props) => props.theme.colors.cardHighlight};
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-decoration: none;

    &:hover {
      border: none;
      scale: 0.99;
      transition: 0.3s;
      opacity: 0.85;
      color: ${(props) => props.theme.colors.cardHighlight};
      border: 1px solid ${(props) => props.theme.colors.white};
    }

    @media screen and (max-width: 767px) {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 5.2rem;
      margin-top: 0.15rem;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0.6rem 0px 1rem 4rem;

    h2 {
      font-size: 1.25rem;
      line-height: 38px;
    }

    button {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const AssetTypesContent = styled(ProfileScreen)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  overflow: auto;
  padding: 16px;

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 1.75rem;
  }
`;

import { useCallback, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  appSelect,
  appSetCurrentCompany,
  appToggleSidebarConfiguration,
  setAppIsReadOnly,
} from "../../store/slices/appSlice";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import ReactTooltip from "react-tooltip";

import {
  Container,
  ContainerMobile,
  SidebarConfigurationHeader,
  Menu,
  MenuItem,
  SidebarContent,
  ToogleShowHideSidebar,
  ToogleShowHideSidebarMobile,
  VersionContainer,
  MenuItensContainer,
  MenuListItem,
} from "./styles";

import { FaChevronLeft } from "react-icons/fa";
import {
  MdMenu,
  MdOutlineDashboardCustomize,
  MdOutlineEditNotifications,
  MdPersonOutline,
} from "react-icons/md";

import { Link } from "react-router-dom";
import { TbBriefcase, TbDeviceIpadCog } from "react-icons/tb";
import { IoMdAlarm } from "react-icons/io";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";

import { isMobile, isMobileOnly } from "react-device-detect";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import useApi from "../../hooks/useApi";
import SelectCompany from "./SelectCompany";
import RetinaMapsButton from "../PositionList/RetinaMapsButton";
import { useTranslation } from "react-i18next";
import { AiFillApi } from "react-icons/ai";
import { EMPTY_COMPANY } from "../../pages/Facilities/types";
import { Badge } from "../Badge";
import { isUserAdministrator, isUserIbbx } from "../../utils/userUtils";

export const SidebarConfiguration = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const retinaVersion = localStorage.getItem("retinaVersion") || "";
  const urlPattern = getUrlPattern(location, params);

  const { pathname } = location;

  const mapsRef = useRef<any>(null);

  const isNotCompaniesLevelUser = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  const setShowSidebar = useCallback(
    (showSidebar: boolean) => [
      dispatch(appToggleSidebarConfiguration(showSidebar)),
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { t } = useTranslation();

  const showSidebar = app.sidebarConfigurationOpen;

  const [showSidebarMobile, setShowSidebarMobile] = useState(false);
  const [companies, setCompanies] = useState([]);

  const { currentCompany, currentFacility } = app;
  const { request } = useApi({ path: "/companies" });

  const {
    CLIENTE_COMUM,
    MESA_ANALISTA,
    REPRESENTANTE,
    CLIENTE_MASTER_VIEW,
    EXECUTIVO_MASTER,
    CLIENTE_MASTER,
  } = PROFILES;
  const isMaster = params.companyId === "ibbx";
  const isClientMaster = [CLIENTE_MASTER].includes(user.profile);
  const isClienteComum = [CLIENTE_COMUM].includes(user.profile);
  const isMesaAnalista = [MESA_ANALISTA].includes(user.profile);
  const isRepresentante = [REPRESENTANTE].includes(user.profile);
  const isUserVisualizer = [CLIENTE_MASTER_VIEW].includes(user.profile);
  const isUserAdmin = [EXECUTIVO_MASTER, MESA_ANALISTA, REPRESENTANTE].includes(
    user.profile,
  );
  const isInFacility = !!currentFacility?.id;

  const changeCompany = ({ value, label, logoUrl, theme, isReadOnly }: any) => {
    var link = location.pathname.replace("/configuration", "");
    link = link.replace(`/${params?.companyId || ""}`, "");

    dispatch(
      appSetCurrentCompany({
        ...EMPTY_COMPANY,
        id: value,
        name: label,
        logoUrl,
        theme,
      }),
    );

    dispatch(setAppIsReadOnly(isReadOnly));
    navigate(`/configuration/${value}${link}${location.search}`);
  };

  const handleNavigateToClearCache = () => {
    navigate(`/clear-cache?from=${pathname}`);
  };

  useEffect(() => {
    if (isMesaAnalista && currentCompany.id) {
      dispatch(appSetCurrentCompany(null));
    } else if (!isNotCompaniesLevelUser) {
      const queryStringParameters = { orderByName: 1 };
      request({ method: "get", queryStringParameters }).then((response) => {
        var company = response.find(
          ({ id }: any) => String(id) === String(params.companyId),
        );

        setCompanies(
          response.map(({ id, name, logoUrl, isReadOnly, theme }: any) => ({
            value: id,
            label: name,
            logoUrl,
            theme,
            isReadOnly,
          })),
        );

        dispatch(
          appSetCurrentCompany({
            ...EMPTY_COMPANY,
            id: company?.id || 0,
            name: company?.name || "",
            logoUrl: company?.logoUrl || "",
            theme: company?.theme,
          }),
        );
      });
    }

    // eslint-disable-next-line
  }, []);

  const renderMenuItens = () => {
    return (
      <Menu isOpen={showSidebar}>
        <div>
          <MenuItem
            data-tip
            data-for="profile"
            active={urlPattern === ROUTES_PATH.PROFILE}
            as={Link}
            to={`/configuration/${params?.companyId}/profile`}
          >
            <MdPersonOutline size={showSidebar ? "30" : "32"} />
            {showSidebar && <span>{t("SidebarConfiguration.myProfile")}</span>}
            {user?.configurationNotificationTypes?.includes("USER_PROFILE") &&
              !pathname.includes("/profile") && (
                <Badge
                  style={{
                    top: "25%",
                    right: "10px",
                    left: "unset",
                  }}
                />
              )}
          </MenuItem>

          <MenuItem
            data-tip
            data-for="notifications"
            active={urlPattern === ROUTES_PATH.NOTIFICATIONS}
            as={Link}
            to={`/configuration/${params.companyId}/notifications`}
          >
            <MdOutlineEditNotifications size={showSidebar ? "30" : "32"} />
            {showSidebar && (
              <span>{t("SidebarConfiguration.notifications")}</span>
            )}
          </MenuItem>

          {!isMaster && !isMesaAnalista && !isClienteComum && (
            <>
              <MenuItem
                data-tip
                data-for="companyData"
                active={urlPattern === ROUTES_PATH.COMPANY_DATA}
                as={Link}
                to={`/configuration/${params.companyId}/details`}
              >
                <TbBriefcase size={showSidebar ? "30" : "32"} />
                {showSidebar && (
                  <span>{t("SidebarConfiguration.companyData")}</span>
                )}
              </MenuItem>
            </>
          )}
          {isUserAdministrator(user.profile) && params.companyId === "ibbx" && (
            <>
              <MenuItem
                data-tip
                data-for="assetTypes"
                active={urlPattern === ROUTES_PATH.ASSET_TYPES}
                as={Link}
                to={`/configuration/${params.companyId}/asset-types`}
              >
                <TbBriefcase size={showSidebar ? "30" : "32"} />
                {showSidebar && <span>{t("AssetTypePage.Asset Types")}</span>}
              </MenuItem>
            </>
          )}
          {isInFacility && (
            <>
              <MenuItem
                data-tip
                data-for="alarms"
                active={urlPattern === ROUTES_PATH.ALARMS}
                as={Link}
                to={`/configuration/${params.companyId}/alarms`}
              >
                <IoMdAlarm size={showSidebar ? "30" : "32"} />
                {showSidebar && <span>{t("SidebarConfiguration.alarms")}</span>}
              </MenuItem>
            </>
          )}
          {!isClienteComum &&
            !isMesaAnalista &&
            !isUserVisualizer &&
            (!isClientMaster || params.companyId !== "ibbx") && (
              <MenuItem
                active={[
                  ROUTES_PATH.USERS,
                  ROUTES_PATH.NEW_USER,
                  ROUTES_PATH.EDIT_USER,
                ].includes(urlPattern)}
                data-tip
                data-for="users"
                as={Link}
                onClick={(e) => {
                  if ([ROUTES_PATH.USERS].includes(urlPattern)) {
                    e.preventDefault();
                  }
                }}
                to={`/configuration/${params.companyId}/users`}
              >
                <HiOutlineUserGroup size={showSidebar ? "30" : "35"} />
                {showSidebar && <span>{t("SidebarConfiguration.users")}</span>}
              </MenuItem>
            )}
          {user.ibbx && (
            <MenuItem
              active={[ROUTES_PATH.REPRESENTATIVES].includes(urlPattern)}
              data-tip
              data-for="representants"
              as={Link}
              to={`/configuration/${params?.companyId}/representatives`}
            >
              <MdOutlineBadge size={showSidebar ? "30" : "35"} />

              {showSidebar && (
                <span>{t("SidebarConfiguration.representant")}</span>
              )}
            </MenuItem>
          )}
          {(!location.pathname.includes("ibbx") ||
            PROFILES.REPRESENTANTE.includes(user.profile)) && (
            <MenuItem
              data-tip
              data-for="theme"
              active={urlPattern === ROUTES_PATH.COLOR_THEME}
              as={Link}
              to={`/configuration/${params.companyId}/color_theme`}
              hide={isUserVisualizer}
            >
              <MdOutlineDashboardCustomize size={showSidebar ? "30" : "32"} />
              {showSidebar && (
                <span>{t("SidebarConfiguration.personalize")}</span>
              )}
            </MenuItem>
          )}

          {user.admin && (
            <MenuItem
              data-tip
              data-for="maps"
              onClick={() => mapsRef.current.click()}
            >
              <RetinaMapsButton
                size={showSidebar ? "30" : "32"}
                ref={mapsRef}
              />
              {showSidebar && <span>Maps</span>}
            </MenuItem>
          )}
          {params.companyId !== "ibbx" &&
            !isClienteComum &&
            !isUserVisualizer && (
              <MenuItem
                data-tip
                data-for="api"
                as={Link}
                to={`/configuration/${params.companyId}/api`}
              >
                <AiFillApi size={showSidebar ? "30" : "32"} />
                {showSidebar && <span>{t("api.Integração")}</span>}
              </MenuItem>
            )}
          {isUserAdmin && (
            <MenuItem
              data-tip
              data-for="devices"
              as={Link}
              to={`/configuration/${params.companyId}/devices`}
            >
              <TbDeviceIpadCog size={showSidebar ? "30" : "32"} />
              {showSidebar && <span>{t("newSensor.Dispositivos")}</span>}
            </MenuItem>
          )}

          {!isMobile && !showSidebar && (
            <>
              <ReactTooltip
                id="profile"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.myProfile")}
              </ReactTooltip>
              <ReactTooltip
                id="companyData"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.companyData")}
              </ReactTooltip>
              <ReactTooltip
                id="alarms"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.alarms")}
              </ReactTooltip>
              <ReactTooltip
                id="users"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.users")}
              </ReactTooltip>
              <ReactTooltip
                id="representants"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.repUsers")}
              </ReactTooltip>
              {(app.currentCompany.id ||
                PROFILES.REPRESENTANTE.includes(user.profile)) && (
                <ReactTooltip
                  id="theme"
                  type="dark"
                  place="right"
                  effect="solid"
                  delayShow={200}
                >
                  {t("SidebarConfiguration.personalizer")}
                </ReactTooltip>
              )}
              <ReactTooltip
                id="notifications"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("SidebarConfiguration.notifications")}
              </ReactTooltip>
              <ReactTooltip
                id="devices"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                Dispositivos
              </ReactTooltip>
              <ReactTooltip
                id="api"
                type="dark"
                place="right"
                effect="solid"
                delayShow={200}
              >
                {t("api.Integração")}
              </ReactTooltip>

              {user.admin && (
                <ReactTooltip
                  id="maps"
                  type="dark"
                  place="right"
                  effect="solid"
                  delayShow={200}
                >
                  {t("SidebarConfiguration.configMaps")}
                </ReactTooltip>
              )}
            </>
          )}
        </div>
        <div>
          <VersionContainer onClick={handleNavigateToClearCache}>
            {t("Versão")} {retinaVersion}
          </VersionContainer>
        </div>
      </Menu>
    );
  };

  const renderMenu = () => {
    <MenuItensContainer>
      <MenuListItem></MenuListItem>
      <VersionContainer onClick={handleNavigateToClearCache}>
        {t("Versão")} {retinaVersion}
      </VersionContainer>
    </MenuItensContainer>;
  };

  return (
    <SidebarContent>
      {isMobileOnly ? (
        <>
          <ToogleShowHideSidebarMobile
            isOpen={showSidebarMobile}
            onClick={() => setShowSidebarMobile(!showSidebarMobile)}
          >
            {showSidebarMobile ? (
              <FaChevronLeft size={20} />
            ) : (
              <MdMenu size={32} />
            )}
          </ToogleShowHideSidebarMobile>
          {showSidebarMobile && (
            <>
              <ContainerMobile>{renderMenuItens()}</ContainerMobile>
            </>
          )}
        </>
      ) : (
        <>
          <ToogleShowHideSidebar
            isOpen={showSidebar}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <FaChevronLeft />
          </ToogleShowHideSidebar>
          <Container isOpen={showSidebar}>
            {showSidebar && (
              <>
                {!isMaster && (
                  <>
                    <SidebarConfigurationHeader>
                      <div>
                        {isNotCompaniesLevelUser ? (
                          <span>{currentCompany?.name}</span>
                        ) : (
                          <SelectCompany
                            handleChange={changeCompany}
                            options={companies}
                          />
                        )}
                      </div>
                    </SidebarConfigurationHeader>
                  </>
                )}
              </>
            )}
            {renderMenuItens()}
          </Container>
        </>
      )}
    </SidebarContent>
  );
};

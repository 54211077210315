import { ReactNode, useState } from "react";

import { Container, Label, Switcher, ToggleCircle } from "./styles";
import { MdOutlineViewArray, MdViewCarousel } from "react-icons/md";

interface ISwitchToggle {
  label?: string;
  labelRight?: string;
  labelLeft?: string;
  switchDirection?: "top" | "right" | "bottom" | "left";
  switchItems?: () => void;
  switcher?: boolean;
  setSwitcher?: () => void;
  iconOn: ReactNode;
  iconOff: ReactNode;
}

export function SwitchToggle({
  label,
  switchItems,
  switcher,
  setSwitcher,
  switchDirection = "left",
  iconOn,
  iconOff,
  labelRight,
  labelLeft,
}: ISwitchToggle) {
  return (
    <Container onClick={setSwitcher} direction={switchDirection}>
      {label && <Label direction={switchDirection}>{label}</Label>}
      {labelLeft && <Label>{labelLeft}</Label>}
      <Switcher toggleSwitch={switcher}>
        <ToggleCircle toggleSwitch={switcher}>
          {switcher ? <>{iconOn}</> : <>{iconOff}</>}
        </ToggleCircle>
        <>{iconOff}</>
        <>{iconOn}</>
      </Switcher>
      {labelRight && <Label>{labelRight}</Label>}
    </Container>
  );
}

import { useRef, useState } from "react";
import { Container } from "./styles";

import { DrawerComponent } from "../../DrawerComponent";
import { useParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { AnalyticsComponent } from "../AnalyticsComponent";
import { FormikProps } from "formik";
import FormSelect from "../../FormSelect";
import { FormikTextInput } from "../../textInput";
import useApi from "../../../hooks/useApi";
import { success } from "../../Toast";
import { useAppSelector } from "../../../store/hooks";
import { userSelect } from "../../../store/slices/userSlice";
import { validateAnalyticsUrl } from "../../../utils/utilsFunctions";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import { Buffer } from "buffer";
import { transparentLoginUrls } from "../../../utils/transparentLoginUrls";

interface IAnalyticsTab {
  url?: string;
  selectPermissionOptions?: any;
  tabs?: any;
  refresh: any;
  blocked?: any;
  onCloseMenu?: () => void;
}

export function AnalyticsTab({
  url,
  selectPermissionOptions,
  tabs,
  refresh,
  blocked,
  onCloseMenu,
}: IAnalyticsTab) {
  const { request } = useApi({ path: "/tabs" });
  const formRef = useRef<FormikProps<any>>(null);
  const user = useAppSelector(userSelect);
  const { handleTrackEvent } = useSegment();

  const params = useParams();
  const submitButtonRefAnalytics = useRef<HTMLButtonElement | null>(null);
  const clearButtonRefAnalytics = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((prevS) => !prevS);

    handleTrackEvent({
      event: "Menu > Add novo Analytics",
    });
  };

  const handleConfirm = () => {
    if (submitButtonRefAnalytics.current) {
      submitButtonRefAnalytics.current.click();

      handleTrackEvent({
        event: "Add novo Analytics > Salvar",
      });
    }
  };
  const handleCancel = () => {
    setOpenDrawer(false);
    formRef?.current?.setValues({ label: "", url: "" });
    onCloseMenu && onCloseMenu();
  };

  const handleSelectChange = (option: any) => {
    formRef.current?.setFieldValue("table", option.value || null);
  };

  const returnLevelToSaveTab = () => {
    if (params.assetId) {
      return "asset";
    }

    if (params.facilityId) {
      return "facility";
    }
    if (params.companyId) {
      return "company";
    }

    return "company";
  };

  const handleSubmit = (values: any) => {
    const id = new Date().getTime();

    const requestData = {
      label: values?.label.trim(),
      id: id,
      key: String(id),
      table: values?.table,
      level: returnLevelToSaveTab(),
      url: values?.url,
      createdBy: user.id,
      createdByName: user.name,
      createdAt: new Date().getTime(),
      credentials: {
        email: values?.email,
        password:
          values?.password && Buffer.from(values?.password).toString("base64"),
      },
    };

    request({
      method: "post",
      body: requestData,
      queryStringParameters: params,
    }).then(() => {
      onCloseMenu && onCloseMenu();
      refresh();
      setOpenDrawer(false);
      success("Analytics criado com sucesso!");
    });
  };

  return (
    <>
      <AnalyticsComponent.Body>
        <AnalyticsComponent.UserForm
          initialValues={{}}
          handleSubmit={handleSubmit}
          formRef={formRef}
          validateForm={(values: any) => {
            const errors: any = {};
            if (!values.label) {
              errors.label = `${t("Analytics.Preencha o nome da aba")}`;
            }
            if (/^\s+$/.test(values.label)) {
              errors.label = `${t(
                "Analytics.O campo não pode começar com um espaço vazio.",
              )}`;
            }
            if (!values.url) {
              errors.url = "Url obrigatória";
            } else if (!validateAnalyticsUrl(values.url)) {
              errors.url = `${t("Analytics.Informe uma Url válida")}`;
            }
            return errors;
          }}
        >
          {({ resetForm, errors, values }: any) => {
            const isTransparentLoginUrl = transparentLoginUrls.some(
              (url: any) => {
                return values.url?.includes(url);
              },
            );

            return (
              <>
                <FormikTextInput
                  placeholder={`${t("Analytics.Nome da Aba")}`}
                  name="label"
                  label={`${t("Analytics.Como deseja Chamar")}:`}
                  error={errors?.label}
                  disabled={blocked}
                />

                <FormSelect
                  handleChange={handleSelectChange}
                  name={"table"}
                  label={`${t("Analytics.Permissão de visualização")}`}
                  options={selectPermissionOptions}
                  placeholder={`${t("Analytics.Selecione")}`}
                  defaultValue={{
                    value: "user",
                    label: `${t("Analytics.Apenas para mim")}`,
                  }}
                  disabled={blocked}
                  notClearable
                />
                <FormikTextInput
                  placeholder={`${t("Analytics.Adicione a Url")}`}
                  name="url"
                  label={`${t("Analytics.Adicione a URL do seu Analytics")}`}
                  error={errors?.url}
                  disabled={blocked}
                />

                {isTransparentLoginUrl && (
                  <>
                    <p>{t("Analytics.Login Transparente")}</p>

                    <FormikTextInput
                      placeholder={`E-mail`}
                      name="email"
                      label={`E-mail`}
                      error={errors?.email}
                      type="email"
                    />

                    <FormikTextInput
                      placeholder={`${t("Analytics.Senha")}`}
                      name="password"
                      label={`${t("Analytics.Senha")}`}
                      error={errors?.email}
                      type="password"
                    />
                  </>
                )}

                <button
                  className="hidenButton"
                  type="submit"
                  ref={submitButtonRefAnalytics}
                />
                <button
                  onClick={() => resetForm()}
                  className="hidenButton"
                  type="button"
                  ref={clearButtonRefAnalytics}
                />
              </>
            );
          }}
        </AnalyticsComponent.UserForm>
      </AnalyticsComponent.Body>
      {!blocked && (
        <AnalyticsComponent.Footer>
          <AnalyticsComponent.CancelButton handleCancel={handleCancel} />
          <AnalyticsComponent.ConfirmButton handleConfirm={handleConfirm} />
        </AnalyticsComponent.Footer>
      )}
    </>
  );
}

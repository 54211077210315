import { Link } from "react-router-dom";
import styled from 'styled-components'

export const Container = styled(Link) <{
  selected?: boolean;
  navbarcolor?: string;
}>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${(props) => {
    if (props.selected && !props.navbarcolor) {
      return props.theme.colors.cardBackground;
    }
    if (props.selected && props.navbarcolor) {
      return props.navbarcolor;
    }
    return "transparent";
  }};

  svg {
    width: 1.5rem;
    height: auto;
  }

  @media screen and (max-width: 1280px) {
      width: 2rem;
      height: 2rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
  }
`;
import { useCallback, useState } from "react";
import { Amplify, API } from "aws-amplify";

import { awsConfig } from "../store/awsConfig";
import { error, warning } from "../components/Toast";
import { notifyDiscordError } from "../utils/notifyDiscord";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTranslator from "./useTranslator";

type UseApiProps = {
  path: string;
};

export interface IRequest {
  method?: "post" | "put" | "get" | "del";
  pathParameters?: any;
  queryStringParameters?: any;
  body?: any;
  noProcessing?: boolean;
  skipToast?: boolean;
  useGmtOffset?: boolean;
}

type UseApiReturn = {
  request: ({
    method,
    pathParameters,
    queryStringParameters,
    body,
    noProcessing,
  }: IRequest) => Promise<any>;
  processing: boolean;
  getting: boolean;
};

Amplify.configure(awsConfig);
const apiRetinaMonitorator = awsConfig?.API?.endpoints?.[0]?.name;

export function useApi({ path }: UseApiProps): UseApiReturn {
  const navigate = useNavigate();
  const location = useLocation();
  const [processing, setProcessing] = useState(false);
  const [getting, setGetting] = useState(false);

  const { t } = useTranslation();

  const errorTranslator = useTranslator("error");

  const handleProcessing = (
    value: boolean,
    method: string,
    noProcessing?: boolean,
  ) => {
    if (!noProcessing) {
      setProcessing(value);
    }

    if (method === "get") {
      setGetting(value);
    }
  };

  const request = useCallback(
    async ({
      method = "get",
      pathParameters,
      queryStringParameters = {},
      noProcessing,
      body,
      skipToast,
      useGmtOffset,
    }: IRequest) => {
      try {
        handleProcessing(true, method, noProcessing);

        const pathUrl = pathParameters ? `${path}/${pathParameters}` : path;
        if (useGmtOffset && method === "get") {
          queryStringParameters.gmt = new Date().getTimezoneOffset() / -60;
        }

        if (useGmtOffset && body) {
          body.gmt = new Date().getTimezoneOffset() / -60;
        }

        const response = await API[method](apiRetinaMonitorator, pathUrl, {
          queryStringParameters,
          body,
          response: true,
        });

        const localRetinaVersion = localStorage.getItem("retinaVersion");
        const serverRetinaVersion = response?.headers["retina-version"];

        if (
          serverRetinaVersion !== localRetinaVersion &&
          process.env.REACT_APP_ENV === "development"
        ) {
          localStorage.setItem("retinaVersion", serverRetinaVersion);
          if(window.location.href.includes('terms')){
            navigate(`/clear-cache?from=/`);
          } else {
            navigate(`/clear-cache?from=${location.pathname}`);
          }
        }

        handleProcessing(false, method, noProcessing);

        return response.data;
      } catch (err: any) {
        const isNetWorkError = err.toString().includes("Network Error");
        if (isNetWorkError && err.request?.status !== 404) {
          warning(t("dictionary.labels.No internet connection.") as string);
          handleProcessing(false, method, noProcessing);
          return;
        }

        const { data, status }: any = err?.response || {};
        var message = data?.message || "Failed to request";

        if (status === 403) {
          navigate("/");
        }

        if (status >= 500) {
          await notifyDiscordError({
            title: `Erro na API`,
            description: `path: ${path}/${pathParameters}\nmessage: ${err.message}`,
          });
        }

        if (["post", "put"].includes(method) && Array.isArray(data)) {
          message = "Informações inválidas";

          if (data[0].message) {
            message = message + " : \n" + errorTranslator(data[0].message);
          }
        }

        if (!skipToast) {
          error(message);
        }

        handleProcessing(false, method, noProcessing);
        throw err;
      }
    },
    [path],
  );

  return { request, processing, getting };
}

export default useApi;

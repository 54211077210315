import { InputHTMLAttributes } from "react";
import { InputCompoundContainer } from "../InputField/styles";
import {
  InputCompoundCheckLabel,
  InputCompoundFieldContainer,
  InputCompoundLabel,
  InputCompoundLabelContainer,
} from "./styles";
import { LoadingInput } from "../LoadingInput";
import { FormCheck } from "react-bootstrap";

interface IInputCompound {
  children:
    | JSX.Element
    | boolean
    | Array<JSX.Element | boolean | undefined | "">;
}

const InputCompound = ({ children }: IInputCompound) => {
  return <InputCompoundContainer>{children}</InputCompoundContainer>;
};

interface IInputCompoundField extends InputHTMLAttributes<HTMLInputElement> {
  isLoading?: boolean;
}

const Field = ({ isLoading, type, ...props }: IInputCompoundField) => {
  if (isLoading) {
    return <LoadingInput />;
  }
  return <input {...props} type={type || "text"} />;
};

interface ICheckBox {
  checked: boolean;
  handleChange: Function;
}
function CheckBox({ checked, handleChange }: ICheckBox) {
  return (
    <FormCheck
      type="checkbox"
      checked={checked}
      onChange={(e) => handleChange(e)}
    />
  );
}

function Radio({ checked, handleChange }: ICheckBox) {
  return (
    <FormCheck
      type="radio"
      checked={checked}
      onChange={(e) => handleChange(e)}
    />
  );
}


InputCompound.LabelContainer = InputCompoundLabelContainer;
InputCompound.Label = InputCompoundLabel;
InputCompound.CheckLabel = InputCompoundCheckLabel;
InputCompound.CheckBox = CheckBox;
InputCompound.Field = Field;

export default InputCompound;

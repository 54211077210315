import styled from "styled-components";


export const TableWrapper = styled.div`
  .table {
    --bs-table-bg: ${props => props.theme.theme};
  }
`

export const ColumnHeader = styled.div`
  margin: 8px 0 4px 0;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H5Bold};


  & > span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.iconsPrimary}!important;
  }
`;

export const ColumnField = styled.div`
  & > span {
    color: ${({ theme }) => theme.colors.iconsSecondary}!important;
  }
`;

export const DeleteUserFacilityButton = styled.button`
  color: ${({ theme }) => theme.colors.iconsSecondary};
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  background-color: transparent;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: flex;

  &:hover {
    border: none;
  }

  svg {
    margin: auto;
    font-size: 14px;
  }

`;

export const EmptyMessage = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

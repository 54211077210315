import { eventSelectArgs } from "../../components/charts/chart/types";
import { eventAnimations } from "../../components/charts/LineChart/types";

export function eventSelect({
  chartWrapper,
  chartData,
  isMobile,
  ticks,
  handleClickPoint,
  comments,
  setShowModalComment = () => null,
}: eventSelectArgs) {
  const wrapper = chartWrapper.getChart();
  const selection = wrapper.getSelection();
  if (selection.length === 1) {
    const [selectedItem] = selection;
    const { row, column } = selectedItem;
    const { refs } = chartData;

    var index = (column - 1) / 2;
    if (!isMobile && handleClickPoint && refs[index][row]) {
      handleClickPoint(ticks[row], refs[index][row]);
    }

    if (comments) {
      var commentIdx: number = (column - 1) / 2;
      const commentsArray: any[] = Object.values(comments);
      setShowModalComment({
        show: true,
        index: row,
        axis: chartData.series[commentIdx + 1],
        comment: commentsArray[commentIdx][row]
          ? commentsArray[commentIdx][row]
          : null,
      });
    }
  }
}

export function eventSelectFundamental({
  chartWrapper,
  chartData,
  handleEvent,
}: any) {
  const wrapper = chartWrapper.getChart();
  const selection = wrapper.getSelection();
  if (selection.length === 1) {
    const [selectedItem] = selection;
    const { row } = selectedItem;

    const freq = chartData.data[row][0];
    handleEvent(freq);
  }
}

export function eventSelectGlobal({
  chartWrapper,
  chartData,
  isMobile,
  ticks,
  handleClickPoint,
}: any) {
  const wrapper = chartWrapper.getChart();
  const selection = wrapper.getSelection();
  if (selection.length === 1) {
    const [selectedItem] = selection;
    const { row, column } = selectedItem;
    const { refs } = chartData;

    var index = (column - 1) / 2;
    if (!isMobile && handleClickPoint && refs[index][row]) {
      handleClickPoint(ticks[row], refs[index][row]);
    }
  }
}

export function eventSelectDynamic({
  wrapper: chartWrapper,
  chartData,
  comments,
  setModalComment = () => null,
}: any) {
  const wrapper = chartWrapper.getChart();
  const selection = wrapper.getSelection();
  if (selection.length === 1) {
    const [selectedItem] = selection;
    const { row, column } = selectedItem;

    const commentIdx: number = (column - 1) / 2;
    const allCommentsArray: any[] = Object.values(comments || {});
    const selectedSerieComments = allCommentsArray[commentIdx] || [];
    setModalComment({
      show: true,
      index: row,
      axis: chartData.series[commentIdx + 1],
      data: selectedSerieComments[row] || null,
    });
  }
}

export function eventSelectDynamicTWFFrequency({
  wrapper: chartWrapper,
  chartData,
  twfFrequency,
  setTwfFrequency = () => null,
}: any) {
  const wrapper = chartWrapper.getChart();
  const selection = wrapper.getSelection();

  const selectedPoints =
    selection.length <= 2 ? selection : selection.slice(selection.length - 2);

  const interval = selectedPoints.map((selected: any) => {
    const { row, column } = selected;

    const time = chartData.data[row][0];
    const value = chartData.data[row][column - 1];

    return { time, value };
  });

  setTwfFrequency(interval);

  wrapper.setSelection(selectedPoints);
}

export function eventAnimation({
  chartWrapper,
  hovered,
  data,
}: eventAnimations) {
  const wrapper: any = chartWrapper.getChart();
  if (!hovered) {
    const [firstRow, secondRow] = data;
    const totalSeries = firstRow.filter(
      (serie: any) => !["Alerta", "Risco"].includes(serie),
    ).length;

    const totalData = secondRow.filter(
      (val: any, index: number) =>
        typeof val === "number" && index < totalSeries,
    );

    var selections: any = [
      { row: wrapper.Gi - 1, column: 1 },
      { row: wrapper.Gi - 1, column: 3 },
      { row: wrapper.Gi - 1, column: 5 },
    ];

    selections = selections.filter(
      (_: any, index: number) => index < totalData.length,
    );

    wrapper.setSelection(selections);
  }
}

import styled from "styled-components";
//css`
// opacity: 0.4;
// pointer-events: none;
// `}

export const DiagnosticDeleteButton = styled.button`
  display: flex;
  justify-content: center !important;
  align-items: center;
  border-radius: 50px;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: none;
  position: relative;
  margin-bottom: 0.28rem;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.cardBackground};
    background-color: ${(props) => props.theme.colors.cardBackground};
  }	
`

export const ContainerOccurreceForm = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 24px;
  overflow-y: auto;
  max-height: 70vh;

  & > h1 {
    width: 100%;
    text-align: left;
    ${(props) => props.theme.fonts.primary.H1Semibold};
    color: ${(props) => props.theme.colors.textPrimary};
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    @media screen and (max-width: 767px) {
      width: 3px !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    max-height: 60vh;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  // Occurrence Info
  & > div:nth-child(2) {
    justify-content: flex-start;
    column-gap: 1rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;

    & > div:nth-child(1) {
      flex: 1 1 200px;
    }
    & > div:nth-child(2) {
      flex: 1 1 200px;
    }
    // Dias para Ação
    & > div:nth-child(3) {
      flex: 0 0 120px;
    }

    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H5Regular};

    opacity: ${(props) => (props.disabled ? 0.2 : "")};

    @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    }

    @media screen and (max-width: 767px) {
      padding: 0.6rem 0 0.25rem 0;
      width: 100%;
      margin: 0.3rem 0;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      input,
      select,
      textarea,
      label {
        font-size: 1rem;
      }

      & > div {
        input,
        select,
        textarea,
        label {
          font-size: 1rem;
        }
      }

      & > div:nth-child(1) {
        width: 100%;
        margin-right: 0;
      }
      & > div:nth-child(2) {
        width: 92%;
        margin-right: 0;
      }
      & > div:nth-child(3) {
        width: 30%;
        margin-right: 0;
      }
      & > div:nth-child(4) {
        width: 60% !important;
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1240px) {
      flex-wrap: wrap;
      & > div:nth-child(1) {
        flex: 0 0 200px;
      }
      & > div:nth-child(2) {
        flex: 1 0 200px;
      }
      & > div:nth-child(3) {
        flex: 0 0 120px;
      }
      & > div:nth-child(4) {
        flex: 1 0 100%;
      }
    }

    @media screen and (max-width: 1048px) {
      flex-wrap: wrap;

      & > div:nth-child(1) {
        flex: 1 0 200px;
        order: 1;
      }
      & > div:nth-child(2) {
        flex: 1 0 100%;
        order: 3;
      }
      & > div:nth-child(3) {
        flex: 0 0 120px;
        order: 2;
      }
      & > div:nth-child(4) {
        flex: 1 0 100%;
        order: 4;
      }
    }

    @media screen and (max-width: 467px) {
      & > div:nth-child(1) {
        flex: 1 0 100%;
        order: 1;
      }
      & > div:nth-child(2) {
        flex: 1 0 100%;
        order: 2;
      }
      & > div:nth-child(3) {
        flex: 0 0 100%;
        order: 3;
      }
      & > div:nth-child(4) {
        flex: 1 0 100%;
        order: 4;
      }
    }
  }
`;


export const OccurrenceCustomFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const OccurrenceCustomFieldTitle = styled.h2`
  font-size: 14px;
  color: ${(props) => props.theme.colors.iconsPrimary};
  font-weight: 400;
`

export const NotOccurrenceCustomField = styled.div`
  width: 100%;

  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.iconsSecondary};
    font-weight: 400; 
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.iconsSecondary};

      &:hover {
        color: ${(props) => props.theme.colors.ibbxOrange};
      }
    }
  }
`
import { lazy, Suspense } from "react";
import { Routes as RRDRoutes, Route as RRDRoute } from "react-router-dom";
import { AppLoading } from "../components/Loading";
import Representatives from "../pages/Configuration/Representatives";

import UserForm from "../pages/Configuration/Users/UserForm";
import { EsgPanel } from "../pages/EsgPanel";
import Occurrence from "../pages/Occurrence";
import Occurrences from "../pages/Occurrences";

import { PROFILES } from "../store/slices/userSlice";

import { NotFoundRoute } from "./NotFountRoute";
import { Route } from "./Route";
import { ROUTES_PATH } from "./RoutesPath";
import Theme from "../pages/Configuration/themes";
import { Notifications } from "../pages/Configuration/Notifications";
import { Analysis } from "../pages/Analysis";
import Integration from "../pages/Configuration/Integration";
import { Hub } from "../pages/Hub";
import { ClearCache } from "../pages/ClearCache";
import Solutions from "../pages/Configuration/Devices";
import Connectivity from "../pages/Connectivity";
import { AssetTypes } from "../pages/Configuration/AssetTypes";

const Login = lazy(() => import("../pages/Login"));
const Companies = lazy(() => import("../pages/Companies"));
const Position = lazy(() => import("../pages/Position"));
const Facilities = lazy(() => import("../pages/Facilities"));
const NewPassword = lazy(() => import("../pages/NewPassword"));
const Terms = lazy(() => import("../pages/Terms"));
const Assets = lazy(() => import("../pages/Assets"));
const Asset = lazy(() => import("../pages/Asset"));

const Profile = lazy(() => import("../pages/Configuration/Profile"));

const CompanyDetails = lazy(
  () => import("../pages/Configuration/CompanyDetails"),
);
const Alarms = lazy(() => import("../pages/Configuration/Alarms"));
const Users = lazy(() => import("../pages/Configuration/Users"));

const ALL_USERS = [
  PROFILES.EXECUTIVO_MASTER,
  PROFILES.MESA_MASTER,
  PROFILES.MESA_ANALISTA,
  PROFILES.CLIENTE_MASTER,
  PROFILES.CLIENTE_MASTER_VIEW,
  PROFILES.CLIENTE_COMUM,
  PROFILES.REPRESENTANTE,
];

const APP_ROUTES = [
  {
    path: "/login",
    guest: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/new-password",
    guest: true,
    newPassword: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <NewPassword />
      </Suspense>
    ),
  },
  {
    path: "/terms",
    terms: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Terms />
      </Suspense>
    ),
  },
  {
    path: "/",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Companies />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.REPRESENTANTE,
      PROFILES.CLIENTE_MASTER,
    ],
  },
  {
    path: ROUTES_PATH.FACILITIES,
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Facilities />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.CLIENTE_MASTER,
      PROFILES.CLIENTE_MASTER_VIEW,
      PROFILES.CLIENTE_COMUM,
      PROFILES.REPRESENTANTE,
    ],
  },
  {
    path: "/companies/:companyId/facilities/:facilityId/assets",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Assets />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/companies/:companyId/facilities/:facilityId/assets/:assetId",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Asset />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/positions/:positionId",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Position />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/profile",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Profile />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/details",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <CompanyDetails />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.CLIENTE_MASTER,
      PROFILES.CLIENTE_MASTER_VIEW,
      PROFILES.REPRESENTANTE,
    ],
  },
  {
    path: "/configuration/:companyId/alarms",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Alarms />
      </Suspense>
    ),
    role: [PROFILES.EXECUTIVO_MASTER, PROFILES.MESA_MASTER],
  },
  {
    path: "/configuration/:companyId/users",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Users />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.CLIENTE_MASTER,
      PROFILES.CLIENTE_MASTER_VIEW,
      PROFILES.REPRESENTANTE,
    ],
  },
  {
    path: ROUTES_PATH.REPRESENTATIVES,
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Representatives />
      </Suspense>
    ),
    role: [PROFILES.EXECUTIVO_MASTER, PROFILES.MESA_MASTER],
  },
  {
    path: "/configuration/:companyId/users/new",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <UserForm />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.CLIENTE_MASTER,
      PROFILES.REPRESENTANTE,
    ],
  },
  {
    path: "/configuration/:companyId/users/edit/:userId",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <UserForm />
      </Suspense>
    ),
    role: [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_MASTER,
      PROFILES.CLIENTE_MASTER,
      PROFILES.REPRESENTANTE,
    ],
  },
  {
    path: "/configuration/:companyId/asset-types",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <AssetTypes />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/api",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Integration />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/occurrences",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Occurrences />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/assets/:assetId/occurrences/:occurrenceId",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Occurrence />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/esgPanel",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <EsgPanel />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/color_theme",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Theme />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/notifications",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Notifications />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/positions/:positionId/analysis",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Analysis />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/hub",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Hub />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/clear-cache",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <ClearCache />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/configuration/:companyId/devices",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Solutions />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/companies/:companyId/connectivity",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Connectivity />
      </Suspense>
    ),
    role: ALL_USERS,
  },
  {
    path: "/companies/:companyId/facilities/:facilityId/connectivity",
    private: true,
    component: (
      <Suspense fallback={<AppLoading />}>
        <Connectivity />
      </Suspense>
    ),
    role: ALL_USERS,
  },
];

export const Routes = () => {
  return (
    <RRDRoutes>
      {APP_ROUTES.map((route: any, index) => (
        <RRDRoute
          key={index}
          path={route.path}
          element={
            <Route
              isGuest={route.guest}
              isPrivate={route.private}
              isTerms={route.terms}
              role={route.role}
            >
              {route.component}
            </Route>
          }
        />
      ))}

      <RRDRoute path="*" element={<NotFoundRoute />} />
    </RRDRoutes>
  );
};

import { useField, useFormikContext } from "formik";
import { ImageUploadForm } from "../../../../../modals/ImageUploadForm";
import { AssetTypeImageWrapper } from "./styles";

export function AssetTypeImage() {
  const { setFieldValue } = useFormikContext();
  const [{ value: image }] = useField("image");
  const [{ value: imageUrl }] = useField("imageUrl");

  const handleChangeImage = (image: string) => {
    setFieldValue("image", image);
  };

  return (
    <AssetTypeImageWrapper>
      <ImageUploadForm
        image={image || imageUrl}
        handleChangeImage={handleChangeImage}
      />
    </AssetTypeImageWrapper>
  );
}

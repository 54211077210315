import { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "../../utils/queryString";
import { MdShowChart } from "react-icons/md";
import { HiChartBar, HiLink } from "react-icons/hi";
import { endOfDay, format, startOfDay } from "date-fns";
import { BsClipboardData } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import { exportComponentAsJPEG } from "react-component-export-image";
import { IoMdClose } from "react-icons/io";

import useApi from "../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { appSelect, appSetCurrentPosition } from "../../store/slices/appSlice";
import { formatGlobalResponse } from "../../utils/charts/general";

import {
  ButtonDownlaod,
  ChartContainer,
  CloseButton,
  ContainerGeneral,
  ContainerHeader,
  ContainerSelect,
  ContainerTitle,
  EmbededLink,
  GraphContainer,
  GraphFooter,
  GraphHeader,
} from "./styles";
import { SeriesSelector } from "../../components/charts/seriesSelector";
import { AnalisysButton } from "../../components/charts/Graphic/styles";

import { dynamicSelectDateModel } from "../../components/charts/chartsList/types";

import { DynamicDateSelect } from "../../components/DynamicDateSelect";
import ChartDataExport from "../../components/charts/ChartDataExport";

import GlobalPeriodSelector from "./GlobalPeriodSelector";
import GlobalPrecisionSelect from "./GlobalPrecisionSelect";
import AxisSelector from "./AxisSelector";
import useRetinaTranslation from "../../hooks/useRetinaTranslation";
import { success } from "../../components/Toast";
import useChartAnalysis from "./useChartAnalysis";
import useSeriesControl from "../../hooks/useSeriesControl";
import AnalysisTools from "./AnalysisTools";
import StampsSelector from "./StampsSelector";
import { IAnalysis } from "./AnalysisTools/types";
import { IPosition } from "../../store/interfaces";
import { AnalysisChartRender } from "./AnalysisChartRender";

export const Analysis = () => {
  const params = useParams();
  const location = useLocation();
  const { positionId } = params;
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { config, chart, loadChart } = useChartAnalysis();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (!currentPosition.id) {
      loadPosition();
    }
    // eslint-disable-next-line
  }, []);

  const refContainer = useRef();

  const { getGraphicLabel } = useRetinaTranslation();

  let { viewType = "LINE", period, precision } = queryParams;

  const dispatch = useAppDispatch();
  function loadPosition() {
    request({ method: "get" }).then((position: IPosition) => {
      dispatch(appSetCurrentPosition(position));
    });
  }

  const { currentPosition } = useAppSelector(appSelect);

  const [chartData, setChartData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState<IAnalysis>({});
  const [viewMode, setViewMode] = useState<any>(viewType || "LINE");
  const [noiseFilter, setNoiseFilter] = useState<number | null>(null);
  const [chartDataExport, setChartDataExport] = useState(false);
  const [selectedSerie, setSelectedSerie] = useState("");
  const { seriesControl, buildSeriesControl, handleSeries } =
    useSeriesControl();
  // Global Period
  const todayStart = startOfDay(new Date());
  const todayEnd = endOfDay(new Date());

  const [dateRange, setDateRange]: [any, any] = useState([
    +queryParams?.minDate ? new Date(+queryParams.minDate) : todayStart,
    +queryParams?.maxDate ? new Date(+queryParams.maxDate) : todayEnd,
  ]);

  const { request, processing } = useApi({ path: `/positions/${positionId}` });

  const date = useMemo(() => {
    if (queryParams.date) {
      const date: dynamicSelectDateModel = {
        label: ` ${format(+queryParams.date, "HH:mm")}`,
        value: new Date(+queryParams.date),
      };
      return date;
    }
    return null;
  }, [queryParams.date]);

  const handleChartDataExport = () => {
    setChartDataExport(true);
  };

  const handleDateChange = (item: any) => {
    const query = queryString.parse(location.search);
    delete query.txDate;
    if (item?.txDate) {
      query.txDate = item.txDate;
    }

    setSearchParams(query);
  };

  const handleToggleViewMode = () => {
    setViewMode(viewMode === "LINE" ? "COLUMN" : "LINE");
  };

  const getChartsData = (args: any = {}) => {
    if (!chart) {
      return;
    }
    setLoading(true);
    let queryStringParameters = { ...queryParams, ...args };
    queryStringParameters.date = queryStringParameters.txDate;

    if (noiseFilter?.toString()) {
      queryStringParameters.noiseFilter = noiseFilter;
    }
    let pathParameters = `charts/${chart.name}`;
    if (analysis.temperatureCorrelation) {
      pathParameters = pathParameters + "/temperature";
    }

    request({ method: "get", queryStringParameters, pathParameters })
      .then((response) => {
        if (chart.type === "GLOBAL") {
          response = formatGlobalResponse(response);
        }

        const seriesControl: any[] = buildSeriesControl(response, analysis);

        if (chart.type === "DYNAMIC" && selectedSerie === "") {
          const firstSerie = seriesControl.filter((serie) => !serie.hidden)[0];
          setSelectedSerie(firstSerie.name);
        }

        setChartData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (analysis.regression) {
      setLoading(false);
      return;
    }

    if (config.LOAD_DATA_IN_CHILD) {
      setLoading(false);
      return;
    }
    let params: any = {};
    if (!chart) {
      return;
    }

    if (analysis.temperatureCorrelation) {
      params.axis = selectedSerie;
      params.alarmType = "SMART";
    }

    if (queryParams.period === "CUSTOM" && !queryParams?.maxDate) {
      return;
    }

    if (chart.type === "DYNAMIC" && !date) {
      setChartData({ data: [] });
    } else {
      //Só tera auto refresh, caso seja tipo GLOBAL

      switch (chart.type) {
        case "DYNAMIC": {
          return getChartsData(params);
        }
        case "GLOBAL": {
          getChartsData();

          const minutes = 10 * 60 * 1000;

          const interval = setInterval(() => {
            getChartsData(params);
          }, minutes);

          return () => clearInterval(interval);
        }
      }
    }

    // eslint-disable-next-line
  }, [
    chart,
    period,
    precision,
    analysis.temperatureCorrelation,
    date,
    queryParams.minDate,
    queryParams.maxDate,
    queryParams.txDate,
    noiseFilter,
  ]);

  useEffect(() => {
    loadChart();
    // eslint-disable-next-line
  }, []);

  function copyToClipboard(text: string) {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  function getEmbededAnalysisLink() {
    if (!chart) {
      return "#";
    }
    const baseLink = `${window.location.origin}/positions/${positionId}/analysis?chartName=${chart.name}`;
    switch (chart.type) {
      case "GLOBAL":
        const globalLing = `${baseLink}&period=${period}&viewMode=${viewMode}&type=GLOBAL&embeded=1`;
        copyToClipboard(globalLing);
        success(`Link ${chart?.label} copiado com sucesso!`);
        return globalLing;
      case "DYNAMIC":
        let baseUrl = `${baseLink}&type=DYNAMIC`;
        if (!isNaN(queryParams.txDate)) {
          baseUrl = `${baseUrl}&date=${queryParams.txDate}&txDate=${queryParams.txDate}`;
        } else if (!isNaN(queryParams.date)) {
          baseUrl = `${baseUrl}&date=${queryParams.date}`;
        }
        const dynamicLink = `${baseUrl}&embeded=1`;
        copyToClipboard(dynamicLink);
        success(`Link ${chart?.label} copiado com sucesso!`);
        return dynamicLink;
    }
  }
  const showFooter = !analysis.regression;

  const renderFooter = () => {
    if (!showFooter || (loading && config.LOADING) || processing) {
      return <></>;
    }
    switch (true) {
      case chart.notFound:
        return <></>;
      case analysis?.cascade:
        return (
          <AxisSelector
            series={chart?.series || []}
            selectedSerie={selectedSerie}
            setSelectedSerie={setSelectedSerie}
            seriesControl={seriesControl}
            chart={chart}
            noColors={!!analysis?.cascade}
          />
        );
      default:
        return (
          <>
            <SeriesSelector
              chart={chart}
              seriesControl={seriesControl}
              handleSeries={handleSeries}
            />
            {chart?.name?.includes("0x") && (
              <div>
                <AnalisysButton
                  title={
                    viewMode === "COLUMN"
                      ? (t("analysis.showRows") as string)
                      : (t("analysis.showColumns") as string)
                  }
                  onClick={handleToggleViewMode}
                >
                  {viewMode === "COLUMN" ? (
                    <MdShowChart size={20} />
                  ) : (
                    <HiChartBar size={20} />
                  )}
                </AnalisysButton>
              </div>
            )}
          </>
        );
    }
  };

  function getCloseLink() {
    const queryParams = queryString.parse(location.search);
    if (queryParams.from) {
      return queryParams.from as string;
    } else if (chart?.type === "GLOBAL") {
      return `/positions/${positionId}?tab=global`;
    } else {
      return `/positions/${positionId}?tab=dynamic&date=${queryParams?.date}&txDate=${queryParams.txDate}`;
    }
  }

  return (
    <>
      <>
        <ContainerGeneral ref={refContainer}>
          {config.TITLE && (
            <ContainerHeader>
              <ContainerTitle>
                {(!!analysis?.cascade
                  ? `${t("analysis.cascadeTitle")} - `
                  : "") + `${getGraphicLabel(chart?.label ?? "")}`}
              </ContainerTitle>
              <Link to={getCloseLink()}>
                <CloseButton>
                  <IoMdClose size={18} /> {t("commonText.close")}
                </CloseButton>
              </Link>
            </ContainerHeader>
          )}
          <ChartContainer embeded={queryParams.embeded}>
            <GraphContainer embeded={queryParams.embeded}>
              {chart && (
                <GraphHeader absolute={!!analysis?.cascade}>
                  {config.ANALYSIS_TOOLS && (
                    <AnalysisTools
                      chart={chart}
                      position={currentPosition}
                      setAnalysis={setAnalysis}
                      noiseFilter={noiseFilter}
                      setNoiseFilter={setNoiseFilter}
                      hiddenItem={queryParams.embeded}
                    />
                  )}
                  {!analysis?.cascade && config.CHART_TOOLS && (
                    <>
                      <ButtonDownlaod
                        title={t("analysis.exportData") as string}
                        onClick={handleChartDataExport}
                      >
                        <BsClipboardData size={18} />
                      </ButtonDownlaod>
                      <ButtonDownlaod
                        title={t("analysis.exportImage") as string}
                      >
                        <AiOutlineDownload
                          onClick={() =>
                            exportComponentAsJPEG(refContainer as any)
                          }
                        />
                      </ButtonDownlaod>
                    </>
                  )}
                  {config.CHART_TOOLS && (
                    <ContainerSelect>
                      {chart?.type === "GLOBAL" && <GlobalPrecisionSelect />}
                      {chart?.type === "DYNAMIC" ? (
                        <DynamicDateSelect
                          chart={chart}
                          handleDateChange={handleDateChange}
                          dateFromGlobalPoint={{
                            value: new Date(
                              queryParams?.date
                                ? +queryParams?.date
                                : new Date(),
                            ),
                          }}
                          positionId={positionId}
                        />
                      ) : (
                        <GlobalPeriodSelector
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                        />
                      )}
                    </ContainerSelect>
                  )}
                </GraphHeader>
              )}

              {!loading && analysis?.bearingStamps && (
                <StampsSelector analysis={analysis} setAnalysis={setAnalysis} />
              )}
              <AnalysisChartRender
                chart={chart}
                analysis={analysis}
                chartData={chartData}
                positionId={positionId}
                selectedSerie={selectedSerie}
                seriesControl={seriesControl}
                setAnalysis={setAnalysis}
                setSelectedSerie={setSelectedSerie}
                viewMode={viewMode}
                isLoading={loading}
              />
              <GraphFooter absolute>
                {renderFooter()}
                <EmbededLink
                  onClick={getEmbededAnalysisLink}
                  title="Copiar Embeded Link"
                >
                  <HiLink size={24} />
                </EmbededLink>
              </GraphFooter>
            </GraphContainer>
          </ChartContainer>
        </ContainerGeneral>
        {chart && chartDataExport && (
          <ChartDataExport
            chart={chart}
            chartData={chartData}
            setRunning={setChartDataExport}
          />
        )}
      </>
    </>
  );
};

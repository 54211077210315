import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { getUrlPattern } from "../../../utils/getUrlPattern";
import {
  Divider,
  FixedSpace,
  FrameHeaderCenter,
  FrameHeaderRight,
  FullHeader,
  NavBarContainer,
} from "./styles";

import { HomePageButton } from "../../HomePageButton";
import { OccurrenceGoTo } from "../../OccurrenceGoTo";
import MenuProfile from "../../MenuProfile";
import { FilterBar } from "../../FilterBar";
import BreadCrumb from "../BreadCrumb";
import { NotificationButton } from "../../NotificationButton";
import { isMobile } from "../../../utils/mediaQuery";
import { isMobileOnly, isMobile as mobileDevices } from "react-device-detect";
import { FilerBarMobile } from "../../FilterBarMobile";
import { EsgPanelButtonGoTo } from "../../EsgPanelButtonGoTo";
import NavBarLogo from "./NavBarLogo";
import { useAppSelector } from "../../../store/hooks";
import { appSelect } from "../../../store/slices/appSlice";
import { PROFILES, userSelect } from "../../../store/slices/userSlice";
import { navbarIconsSelect } from "../../../store/slices/navbarIconsSlice";
import { useTranslation } from "react-i18next";
import { ROUTES_PATH } from "../../../routes/RoutesPath";
import { HubGoto } from "../../../pages/Hub/HubGoTo";
import { NamePlusTooltip } from "../../NamePlusTooltip";
import { NavigateToConnectivity } from "./NavigateToConnectivity";
import { HelpButton } from "../../HelpPageButton";
interface INabBar {
  fixed?: boolean;
  runJoyride?: boolean | undefined;
  setRunJoyride?: (value: boolean) => void;
}

export const NavBar = ({ fixed, runJoyride, setRunJoyride }: INabBar) => {
  // Hooks
  const location = useLocation();
  const params = useParams();
  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);

  const isMasterLevel = useMemo(() => {
    const urlPattern = getUrlPattern(location, params);
    if (
      urlPattern.startsWith("/configuration/") &&
      params.companyId === "ibbx"
    ) {
      return true;
    }

    return ROUTES_PATH.COMPANIES === urlPattern;
    // eslint-disable-next-line
  }, []);

  const { t } = useTranslation();

  const urlPattern = getUrlPattern(location, params);

  const showFilterBar = urlPattern === ROUTES_PATH.OCCURRENCES;

  const headerRef = useRef<HTMLDivElement>(null);

  const [isEsgPanel, setIsEsgPanel] = useState<boolean>(false);

  const { selectedIcons = [] } = useAppSelector(navbarIconsSelect);

  const userClient = [
    PROFILES.CLIENTE_COMUM,
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(user.profile);

  useEffect(() => {
    setIsEsgPanel(urlPattern.includes("esgPanel"));
  }, [urlPattern]);

  const theme = useMemo(() => {
    const integratorTheme: any = app.colorTheme || {};
    const companyTheme = app.currentCompany.theme || {};

    switch (true) {
      case isEsgPanel:
        return { navbarColor: "#1B2B2C", iconsColor: "#1B2B2C" };
      case isMasterLevel && ![PROFILES.REPRESENTANTE].includes(user.profile):
        return { navbarColor: "", iconsColor: "" };
      case isMasterLevel && [PROFILES.REPRESENTANTE].includes(user.profile):
        return {
          navbarColor: integratorTheme.navbarColor || "",
          iconsColor: integratorTheme.iconsColor || "",
        };
      default:
        return {
          navbarColor: companyTheme.navbarColor || integratorTheme.navbarColor,
          iconsColor: companyTheme.iconsColor || integratorTheme.iconsColor,
        };
    }
    // eslint-disable-next-line
  }, [app.colorTheme, app.currentCompany.id, isEsgPanel]);

  const isCurrentEsgPage = urlPattern.includes(ROUTES_PATH.ESG);
  const isDevelopmentAndQa =
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "qa";

  const thereIsCompanyId = !!app.currentCompany.id;

  const showConnectivity =
    (!location.pathname.includes("connectivity") && isMobileOnly) ||
    !isMobileOnly;

  return (
    <>
      <FullHeader fixed={fixed} ref={headerRef}>
        <NavBarContainer
          hasMargin={!showFilterBar}
          fixed={fixed}
          esgMargin={isEsgPanel}
          bgColor={isEsgPanel}
          customColor={theme.navbarColor}
        >
          {!isMobileOnly && <NavBarLogo />}

          <FrameHeaderCenter bgColor={isEsgPanel} iconsColor={theme.iconsColor}>
            {location.pathname !== "/" && location.pathname !== "/help" && (
              <BreadCrumb navbarColor={theme.navbarColor} />
            )}
          </FrameHeaderCenter>

          <FrameHeaderRight
            isMobile={isMobileOnly}
            navbarColor={theme.navbarColor}
            iconsColor={theme.iconsColor}
            isCurrentEsgPage={isCurrentEsgPage}
          >
            {!selectedIcons.includes("hub") &&
              params.companyId &&
              userClient &&
              !!(app.apps || []).length && (
                <NamePlusTooltip tooltip="Hub" position={"bottom"}>
                  <div>
                    <HubGoto navbarColor={theme.navbarColor} />
                  </div>
                </NamePlusTooltip>
              )}

            {!selectedIcons.includes("mainDashboard") && (
              <NamePlusTooltip tooltip="Dashboard" position={"bottom"}>
                <div>
                  {(!isMobileOnly ||
                    (isMobileOnly &&
                      location.pathname.includes("connectivity"))) && (
                    <HomePageButton navbarColor={theme.navbarColor} />
                  )}
                </div>
              </NamePlusTooltip>
            )}
            {!selectedIcons.includes("connectivity") &&
              ROUTES_PATH.COMPANIES !== urlPattern &&
              params.companyId !== "ibbx" &&
              thereIsCompanyId && (
                <NamePlusTooltip
                  tooltip={runJoyride ? "" : `${t("iconsNavbar.connectivity")}`}
                  position={"bottom"}
                >
                  <div
                    style={{ display: !!showConnectivity ? "flex" : "none" }}
                  >
                    <NavigateToConnectivity
                      navbarColor={theme.navbarColor}
                      runJoyride={runJoyride}
                      setRunJoyride={setRunJoyride}
                      visible={showConnectivity}
                    />
                  </div>
                </NamePlusTooltip>
              )}

            {!isMobileOnly && !selectedIcons.includes("occurrence") && (
              <NamePlusTooltip
                tooltip={`${t("iconsNavbar.occurrence")}`}
                position={"bottom"}
              >
                <div>
                  {!isMobileOnly && (
                    <OccurrenceGoTo navbarColor={theme.navbarColor} />
                  )}
                </div>
              </NamePlusTooltip>
            )}
            {!isMobileOnly && !selectedIcons.includes("esgPanel") && (
              <NamePlusTooltip
                tooltip={`${t("iconsNavbar.esgPanel")}`}
                position={"bottom"}
              >
                <div>
                  <EsgPanelButtonGoTo navbarColor={theme.navbarColor} />
                </div>
              </NamePlusTooltip>
            )}

            {!isMobileOnly &&
              isDevelopmentAndQa &&
              !selectedIcons.includes("help") && (
                <NamePlusTooltip
                  tooltip={`${t("CustomNavbarIcons.help")}`}
                  position="bottom"
                >
                  <div>
                    <HelpButton />
                  </div>
                </NamePlusTooltip>
              )}

            {(!selectedIcons.includes("mainDashboard") ||
              !selectedIcons.includes("occurrence") ||
              !selectedIcons.includes("esgPanel")) && <Divider />}

            {!isMobileOnly && !selectedIcons.includes("notifications") && (
              <NamePlusTooltip
                tooltip={`${t("iconsNavbar.notifications")}`}
                position={"bottom"}
              >
                <div>
                  {!isMobileOnly && (
                    <NotificationButton navbarColor={theme.navbarColor} />
                  )}
                </div>
              </NamePlusTooltip>
            )}
            <NamePlusTooltip tooltip="Profile" position={"bottom"}>
              <div>
                <MenuProfile navbarColor={theme.navbarColor} />
              </div>
            </NamePlusTooltip>
          </FrameHeaderRight>
        </NavBarContainer>
        {showFilterBar && isMobile() ? (
          <FilerBarMobile />
        ) : (
          showFilterBar && <FilterBar fixed={fixed} />
        )}
      </FullHeader>
      {headerRef.current?.clientHeight && fixed && (
        <FixedSpace height={headerRef.current?.clientHeight} />
      )}
    </>
  );
};

import { FormikProps } from "formik";
import { RefObject, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { appSelect, setOccurrenceRegister } from "../../store/slices/appSlice";
import { addDays, differenceInDays } from "date-fns";
import useApi from "../../hooks/useApi";
import { success } from "../../components/Toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IuseOccurrenceFullModal {
  formRef: RefObject<FormikProps<any>>;
  refresh?: () => void;
}

export default function useOccurrenceForm({
  formRef,
  refresh = () => null,
}: IuseOccurrenceFullModal) {
  const dispatch = useAppDispatch();
  const { occurrenceRegister } = useAppSelector(appSelect);
  const { id: occurrenceId, assetId } = occurrenceRegister;
  const { assetId: assetIdParams } = useParams();

  const [occurrence, setOccurrence] = useState<any>(null);
  const { t } = useTranslation();

  const { request, processing } = useApi({
    path: `/assets/${assetId || assetIdParams}/occurrences`,
  });

  const loadOccurrence = useCallback(async () => {
    let response: any = {};

    if (occurrenceId) {
      response = await request({ method: "get", pathParameters: occurrenceId });
      const { dueDate, createdAt } = response;

      if (dueDate) {
        response.days = differenceInDays(
          new Date(dueDate),
          new Date(createdAt),
        );
      }
    }

    if (response?.occurrenceCustomFields?.length) {
      response.extra = response.extra || {};
      if (response?.occurrenceCustomFields?.length) {
        response.extra = response.extra || {};
        response.extra.occurrenceCustomFields =
          response.extra.occurrenceCustomFields || [];
        const existingIds = response.extra.occurrenceCustomFields?.map(
          (field: any) => field.id,
        );
        const newFields = response.occurrenceCustomFields
          .filter((field: any) => !existingIds?.includes(field.id))
          .map((field: any) => ({ id: field.id, value: "", key: field.key }));
        response.extra.occurrenceCustomFields = [
          ...response.extra.occurrenceCustomFields,
          ...newFields,
        ];
      }
    }

    formRef.current?.setValues({
      sysStatusId: 3,
      ...response,
      updatedAt: null,
      createdAt: null,
      dueDate: null,
      category: response.category === null ? "OTHERS" : response.category,
      // occurrenceCustomFields: response.occurrenceCustomFields,
    });

    setOccurrence(response);
    // eslint-disable-next-line
  }, [occurrenceId]);

  useEffect(() => {
    if (occurrenceId) {
      loadOccurrence();
    }
  }, [occurrenceId, loadOccurrence]);

  function onSubmit(values: any) {
    let body = values;

    if (occurrenceRegister.id) {
      body = getBody(formRef.current?.values);

      if (!Object.keys(body).length) {
        handleClose();
        return;
      }
    } else if (values.days) {
      body.dueDate = daysToDueDate(values.days);
    }

    if (body.category && body.category === "OTHERS") {
      body.category = null;
    }

    const method = occurrenceRegister.id ? "put" : "post";
    const pathParameters = occurrenceRegister.id || null;

    request({ method, body, pathParameters }).then(() => {
      success("Ocorrência Atualizada com Sucesso");
      handleClose();
      refresh();
    });
  }

  function validateDiagnostics(diagnostics: any[]) {
    const errors = diagnostics.reduce((acc, item, i) => {
      if (!item.diagnostic && (item.recommendation || item.comments)) {
        acc[`diagnostics[${i}][diagnostic]`] = t(
          "OccurrenceForm.errors.diagnostic",
        );
      }
      return acc;
    }, {});

    return errors;
  }

  function validate(values: any) {
    let errors: any = {};

    if (!values.sysStatusId) {
      errors.sysStatusId = "";
    }

    const diagnosticsErrors = validateDiagnostics(values.diagnostics);
    errors = { ...errors, ...diagnosticsErrors };

    return errors;
  }

  function handleClose() {
    formRef.current?.setValues({});
    dispatch(setOccurrenceRegister({ show: false, id: 0, assetId: 0 }));
  }

  function hasDiagnosticChanged(diagnostic: any) {
    const { diagnostics = [] } = occurrence ?? {};
    let current = diagnostics.find(({ id }: any) => id === diagnostic.id);

    if (!current) {
      return diagnostic;
    }

    let result: any = {};

    if (diagnostic.diagnostic !== current.diagnostic) {
      result = { ...result, diagnostic: diagnostic.diagnostic };
    }

    if (diagnostic.recommendation !== current.diagnostic) {
      result = { ...result, recommendation: diagnostic.recommendation };
    }

    if (diagnostic.comments !== current.comments) {
      result = { ...result, comments: diagnostic.comments };
    }

    if (diagnostic.deleted) {
      result = { deleted: true };
    }

    if (Object.keys(result)) {
      result.id = diagnostic.id;
    }

    return result.id ? result : null;
  }

  function filterDiagnostics(diagnostics: any[]) {
    return diagnostics.reduce((acc, item) => {
      let result = hasDiagnosticChanged(item);

      if (result
        // && item.diagnostic
      ) {
        if (result.diagnostic === null) {
          result.diagnostic = " ";
        }

        acc.push(result);
      }

      return acc;
    }, []);
  }

  function daysToDueDate(days: number) {
    if (days?.toString()) {
      const start = new Date(occurrence?.createdAt ?? new Date());
      return addDays(start, +days).toISOString();
    }

    return null;
  }

  function getBody(body: any) {
    let result: any = {};

    if (body.createdAt && occurrence?.createdAt !== body.createdAt) {
      result.createdAt = body.createdAt;
    }

    if (body.days !== occurrence?.days) {
      result.dueDate = daysToDueDate(body.days);
    }

    if (occurrence?.sysStatusId !== body.sysStatusId) {
      result.sysStatusId = body.sysStatusId;
    }

    if (occurrence?.comments !== body.comments) {
      result.comments = body.comments;
    }

    if (body?.diagnostics?.length) {
      result.diagnostics = filterDiagnostics(body?.diagnostics ?? []);
    }

    if (body?.occurrenceCustomFields?.length) {
      result.extra = result.extra || {};
      result.extra.occurrenceCustomFields = body.extra.occurrenceCustomFields;
    }

    const currentCategory = occurrence?.category ?? "OTHERS";
    if (body?.category !== currentCategory) {
      result.category = body.category;
    }

    return result;
  }

  return {
    handleClose,
    occurrence,
    daysToDueDate,
    validate,
    onSubmit,
    processingOccurrence: processing,
  };
}

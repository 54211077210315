import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DrawerComponent } from "../../../../components/DrawerComponent";
import { AnalyticsComponent } from "../../../../components/Analytics/AnalyticsComponent";
import ButtonAddV2 from "../../../../components/ButtonAddV2";
import { isMobileOnly } from "react-device-detect";
import {
  DiagnosticDrawerAddButtonWrapper,
  DiagnosticListLabel,
  DiagnosticListRow,
} from "./styles";
import { DiagnosticDrawerForm } from "./DiagnosticDrawerForm";
import { ExplanationLabel } from "../../../../components/ExplanationLabel";
import { InputRadio } from "../../../../modals/ConfirmRecall/Components/InputRadio";
import { useField, useFormikContext } from "formik";
import useApi from "../../../../hooks/useApi";
import { FormCheck } from "react-bootstrap";
import { MdClose, MdEdit, MdRemove } from "react-icons/md";
import useTranslator from "../../../../hooks/useTranslator";

interface IDiagnosticsDrawer {
  isOpen: boolean;
  handleOpen?: () => void;
  onClose: () => void;
}

export function DiagnosticsDrawer({ isOpen, onClose }: IDiagnosticsDrawer) {
  const [diagnosticList, setDiagnosticList] = useState<any[]>([]);
  const [form, setForm] = useState({
    expanded: false,
    diagnosticId: 0,
    nameInPT: "",
    nameInEN: "",
    nameInES: "",
  });
  const [selecteDiagnosticsIds, setSelectedDiagnosticIds] = useState<number[]>(
    [],
  );

  const { setFieldValue } = useFormikContext();
  const [{ value: diagnostics }] = useField("diagnostics");

  const { t, i18n } = useTranslation();
  const translator = useTranslator("AssetTypePage");
  const { request, processing } = useApi({ path: "/diagnostics" });

  function loadDiagnostics() {
    request({ method: "get" }).then((response: any[]) => {
      setDiagnosticList(response);
    });
  }

  useEffect(() => {
    loadDiagnostics();
  }, []);

  const handleConfirm = () => {
    const selectedDiagnostics = diagnosticList
      .filter(({ id }: any) => selecteDiagnosticsIds.includes(id))
      .map((diagnostic) => {
        return {
          diagnosticId: diagnostic.id,
          name: diagnostic.nameInPT,
          nameInPT: diagnostic.nameInPT,
          nameInEN: diagnostic.nameInEN,
          nameInES: diagnostic.nameInES,
          sugestionInPT: "",
          sugestionInEN: "",
          sugestionInES: "",
        };
      });

    setFieldValue("diagnostics", selectedDiagnostics.concat(diagnostics));

    onClose();
  };

  const handleCheck = (item: any) => {
    if (selecteDiagnosticsIds.includes(item.id)) {
      setSelectedDiagnosticIds((cur) => cur.filter((id) => id !== item.id));
      return;
    }

    setSelectedDiagnosticIds((cur) => cur.concat([item.id]));
  };

  const handleDelete = (item: any) => {
    request({ method: "del", pathParameters: item.id }).then(() => {
      loadDiagnostics();
      if (selecteDiagnosticsIds.includes(item.id)) {
        setSelectedDiagnosticIds((cur) => cur.filter((id) => id !== item.id));
      }
    });
  };

  return (
    <DrawerComponent
      isOpen={isOpen}
      direction="right"
      size={isMobileOnly ? 300 : 450}
      handleClose={onClose}
    >
      <AnalyticsComponent>
        <>
          <AnalyticsComponent.Body>
            <AnalyticsComponent.Title title={t("AssetTypePage.Diagnostics")} />
            <DiagnosticDrawerForm
              form={form}
              setForm={setForm}
              refresh={() => loadDiagnostics()}
            />
            <DiagnosticListLabel style={{ marginTop: "10px" }}>
              {translator("Diagnostic List")}
            </DiagnosticListLabel>
            {diagnosticList.map((item, index) => (
              <DiagnosticListRow key={index}>
                <label className="form_check_label">
                  <FormCheck
                    onChange={() => handleCheck(item)}
                    checked={selecteDiagnosticsIds.includes(item.id)}
                  />
                  {item.translation[i18n.language]}
                </label>
                <div className="flex">
                  <button
                    type="button"
                    onClick={() =>
                      setForm({
                        expanded: true,
                        diagnosticId: item.id,
                        nameInPT: item.nameInPT,
                        nameInEN: item.nameInEN,
                        nameInES: item.nameInES,
                      })
                    }
                  >
                    <MdEdit />
                  </button>
                  <button type="button" onClick={() => handleDelete(item)}>
                    <MdClose />
                  </button>
                </div>
              </DiagnosticListRow>
            ))}
          </AnalyticsComponent.Body>
          <AnalyticsComponent.Footer>
            <AnalyticsComponent.CancelButton handleCancel={() => onClose()} />
            <AnalyticsComponent.ConfirmButton
              type="button"
              handleConfirm={handleConfirm}
            />
          </AnalyticsComponent.Footer>
        </>
      </AnalyticsComponent>
    </DrawerComponent>
  );
}

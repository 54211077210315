import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Triangle = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  right: 14px;
  top: 41px;
  position: absolute;

  @media screen and (max-width: 767px) {
    margin-top: 4px;
  }
`;

export const Container = styled.div<{ selected?: boolean }>`
  position: relative;
  border: none;
`;

export const Button = styled.button<{
  selected?: boolean;
  navbarColor?: string;
  color?: string;
}>`
  border: none;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: ${(props) =>
    props.navbarColor ? props.color : props.theme.colors.iconsSecondary};
  background: ${(props) => {
    if (props.selected && !props.navbarColor) {
      return props.theme.colors.cardBackground;
    }
    if (props.selected && props.navbarColor) {
      return props.navbarColor;
    }
    return "transparent";
  }};

  ${(props) =>
    props.navbarColor &&
    css`
      filter: brightness(0.9);
    `}

  svg {
    border: none;
    stroke-width: ${({ selected }) => (selected ? 0 : 0.5)};
  }
`;

export const DropdownContainerNotifications = styled.div<{ visible?: boolean }>`
  position: absolute;
  width: 500px;
  padding: 15px;
  top: 47px;
  right: 0;
  border-radius: 4px;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.headerBackground};
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-align: left;
  color: ${(props) => props.theme.colors.iconsPrimary};
  max-height: calc(100vh - 300px);

  @media screen and (max-width: 767px) {
    ${(props) => ({ ...props.theme.fonts.primary.H2Bold })};
    color: ${(props) => props.theme.colors.iconsPrimary};
    z-index: 99;
    bottom: -8px;
    right: 0;
    width: 96vw !important;
    margin-top: 4px;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    text-align: left;
    height: fit-content;
    max-height: calc(100vh - 60px);
    margin-right: -50px;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  }
`;

export const HeaderNotifications = styled.div`
  label {
    ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
    color: ${(props) => props.theme.colors.iconsPrimary};
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px !important;
  padding-bottom: 8px;
`;

export const QuantitySpan = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  font-size: 10px;

  position: absolute;
  top: 6px;
  right: 6px;
  color: black;
  z-index: 12;
  background: ${(props) => props.theme.colors.ibbxOrange};
`;

export const ContentNotifications = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.headerBackground};
  min-height: 240px;
  gap: 0.25rem;

  & > a:last-child {
    margin-bottom: 3rem;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background};
    width: 20px;
    border-radius: 100vw;
    margin-block: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    height: 100%;
    max-height: 5rem;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${(props) => props.theme.colors.iconsSecondary};
  }

  @media screen and (max-width: 767px) {
    z-index: 99;
    height: auto;
    min-height: 160px;
    margin-bottom: 60px;
  }
`;

export const NotificationContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  height: 90px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.iconsSecondaryAlpha}!important;
  color: ${(props) => props.theme.colors.iconsPrimary};
  text-decoration: none;
  margin-right: 0.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;

    label {
      ${(props) => ({ ...props.theme.fonts.secondary.H3Regular })};
      font-size: 12px;
      :hover {
        cursor: pointer;
      }

      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
    span {
      font-size: 9px;
    }
  }

  div:first-child {
    width: 90%;
  }

  div:last-child {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }

  :hover {
    cursor: pointer !important;
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    color: ${(props) => props.theme.colors.iconsPrimary};
    border-radius: 6px;
    transition: all 0.3s ease;
  }
`;

export const UnreadSpan = styled.span<{ isRead?: boolean }>`
  margin-top: 8px;
  margin-right: 8px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: ${(props) =>
    props.isRead ? "transparent" : props.theme.colors.ibbxOrange};

  :hover {
    scale: 1.5;
  }
`;

export const EmptyNotification = styled.div`
  ${(props) => ({ ...props.theme.fonts.primary.H4Bold })};
  color: ${({ theme }) => theme.colors.iconsSecondary};
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 48px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 10px;

  :hover {
    cursor: default;
  }
`;

export const ReadAllButton = styled.button<{ disabled?: boolean }>`
  ${(props) => ({ ...props.theme.fonts.secondary.H3Regular })};
  font-size: 11px;
  background: transparent;
  border: none;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.iconsSecondary
      : props.theme.colors.iconsPrimary};
  border-bottom: 1px solid transparent !important;

  :hover {
    border-bottom: 1px solid
      ${(props) =>
        props.disabled
          ? "transparent"
          : props.theme.colors.iconsSecondary}!important;
  }
`;

export const TooltipContainer = styled.div<{ show?: boolean }>`
  padding: 6px;
  position: absolute !important;
  width: 100px;
  right: 25px;
  top: 5px;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background: black !important;
`;

export const ContractType = styled.span`
  ${(props) => props.theme.fonts.primary.H5Regular};
  background-color: ${(props) => props.theme.colors.p30};
  color: ${(props) => props.theme.colors.inputBlock};
  text-align: center;
  border-radius: 6px;
  padding: 2px 4px;
  margin-right: 3px;
`;

import { IoAlert } from "react-icons/io5";
import { Circle } from "./styles";
import { NamePlusTooltip } from "../NamePlusTooltip";
import { ReactNode } from "react";

interface IBadge {
  tooltip?: string;
  children?: ReactNode;
}

type BadgeType = IBadge & React.HTMLAttributes<HTMLDivElement>;

export const Badge = (props: BadgeType) => {
  const { tooltip, ...rest } = props;

  return (
    <NamePlusTooltip
      tooltip={tooltip || ""}
      position={"top"}
      disabled={!tooltip}
    >
      <Circle {...rest}>
        <IoAlert />
      </Circle>
    </NamePlusTooltip>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  & > button {
    padding: 0.5rem;
    border-radius: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    color: ${(props) => props.theme.colors.iconsSecondary};
    border: 1px solid transparent;
  }

  @media screen and (min-width: 767px) and (max-width: 1535px) {
    .EZDrawer__container {
      right: -92px !important;
    }
  }
`;

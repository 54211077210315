import { useTranslation } from "react-i18next";
import { PROFILES } from "../../../../store/slices/userSlice";
import useApi from "../../../../hooks/useApi";
import { success } from "../../../../components/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { isUserAnyClienteMaster } from "../../../../utils/userUtils";

interface IUseUserForm {
  formRef: any;
  setShowDecisionModal: (args: any) => void;
}

export default function useUserForm({
  formRef,
  setShowDecisionModal,
}: IUseUserForm) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const { companyId, userId } = params;

  const { request, processing: processingSubmit } = useApi({ path: "/users" });

  const goBack = () => {
    navigate(`/configuration/${companyId}/users`);
  };

  function validate(values: any) {
    var errors: any = {};

    if (
      !values?.companyId &&
      [PROFILES.CLIENTE_COMUM, PROFILES.CLIENTE_MASTER_VIEW].includes(
        values.profile,
      )
    ) {
      errors.companyId = t("userForm.selectCompany");
    }

    if (
      values?.profile === "CLIENTE_MASTER" &&
      !values?.userCompanies?.length
    ) {
      errors.companyId = t("userForm.selectCompany");
    }

    if (
      values?.profile === "CLIENTE_COMUM" &&
      !values?.userFacilities?.length
    ) {
      errors.facilityId = t("userForm.selectFacility");
    }

    values.name = values.name?.trim();
    if (!values.name) {
      errors.name = t("userForm.typeName");
    }

    if (!values?.email) {
      errors.email = t("userForm.typeEmail");
    }

    if (!values?.profile) {
      errors.profile = t("userForm.typeProfile");
    }

    if (!values?.language) {
      errors.language = t("userForm.typeLanguage");
    }

    if (
      !values.email?.includes(`@`) ||
      !values.email?.split("@")[1].includes(`.`) ||
      values.email.includes(",")
    ) {
      errors.email = t("userForm.invalidEmail");
    }

    return errors;
  }

  function handleSubmit(values: any) {
    values.email = values?.email.toLowerCase();

    values.name = (values.name || "").trim();
    values.email = (values.email || "").trim();

    if (!values.phone) {
      delete values.phone;
    }

    if (!processingSubmit) {
      formRef.current?.setSubmitting(true);

      request({
        method: userId ? "put" : "post",
        pathParameters: userId || "",
        body: values,
      })
        .then(() => {
          success(`${t("userForm.saved")}`);

          if (userId) {
            goBack();
          } else {
            setShowDecisionModal(true);
          }
        })
        .catch((err) => {
          const { response } = err || {};

          if (Array.isArray(response?.data)) {
            response.data.forEach(({ field, message }: any) => {
              formRef.current?.setFieldError(field, message);
            });
          }
        })
        .finally(() => {
          formRef.current?.setSubmitting(false);
        });
    }
  }

  return { validate, handleSubmit };
}

import styled from "styled-components";

export const AssetTypeRegisterContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: 16px;
  max-height: 95%;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    display: initial;
  }

  // Columns
  & > div {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.3rem;
    width: 100%;

    // Container na Imagem / Nomes em Informações Gerais / Diagnosticos
    & > div {
    }

    h3 {
      color: ${({ theme }) => theme.colors.textPrimary};
      font-family: Lexend;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    @media screen and (max-width: 767px) {
      height: auto;
      padding: 4px;
    }
  }

  // Left Column
  & > div:first-child {
    flex: 0 0 45%;
    border-right: 1px solid ${({ theme }) => theme.colors.colorTools};
    @media screen and (max-width: 767px) {
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorTools};
      padding-bottom: 16px;
    }
  }

  // Right Column
  & > div:last-child {
    flex: 1 1 50%;
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
`;

export const AssetTypeRegisterFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const AssetTypeRegisterFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  height: auto;
  gap: 18px;
  width: 100%;
  padding-right: 20px;

  @media screen and (max-width: 767px) {
    height: 64px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const AssetTypeRegisterGeneral = styled.div``;

import styled, { css } from "styled-components";

export const SimpleTableColumn = styled.div`
  margin: 4px 1rem 2px 0;
  
  color: ${(props) => props.theme.colors.textPrimary};
  ${({ theme }) => theme.fonts.secondary.H1BoldTable};

  & > span {
    display: flex;
    align-items: center;
  }
`;

interface ISimpleTableCell {
  disabled?: boolean;
  maxWidth?: number;
}

export const SimpleTableCell = styled.span<ISimpleTableCell>`
  color: ${(props) => props.theme.colors.textPrimary};
  ${({ theme }) => theme.fonts.secondary.H1RegularTable};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `}
`;

export const SimpleTableContainer = styled.div`
  background-color: transparent;
  width: 100%;

  th {
    background-color: transparent;
  }

  thead > tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorTools};
    width: 100%;
  }

  tbody > tr {
    background-color: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorTools};
  }

  td {
    background-color: transparent;
  }
`;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Amplify, API } from "aws-amplify";

import { RootState } from "../store";

import { getItem, setItem } from "../../utils/localStorage";
import { positionEmpty } from "./positionSlice";
import { awsConfig } from "../awsConfig";
import { error } from "../../components/Toast";
import { notifyDiscordError } from "../../utils/notifyDiscord";
import theme from "../../styles/themes/default";
import { EMPTY_COMPANY, ICompany } from "../../pages/Facilities/types";
import { EMPTY_FACILITY, IFacility } from "../../pages/Assets/types";
import { EMPTY_ASSET } from "../../pages/Asset/types";
import { IAsset, IPosition } from "../interfaces";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);
const apiRetinaCustomer = awsConfig?.API?.endpoints?.[0]?.name;

interface CustomItemsProps {
  icons: string[];
  frequencyUnit: string;
}

type registerModalType = {
  show: boolean;
  companyId?: number;
  facilityId?: number;
  assetId?: number;
  positionId?: number;
  sysSensorTypeId?: number;
  contract?: "PaaS" | "SOLaaS";
  id: number | null;
  occurrenceCustomFields?: any;
  disabledChangeStatus?: boolean;
  sysStatusId?: number;
  refresh?: () => void;
};

const registerModalEmpty: registerModalType = {
  show: false,
  id: null,
};

export type filterOptionDataType = {
  value: number | string;
  label: string;
  logoUrl?: string;
};

export type filterOptionType = {
  companyId?: number | string;
  facilityId?: number | string;
  sectorId?: number | string;
  assetId?: number | string;
  data: filterOptionDataType[];
};

const filterOptionEmpty = {
  data: [],
};

interface SliceState {
  [key: string]: any;
  darkMode: boolean; // alterna dark/light
  processing: boolean; // estado de processamento
  breadcrumb: { title: string; link: string }[]; // breadcrumb em telas grandes
  defaultLogo: string;
  currentBreadcrumb: string; // breadcrumb em telas pequenas
  currentCompany: ICompany; // empresa selecionada
  currentFacility: IFacility; // unidade selecionada
  currentAsset: IAsset; // equipamento selecionado
  currentPosition: IPosition;
  sidebarOpen: boolean; // sidebar aberto ou fechado em telas pequenas
  diagnostics: DiagnosticModel[];
  processingDiagnostics: boolean;
  newRelease: boolean;
  sidebarConfigurationOpen: boolean;
  occurrenceRegister: registerModalType;
  companyOptions: filterOptionType;
  facilityOptions: filterOptionType;
  sectorOptions: filterOptionType;
  assetOptions: filterOptionType;
  positionOptions: filterOptionType;
  monitoringOptions: filterOptionType;
  occurrenceRefresh: any;
  showRetinaChatButton: boolean;
  colorTheme: any;
  bearingCodeOptions: any;
  showAnnouncement: boolean;
  customItems?: CustomItemsProps;
  apps?: any[];
  contract: "BOTH" | "SOLaaS" | "PaaS";
  refreshDashboards: number;
  isReadOnly: boolean;
  deniedPermissions: any;
  homeUrl: string;
}

const initialState: SliceState = {
  darkMode: true,
  processing: true,
  breadcrumb: [],
  defaultLogo: "",
  currentBreadcrumb: "",
  currentCompany: EMPTY_COMPANY,
  currentFacility: EMPTY_FACILITY,
  currentAsset: EMPTY_ASSET,
  currentPosition: positionEmpty,
  sidebarOpen: false,
  diagnostics: [],
  bearingCodes: [],
  processingDiagnostics: false,
  newRelease: false,
  sidebarConfigurationOpen: true,
  occurrenceRegister: registerModalEmpty,
  companyOptions: filterOptionEmpty,
  facilityOptions: filterOptionEmpty,
  sectorOptions: filterOptionEmpty,
  assetOptions: filterOptionEmpty,
  positionOptions: filterOptionEmpty,
  monitoringOptions: filterOptionEmpty,
  occurrenceRefresh: 0,
  showRetinaChatButton: true,
  colorTheme: {},
  bearingCodeOptions: [],
  showAnnouncement: false,
  contract: "BOTH",
  apps: [],
  refreshDashboards: 0,
  isReadOnly: false,
  deniedPermissions: {},
  homeUrl: "/"
};

export const iconColors: any = {
  "#FB2576": theme.colors.white,
  "#F55050": theme.colors.white,
  "#F48484": theme.colors.iconsSecondaryAlpha,
  "#810CA8": theme.colors.iconsPrimary,
  "#4E31AA": theme.colors.iconsPrimary,
  "#0099FF": theme.colors.white,
  "#6C5B7B": theme.colors.iconsPrimary,
  "#E5B8F4": theme.colors.iconsSecondaryAlpha,
  "#005555": theme.colors.iconsPrimary,
  "#74AB90": theme.colors.iconsSecondaryAlpha,
  "#A3D95B": theme.colors.iconsSecondaryAlpha,
  "#F2F7A1": theme.colors.iconsSecondaryAlpha,
};

export interface DiagnosticModel {
  name: string;
  sugestion: string;
  caption: string;
  sysSensorTypeId: number;
  translation: any;
  translationSugestion: any;
}

export const getBearingCodes = createAsyncThunk(
  "app/bearingCodesGET",
  async () => {
    const path = `/bearing-codes`;
    const data: DiagnosticModel[] = await new Promise((resolve, reject) => {
      API.get(apiRetinaCustomer, path, {})
        .then((response) => {
          resolve(response);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppToggleDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = !action.payload;
      setItem("darkMode", JSON.stringify(state.darkMode));
      //[A FAZER] Persistir a preferência do usuário para o darkMode
    },
    appReset: (state) => {
      state.darkMode = initialState.darkMode;
      state.processing = initialState.processing;
      state.breadcrumb = initialState.breadcrumb;
      state.currentBreadcrumb = initialState.currentBreadcrumb;
      state.currentCompany = initialState.currentCompany;
      state.currentFacility = initialState.currentFacility;
      state.currentAsset = initialState.currentAsset;
      state.sidebarOpen = initialState.sidebarOpen;
    },
    appSetProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    appSetBreadcrumb: (state, action: PayloadAction<any[]>) => {
      state.breadcrumb = action.payload;
    },
    appSetCurrentBreadcrumb: (state, action: PayloadAction<string>) => {
      state.currentBreadcrumb = action.payload;
    },
    appSetCurrentCompany: (state, action: PayloadAction<ICompany | null>) => {
      state.currentCompany = { ...EMPTY_COMPANY, ...(action.payload ?? {}) };
    },
    setPositionOptions: (state, action: PayloadAction<filterOptionType>) => {
      state.positionOptions = action.payload;
    },
    setMonitoringOptions: (state, action: PayloadAction<filterOptionType>) => {
      state.monitoringOptions = action.payload;
    },
    appSetCurrentFacility: (state, action: PayloadAction<IFacility | null>) => {
      state.currentFacility = { ...EMPTY_FACILITY, ...(action.payload ?? {}) };
    },
    setAppCompanyLogo: (state, action: PayloadAction<any>) => {
      state.currentCompany.logoUrl = action.payload;
    },
    appSetCurrentAsset: (state, action: PayloadAction<IAsset | null>) => {
      state.currentAsset = { ...EMPTY_ASSET, ...(action.payload ?? {}) };
    },
    appSetCurrentPosition: (state, action: PayloadAction<IPosition | null>) => {
      state.currentPosition = { ...positionEmpty, ...(action.payload ?? {}) };
    },
    appSetSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    setOccurrenceRegister: (
      state,
      action: PayloadAction<registerModalType>,
    ) => {
      state.occurrenceRegister = action.payload;
    },
    setDefaultLogo: (state, action: PayloadAction<string>) => {
      state.defaultLogo = action.payload;
    },
    appSetCurrentDarkMode: (state) => {
      const storageDarkMode = getItem("darkMode");
      state.darkMode =
        storageDarkMode !== null ? Boolean(storageDarkMode) : true;
    },
    appSetNewRelease: (state, action: PayloadAction<boolean>) => {
      state.newRelease = action.payload;
    },
    appToggleSidebarConfiguration: (state, action: PayloadAction<boolean>) => {
      state.sidebarConfigurationOpen = action.payload;
    },
    setContract: (state, action: PayloadAction<"BOTH" | "PaaS" | "SOLaaS">) => {
      state.contract = action.payload;
    },
    setOccurrenceRefresh: (state, action: PayloadAction<any>) => {
      state.occurrenceRefresh = action.payload;
    },
    setShowRetinaChatButton: (state, action: PayloadAction<any>) => {
      state.showRetinaChatButton = action.payload;
    },
    setColorTheme: (state, action: PayloadAction<any>) => {
      state.colorTheme = action.payload;
    },
    setShowAnnouncement: (state, action: PayloadAction<any>) => {
      state.showAnnouncement = action.payload;
    },
    setCustomItems: (state, action: PayloadAction<any>) => {
      state.customItems = action.payload;
    },
    setApps: (state, action: PayloadAction<any>) => {
      state.apps = action.payload;
    },
    setLanguage: (state, action: PayloadAction<any>) => {
      state.language = action.payload;
    },
    setAppDiagnostics: (state, action: PayloadAction<any>) => {
      state.diagnostics = action.payload;
    },
    setAppRefreshDashboard: (state, action: PayloadAction<any>) => {
      state.refreshDashboards = action.payload;
    },
    setAppIsReadOnly: (state, action: PayloadAction<any>) => {
      state.isReadOnly = action.payload;
    },
    setAppDeniedPermissions: (state, action: PayloadAction<any>) => {
      state.deniedPermissions = action.payload;
    },
    setAppLoaded: (state, action: PayloadAction<any>) => {
      state.homeUrl = action.payload.homeUrl;
      state.processing = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBearingCodes.rejected, (state, action) => {
        error(action.error?.message);
      })
      .addCase(getBearingCodes.pending, (state) => {})
      .addCase(getBearingCodes.fulfilled, (state, action) => {
        state.bearingCodeOptions = action.payload;
      });
  },
});

export const appSelect = (state: RootState) => state.app;

export const {
  appReset,
  setAppToggleDarkMode,
  appSetProcessing,
  appSetBreadcrumb,
  appSetCurrentBreadcrumb,
  appSetCurrentCompany,
  appSetCurrentFacility,
  appSetCurrentAsset,
  appSetCurrentPosition,
  setAppCompanyLogo,
  appSetSidebarOpen,
  appSetCurrentDarkMode,
  appSetNewRelease,
  appToggleSidebarConfiguration,
  setOccurrenceRegister,
  setMonitoringOptions,
  setDefaultLogo,
  setOccurrenceRefresh,
  setShowRetinaChatButton,
  setColorTheme,
  setShowAnnouncement,
  setCustomItems,
  setContract,
  setLanguage,
  setApps,
  setAppDiagnostics,
  setAppRefreshDashboard,
  setAppIsReadOnly,
  setAppDeniedPermissions,
  setAppLoaded,
} = appSlice.actions;

export default appSlice.reducer;

import { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { HiQrcode } from "react-icons/hi";

import { Modal } from "../../components/Modal";
import { FormikTextInput } from "../../components/textInput";
import useApi from "../../hooks/useApi";
import { ConfirmModal } from "../ConfirmModal";
import { BoltFormTypes } from "./types";
import {
  BoltFieldsContainer,
  BoltFormBody,
  BoltFormContainer,
  BoltImageContainer,
  InputBoltID,
  QrCodeButton,
} from "../BoltForm/styles";
import { error, success } from "../../components/Toast";
import { BoltActivator } from "../../components/BoltActivator";
import { CloudConnectionForm } from "./CloudConnectionForm";
import { FormAssetFooter } from "../AssetForm/styles";
import { ButtonSave } from "../../components/Modal/styles";
import { useTranslation } from "react-i18next";
import { ImageUploadCard } from "../../components/ImageUploadCard";
import { useParams } from "react-router-dom";
import { Select } from "../../components/Select";
export const BoltForm = ({
  show,
  handleClose,
  selectedBolt,
  handleSuccess,
}: BoltFormTypes) => {
  const formRef = useRef<FormikProps<any>>(null);

  const params = useParams();

  const { t } = useTranslation();

  const initialValues = {
    implantation: {
      cloudConnection: {
        type: "",
      },
    },
    ...selectedBolt,
    local: selectedBolt?.localReference,
    boltCode: selectedBolt?.gatewayId,
  };

  const [facilitiesOptions, setFacilitiesOptions] = useState<any[]>([]);
  const [facility, setFacility] = useState<any>();
  const [sector, setSector] = useState<any>();

  const [showQrCode, setShowQrCode] = useState<boolean>(false);
  const [boltSectors, setBoltSectors] = useState<any[]>([]);
  const [image, setImage] = useState({
    src: selectedBolt?.imageUrl || "",
    changed: false,
  });

  const { request } = useApi({
    path: `/companies/${params.companyId}`,
  });

  const {
    request: requestFacilitySectors,
    processing: processingFacilitySectors,
  } = useApi({
    path: `/companies/${params.companyId}/facilities`,
  });

  const { request: requestImage } = useApi({
    path: `/gateways`,
  });

  const {
    request: requestFacilitiesOptions,
    processing: processingFacilitiesOptions,
  } = useApi({
    path: "/facilities-options",
  });

  const loadFacilitySectorOptions = ({
    facilityId,
  }: {
    facilityId: string | number;
  }) => {
    requestFacilitySectors({
      method: "get",
      pathParameters: `${facilityId}/sectors`,
    }).then((response: any) => {
      const formatToReactSelect = response?.map(
        (item: { id: number; name: string }) => {
          return { value: item.id, label: item.name };
        },
      );

      setBoltSectors(formatToReactSelect);
    });
  };

  const loadFacilitiesOptions = () => {
    requestFacilitiesOptions({
      method: "get",
      queryStringParameters: { companyId: params.companyId },
    }).then((response) => {
      setFacilitiesOptions(response || []);
    });
  };

  useEffect(() => {
    loadFacilitiesOptions();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (boltSectors?.length && selectedBolt?.boltSectorId) {
      const currentSector = boltSectors?.find(
        (item: { value: string | number; name: string }) =>
          item.value === selectedBolt?.boltSectorId,
      );

      setSector(currentSector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boltSectors]);

  function findFacilityOptionById(facilityId: any) {
    return (facilitiesOptions || []).find(
      (item: any) => String(item.value) === String(facilityId),
    );
  }

  useEffect(() => {
    if (params.facilityId && facilitiesOptions.length) {
      formRef.current?.setFieldValue("facilityId", +params.facilityId);
      setFacility(findFacilityOptionById(params.facilityId));
    }

    if (selectedBolt?.facilityId) {
      formRef.current?.setFieldValue("facilityId", +selectedBolt.facilityId);
      setFacility(findFacilityOptionById(selectedBolt?.facilityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitiesOptions]);

  useEffect(() => {
    if (facility) {
      loadFacilitySectorOptions({ facilityId: facility?.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility]);

  const onValidate = (values: any) => {
    const errors: any = {};
    if (!(values.boltCode || "").trim() && selectedBolt !== true) {
      errors.boltCode = `${t("BoltForm.idBoltValidate")}`;
    }
    if (!(values.name || "").trim() && selectedBolt !== true) {
      errors.name = `${t("BoltForm.nameBoltValidate")}`;
    }

    if (!values.facilityId && selectedBolt !== true) {
      errors.facilityId = `${t("BoltForm.nameBoltValidate")}`;
    }

    return errors;
  };

  const onHandleCloseQRCodeModal = () => {
    setShowQrCode(false);
  };

  const onHandleClose = () => {
    handleClose();
  };

  const handleImageChange = function (src: string) {
    setImage({ src: src, changed: true });
  };

  const successMessage = selectedBolt
    ? `${t("BoltForm.successEditMessage")}`
    : `${t("BoltForm.successRegisteredMessage")}`;

  const handleConfirmModalImage = (gatewayId: any) => {
    if (!image) {
      error(`${t("ModalAssetForm.noLogoSelected")}`);
      return;
    }
    const body = { data: image.src, favorite: true };
    requestImage({
      method: "put",
      pathParameters: `${gatewayId}/resources`,
      body,
    }).then(() => {
      setImage({ src: "", changed: false });
    });
  };

  const handleSubmit = (values: any, actions: any) => {
    values.boltCode = (values.boltCode || "").trim();
    values.name = (values.name || "").trim();
    const gatewayId = selectedBolt ? `/${selectedBolt.gatewayId}` : "";
    request({
      body: values,
      method: selectedBolt ? "put" : "post",
      pathParameters: `bolts${gatewayId}`,
    })
      .then((response) => {
        if (image.changed) {
          handleConfirmModalImage(values.boltCode);
        }
        success(successMessage);
        handleSuccess();
        onHandleClose();
      })
      .catch((err) => {
        const { response } = err || {};

        if (response.data) {
          response.data.forEach(({ field, message }: any) => {
            actions.setFieldError(field, message);
          });
        }

        actions.setSubmitting(false);
      });
  };

  const handleChangeFacilityOptions = (e: any) => {
    setFacility(e);

    formRef.current?.setFieldValue("facilityId", e.value);

    if (sector) {
      setSector(null);
      formRef.current?.setFieldValue("boltSectorId", null);
    }
  };

  const isDevelopment = process.env.REACT_APP_ENV === "development";
  const isQAOrProd = ["qa", "production"].includes(process.env.REACT_APP_ENV);

  useEffect(() => {
    if (!selectedBolt?.coverage) {
      formRef.current?.setFieldValue("coverage", "FACILITY");
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeSector = (e: any) => {
    setSector(e);
    formRef.current?.setFieldValue("boltSectorId", e.value);
  };

  const coverageOptions: any[] = [
    {
      value: "FACILITY",
      label: t("dictionary.labels.Facility"),
    },
    {
      value: "COMAPNY",
      label: t("dictionary.labels.Company"),
    },
  ];

  return (
    <Modal show={show} handleClose={onHandleClose} size="lg" changeFotter>
      <>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validate={onValidate}
          innerRef={formRef}
          enableReinitialize
        >
          {({ values, errors }: any) => (
            <Form>
              <BoltFormContainer>
                <h1 className="form_title">
                  {`${t(
                    `BoltForm.${selectedBolt ? "formEditBolt" : "formAddBolt"}`,
                  )}`}{" "}
                  Bolt
                </h1>
                <BoltFormBody>
                  <div
                    className="form_row gap_1"
                    style={{ marginBottom: "1rem" }}
                  >
                    <BoltImageContainer>
                      <ImageUploadCard
                        image={image.src}
                        changeImage={handleImageChange}
                        hideRemove
                      />
                    </BoltImageContainer>
                    <BoltFieldsContainer>
                      <InputBoltID qrCode={selectedBolt?.gatewayId}>
                        <FormikTextInput
                          name="boltCode"
                          label="Bolt ID*"
                          error={errors.boltCode}
                          isActivator
                        />
                        {!selectedBolt?.gatewayId && (
                          <QrCodeButton
                            type="button"
                            onClick={() => setShowQrCode(true)}
                          >
                            <HiQrcode size={30} />
                            <span>QR code</span>
                          </QrCodeButton>
                        )}
                      </InputBoltID>
                      {isDevelopment && (
                        <Select
                          label={`${t("BoltForm.connectivityCoverage")}`}
                          handleChange={(e) => {
                            formRef.current?.setFieldValue("coverage", e.value);
                          }}
                          options={coverageOptions}
                          placeholder={`${t("BoltForm.connectivityCoverage")}`}
                          value={coverageOptions.find(
                            ({ value }) => value === values.coverage,
                          )}
                          processing={processingFacilitiesOptions}
                          informativeButton={
                            <>
                              <p>
                                <strong>
                                  {`${t("BoltForm.connectivityCoverage")}: `}
                                </strong>
                                {t("BoltForm.connectivityCoverageInformation")}
                              </p>
                              <p>
                                <strong>{`${t(
                                  "dictionary.labels.Facility",
                                )}: `}</strong>
                                {t("BoltForm.facilityCoverageInformation")}
                              </p>
                              <p>
                                <strong>{`${t(
                                  "dictionary.labels.Company",
                                )}: `}</strong>
                                {t("BoltForm.companyCoverageInformation")}
                              </p>
                            </>
                          }
                        />
                      )}
                      <Select
                        label={`${t("dictionary.labels.Facility")}*`}
                        handleChange={(e) => {
                          handleChangeFacilityOptions(e);
                        }}
                        options={facilitiesOptions}
                        placeholder={`${t("dictionary.labels.Facility")}`}
                        value={facility}
                        error={!!errors.facilityId}
                        processing={processingFacilitiesOptions}
                        disabled={!!params.facilityId}
                      />
                      <Select
                        label={`${t("dictionary.labels.Sector")}`}
                        handleChange={(e) => {
                          handleChangeSector(e);
                        }}
                        options={boltSectors}
                        placeholder={`${t("BoltForm.sectorPlaceholder")}`}
                        value={sector}
                        error={!!errors.sectorId}
                        processing={
                          processingFacilitySectors ||
                          processingFacilitiesOptions
                        }
                      />
                      {isQAOrProd && (
                        <FormikTextInput
                          name="name"
                          label={`${t("BoltForm.identificationName")}`}
                          placeholder={`${t(
                            "BoltForm.identificationPlaceholder",
                          )}`}
                          error={errors.name}
                        />
                      )}
                    </BoltFieldsContainer>
                  </div>
                  {isDevelopment && (
                    <FormikTextInput
                      name="name"
                      label={`${t("BoltForm.identificationName")}`}
                      placeholder={`${t("BoltForm.identificationPlaceholder")}`}
                      error={errors.name}
                    />
                  )}
                  <CloudConnectionForm name="implantation[cloudConnection]" />
                  <FormikTextInput
                    name="local"
                    label={`${t("BoltForm.localDescriptionLabel")}`}
                    placeholder={`${t("BoltForm.localDescriptionPlaceholder")}`}
                  />
                </BoltFormBody>
              </BoltFormContainer>
              <FormAssetFooter>
                <ConfirmModal
                  onConfirm={onHandleClose}
                  text={`${t("BoltForm.confirmCancelModal")}`}
                />
                <ButtonSave type="submit">
                  {`${t("BoltForm.saveButton")}`}
                </ButtonSave>
              </FormAssetFooter>
              <BoltActivator
                show={showQrCode}
                handleClose={onHandleCloseQRCodeModal}
              />
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

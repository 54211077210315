// Libraries
import { useEffect, memo } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { BrowserView, MobileOnlyView, TabletView } from "react-device-detect";

// Styles
import {
  SidebarContainer,
  SidebarDesktopToggle,
  SidebarMobile,
  SidebarMobileToggle,
} from "./styles";

import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useDispatch } from "react-redux";
import { setShowSidebar } from "../../store/slices/sidebarSlice";

import {
  BsLayoutSidebarInset,
  BsLayoutSidebarInsetReverse,
} from "react-icons/bs";

type Props = {
  children: any;
  onShow?: Function;
  assetsLevel?: boolean;
};

const Sidebar = ({ children, onShow = () => null, assetsLevel }: Props) => {
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();

  const showSidebar = useSelector(
    (state: RootState) => state.sidebar.showSidebar,
  );

  useEffect(() => {
    const { pathname, search } = location;

    if (pathname === "/assets" && search.includes("boltId")) {
      setShowSidebar(false);
    }
  }, [location]);

  function hideSidebar() {
    dispatch(setShowSidebar(!showSidebar));
  }

  function handleShowSidebar() {
    onShow();
    dispatch(setShowSidebar(!showSidebar));
  }

  return (
    <>
      <BrowserView>
        {!showSidebar && (
          <SidebarDesktopToggle show onClick={handleShowSidebar}>
            <FaChevronRight size={16} />
          </SidebarDesktopToggle>
        )}

        <SidebarContainer show={showSidebar} id="sidebar">
          {children(hideSidebar)}
        </SidebarContainer>
      </BrowserView>
      <MobileOnlyView>
        <SidebarMobileToggle show onClick={() => hideSidebar()}>
          {showSidebar ? (
            <BsLayoutSidebarInsetReverse />
          ) : (
            <BsLayoutSidebarInset />
          )}
        </SidebarMobileToggle>
        <SidebarMobile visible={showSidebar} id="sidebar">
          <SidebarMobileToggle
            show
            onClick={() => hideSidebar()}
          ></SidebarMobileToggle>
          {children(hideSidebar)}
        </SidebarMobile>
      </MobileOnlyView>
      <TabletView>
        {!showSidebar && (
          <SidebarDesktopToggle show onClick={handleShowSidebar}>
            <FaChevronRight size={16} />
          </SidebarDesktopToggle>
        )}

        <SidebarContainer show={showSidebar} assetsLevel={assetsLevel}>
          {children(hideSidebar)}
        </SidebarContainer>
      </TabletView>
    </>
  );
};

export default memo(Sidebar);

import { Form } from "formik";
import styled from "styled-components";

export const DiagnosticFormBody = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    width: 100%;
  }
`;

export const DiagnosticForm = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    ${({ theme }) => theme.fonts.primary.H4Bold};
  }
`;

import styled from "styled-components";

export const CollapseComponentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    ${({ theme }) => theme.fonts.primary.H3SemiBold};
    color: ${({ theme }) => theme.colors.iconsPrimary};
    margin: 0;
    line-height: 16px;
  }
`;

export const CollapseLine = styled.div`
  height: 2rem;
  display: flex;
  justify-content: flex-end !important;
  right: 1rem;
  top: 0.25rem;
  transition: all 0.3s ease;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconsSecondary};
  z-index: 2;
  opacity: 0.25;

  svg {
    width: 1.8rem;
    height: auto;
  }
  :hover {
    border-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 30px;
    opacity: 0.75;
  }
  @media screen and (max-width: 767px) {
    right: 0.5rem;
  }

  @media screen and (min-width: 767px) and (max-width: 1536px) {
    height: auto;
    top: 0.15rem;
    svg {
      width: 1.4rem;
      height: auto;
    }
  }
`;
import { BiPlus } from "react-icons/bi";
import { ButtonAddV2Button } from "./styles";

interface IButtonAddV2 {
  onClick: () => void;
  size?: number;
  right?: number;
  top?: number;
}

const ButtonAddV2 = ({ onClick, size = 32 }: IButtonAddV2) => {
  return (
    <ButtonAddV2Button type="button" size={size} onClick={onClick}>
      <BiPlus />
    </ButtonAddV2Button>
  );
};

export default ButtonAddV2;

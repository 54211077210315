import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import CountUp from "react-countup";
import { MdAdd, MdOutlineWebAsset } from "react-icons/md";

// Store
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  appSelect,
  appSetCurrentAsset,
  appSetCurrentPosition,
} from "../../store/slices/appSlice";
import { PROFILES, userSelect } from "../../store/slices/userSlice";

import useApi from "../../hooks/useApi";
import { isMobile } from "../../utils/mediaQuery";

// Components
import Frame from "../../components/frame";
import { FrameBodyMain } from "../../components/frame/styles";
import Sidebar from "../../components/Sidebar";
import { Dashboard } from "../../components/Dashboard";
import { Tab, Tabs } from "../../components/Tabs";

import {
  SidebarInsideTab,
  AddNewContainer,
  TitleMonitoring,
} from "../../components/Sidebar/styles";
import {
  AddNewContainerAssets,
  FrameBodyRightAssets,
  FrameSidebarFiltersEquipment,
  Header,
  LearnMoreButton,
  Separator,
  SidebarTitleEquipment,
} from "./styles";

import { routePathParams } from "./types";

import SidebarSearchInput from "../../components/SidebarSearchInput";
import { SidebarSelect } from "../../components/SidebarSelect";
import RoundIconButton from "../../components/RoundIconButton";
import SensorList from "../../components/SensorList";
import SidebarBolt from "../Assets/SidebarBolts";
import { CardBoltOperationMode } from "../Assets/CardBoltOperationMode";
import { CardSynchronizedSensors } from "../Assets/CardSynchronizedSensors";

import { FaChevronLeft } from "react-icons/fa";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import { TabsNavigation } from "../../components/TabsNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import ReturnButton from "../../components/ReturnButton";
import { isMobileOnly } from "react-device-detect";
import usePageLevel from "../../hooks/usePageLevel";
import { IoIosCube } from "react-icons/io";
import { LoadingTabContainer } from "../Facilities/styles";
import { Spinner } from "react-bootstrap";
import { AppBoltBt } from "../../components/AppBoltBt";
import { TiDevicePhone } from "react-icons/ti";
import useQueryParams from "../../hooks/useQueryParams";
import { CardGatewayConnectivity } from "./CardGatewayConnectivity";
import { MS_IN_SECOND } from "../../constants/dates";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";

const Connectivity = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const params: any = useParams<routePathParams>();

  const app = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);

  const { t } = useTranslation();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();

  const isAtCompanyLevel = useMemo(() => {
    return getUrlPattern(location, params) === ROUTES_PATH.COMPANY_CONNECTIVY;
  }, [location.pathname]);

  // APIs
  const path = location.pathname.replace("/connectivity", "");
  const { loadFacility, loadCompany, levelProcessing, cancelRequest } =
    usePageLevel(path);

  const { request: requestGatewaysList, processing: processingList } = useApi({
    path: `/gateways-list`,
  });

  const { request: requestFacilities, processing: processingFacilities } =
    useApi({
      path: "/facilities-options",
    });

  const facilityEmpty = { id: null, name: "All Facilities" };
  const [facilityOptions, setFacilityOptions] = useState<any[]>([
    facilityEmpty,
  ]);

  const [runJoyride, setRunJoyride] = useState(false);

  const [showModalFormBolt, setShowModalFormBolt] = useState(false);
  const [bolts, setBolts] = useState<any>([]);
  const [filteredBolt, setFilteredBolt] = useState<any>(null);
  const [totalBolts, setTotalBolts] = useState(0);
  const [rendering, setRendering] = useState(false);
  const [tab, setTab] = useState<string>("");

  const hideDashboardCharts: boolean = useSelector(
    (state: RootState) => state.dashboardToggle.hideDashboardCharts,
  );

  // eslint-disable-next-line
  const { handleTrackPageView } = useSegment();
  const { queryParams, setQueryParams } = useQueryParams();

  const facilityId = useMemo(() => {
    if (params.facilityId) {
      return params.facilityId;
    }

    return queryParams.facilityId;
    // eslint-disable-next-line
  }, [queryParams.facilityId]);

  function loadFacilities() {
    requestFacilities({
      method: "get",
      queryStringParameters: { companyId: params.companyId },
    }).then((response: any[]) => {
      response = response.map(({ value, label }) => ({
        id: value,
        name: label,
      }));
      setFacilityOptions([facilityEmpty, ...response]);
    });
  }

  const loadBolts = useCallback(
    ({ noProcessing }: { noProcessing: boolean }) => {
      const queryStringParameters = params;
      if (queryParams.facilityId) {
        queryStringParameters.facilityId = facilityId;
      }

      requestGatewaysList({
        method: "get",
        queryStringParameters,
        noProcessing,
        skipToast: true,
      }).then((response) => {
        setBolts(response || []);
      });
    },
    // eslint-disable-next-line
    [queryParams.facilityId],
  );

  useEffect(() => {
    loadFacilities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadBolts({ noProcessing: false });

    const interval = setInterval(
      () => loadBolts({ noProcessing: true }),
      15 * MS_IN_SECOND,
    );

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  function loadCurrentLevel() {
    if (params.facilityId) {
      return loadFacility();
    }
    return loadCompany();
  }

  // Carrega Assets
  useEffect(() => {
    Promise.all([
      loadCurrentLevel(),
      handleTrackPageView(),
      dispatch(appSetCurrentAsset(null)),
      dispatch(appSetCurrentPosition(null)),
    ]);

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFacilityChange = (facility: any) => {
    if (!facility?.id) {
      setQueryParams({});
      return;
    }
    setQueryParams({ facilityId: facility.id });
  };

  const handleInsertBolt = () => {
    setShowModalFormBolt(true);
  };

  function handleSidebar(hideSidebar: Function) {
    setRendering(true);
    hideSidebar();
  }

  useEffect(() => {
    if (rendering) {
      setTimeout(() => {
        setRendering(false);
      }, 500);
    }
  }, [rendering]);

  const [connectivityTab, setConnectivityTab] = useState<string>("POSITIONS");

  const renderTabsContent = () => {
    if (levelProcessing) {
      return (
        <Tabs>
          <Tab key={""} label={""}>
            <LoadingTabContainer>
              <Spinner />
            </LoadingTabContainer>
          </Tab>
        </Tabs>
      );
    }

    if (isMobile()) {
      return (
        <TabsNavigation
          currentTab={tab}
          handleChangeTab={(tab: string) => setTab(tab)}
        >
          <Tab
            key="POSITIONS"
            label={`${t("tabs.positions")}`}
            icon={<MdOutlineWebAsset />}
          >
            <SensorList
              facilityId={facilityId}
              filteredBolt={filteredBolt}
              handleClearFilteredBolt={() => setFilteredBolt(null)}
            />
          </Tab>

          <Tab
            key="MAPS"
            label={
              process.env.REACT_APP_ENV === "production"
                ? "Maps"
                : t("Gêmeo Digital")
            }
            icon={<IoIosCube />}
          >
            <></>
          </Tab>
          {user.admin ? (
            <Tab key="APPBOLT" label="App Bolt" icon={<TiDevicePhone />}>
              <AppBoltBt />
            </Tab>
          ) : (
            <></>
          )}
        </TabsNavigation>
      );
    }

    return (
      <Tabs
        currentTab={connectivityTab}
        handleChangeTab={(tab: string) => setConnectivityTab(tab)}
        adjustHeight={hideDashboardCharts ? "70%" : "100%"}
      >
        <Tab
          key="POSITIONS"
          label={`${t("tabs.positions")}`}
          icon={<MdOutlineWebAsset />}
        >
          <SensorList
            facilityId={facilityId}
            filteredBolt={filteredBolt}
            handleClearFilteredBolt={() => setFilteredBolt(null)}
          />
        </Tab>

        <Tab
          key="MAPS"
          label={
            process.env.REACT_APP_ENV === "production"
              ? "Maps"
              : t("Gêmeo Digital")
          }
          icon={<IoIosCube />}
        >
          <></>
        </Tab>

        <Tab key="APPBOLT" label="App Bolt Bluetooth" icon={<TiDevicePhone />}>
          <AppBoltBt />
        </Tab>
      </Tabs>
    );
  };

  useEffect(() => {
    const joyrideConnectivityLocalStorage = localStorage.getItem(
      "retina:JoyrideConnectivity",
    );

    setRunJoyride(
      location.pathname.includes("connectivity") &&
        joyrideConnectivityLocalStorage !== "false",
    );
  }, [location.pathname]);

  const isMobileAndMapTab = isMobile() && tab === "MAPS";

  return (
    <Frame runJoyride={runJoyride} setRunJoyride={setRunJoyride}>
      <FrameBodyMain>
        <Sidebar onShow={() => setRendering(true)} assetsLevel>
          {(hideSidebar: any) => (
            <>
              <Header>
                <TitleMonitoring>
                  <span onClick={() => hideSidebar()}>
                    {`${t("assets.gatewaysMonitored")}`} (
                    <CountUp
                      start={bolts.length || 0}
                      end={bolts.length || 0}
                      duration={0.8}
                      separator={"."}
                    />
                    )
                  </span>
                  <div>
                    {!app.isReadOnly && (
                      <AddNewContainerAssets>
                        <RoundIconButton
                          buttonRef={buttonRef}
                          handleClick={handleInsertBolt}
                        >
                          <MdAdd size={28} />
                        </RoundIconButton>
                      </AddNewContainerAssets>
                    )}
                    {!isMobile() && (
                      <AddNewContainer
                        onClick={() => handleSidebar(hideSidebar)}
                      >
                        <FaChevronLeft size={16} />
                      </AddNewContainer>
                    )}
                  </div>
                </TitleMonitoring>
              </Header>
              <Separator />
              <LearnMoreButton
                onClick={() => {
                  setRunJoyride(true);
                }}
              >
                {t("ConnectivityJoyirde.Learn more")}
              </LearnMoreButton>
              <Separator />
              <SidebarInsideTab>
                <SidebarTitleEquipment>
                  <FrameSidebarFiltersEquipment>
                    {isAtCompanyLevel && (
                      <SidebarSelect
                        callback={handleFacilityChange}
                        options={facilityOptions}
                        defaultLabel={
                          t("dictionary.labels.All Facilities") as string
                        }
                        disableColor
                        selected={
                          queryParams?.facilityId
                            ? {
                                id: queryParams.facilityId,
                                name: (facilityOptions || []).find(
                                  ({ id }) => id === +queryParams.facilityId,
                                )?.name,
                              }
                            : null
                        }
                        processing={processingFacilities}
                      />
                    )}
                  </FrameSidebarFiltersEquipment>
                </SidebarTitleEquipment>
                <SidebarBolt
                  processing={processingList}
                  bolts={bolts}
                  showModalFormBolt={showModalFormBolt}
                  setShowModalFormBolt={setShowModalFormBolt}
                  setTotalBolts={setTotalBolts}
                  refresh={() => loadBolts({ noProcessing: false })}
                  handleClickBolt={setFilteredBolt}
                />
              </SidebarInsideTab>
            </>
          )}
        </Sidebar>
        <FrameBodyRightAssets
          visible={app.sidebarOpen}
          isMobile={isMobile()}
          isBoltTab
        >
          {isMobileOnly && <ReturnButton />}
          {!isMobileAndMapTab && (
            <Dashboard isBoltNewArchiteture isBoltTab>
              {!!facilityId ? (
                <CardBoltOperationMode
                  boltAdded={bolts}
                  facilityId={facilityId}
                  refresh={() => loadBolts({ noProcessing: false })}
                />
              ) : (
                <CardGatewayConnectivity />
              )}
              <CardSynchronizedSensors facilityId={facilityId} />
            </Dashboard>
          )}

          {renderTabsContent()}
        </FrameBodyRightAssets>
      </FrameBodyMain>
    </Frame>
  );
};

export default Connectivity;

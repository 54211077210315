import styled from "styled-components";

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.ibbxOrange};
  width: 16px !important;
  height: 16px;
  position: absolute;
  border-radius: 100%;
  z-index: 1;

  svg {
    color: white;
    font-size: 0.75rem;
  }
`;

import { useMemo, useState } from "react";
import { Modal } from "../Modal";
import { ButtonCancel, ButtonSave } from "../Modal/styles";

import { ActivatorFooter, ContainerActivator } from "./styles";
import { ActivatorProps } from "./types";
import QrReader from "react-web-qr-reader";
import QRCode from "react-qr-code";
import { isMobile } from "react-device-detect";
import { useField, useFormikContext } from "formik";
import { blockInvalidCharacter } from "../../utils/blockInvalidCharacter";
import { useTranslation } from "react-i18next";
import { QRCodeReader } from "../QRCodeReader";

export const BoltActivator = ({
  show,
  handleClose,
}: ActivatorProps) => {
  const name = "boltCode";
  const [{ value: boltCode }] = useField(name);
  const { setFieldValue } = useFormikContext();

  const camera = useMemo(() => {
    if (show) {
      return true;
    } else {
      return false;
    }
  }, [show]);

  const handleCloseActivator = () => {
    handleClose();
    setFieldValue(name, "");
    setQrCode("");
  };

  const handleActivatorIdChange = (event: any) => {
    setFieldValue(name, event.target.value.replaceAll("-", ""));
  };

  const [qrCode, setQrCode] = useState("");
  const [cameraDenied, setCameraDenied] = useState(false);

  const handleConfirm = () => {
    handleClose();
  };

  const delay = 5;

  const handleScan = (result: any) => {
    if (result) {
      const formattedBoltId = result.data
        .toUpperCase()
        .replaceAll("-", "")
        .replace(/\s+/g, "");

      const isLink = result.data.includes("/main/?id=");
      const code = isLink ? formattedBoltId.split("=")[1] : formattedBoltId;

      setFieldValue("boltCode", code);
      setQrCode(formattedBoltId);

      handleConfirm();
    }
  };
  const handleError = (error: any) => {
    if (error.toString().includes("Permission denied")) {
      setCameraDenied(true);
    }
  };

  const handleClear = () => {
    setFieldValue(name, "");
    setQrCode("");
  };

  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        handleClose={handleCloseActivator}
        handleConfirm={handleConfirm}
        changeFotter
      >
        <>
          <ContainerActivator>
            <div>
              <div>
                <span>{t("BoltActivator.boltId")}</span>
                <input
                  disabled={!qrCode ? false : true}
                  value={boltCode}
                  onChange={handleActivatorIdChange}
                  onKeyDown={(e) => blockInvalidCharacter(e, { activator: true })}
                />
              </div>
              {qrCode && (
                <button onClick={handleClear}>
                  {t("BoltActivator.clear")}
                </button>
              )}
            </div>
            {show &&
              (!qrCode && !cameraDenied && camera ? (
                <QRCodeReader
                  delay={delay}
                  handleError={handleError}
                  handleScan={handleScan}
                />
              ) : cameraDenied ? (
                <div>
                  <label>{t("BoltActivator.noCameraPermission")}</label>
                </div>
              ) : (
                <div>
                  <QRCode size={250} id="QRCode" value={qrCode} />
                </div>
              ))}
          </ContainerActivator>

          <ActivatorFooter>
            <ButtonCancel onClick={handleCloseActivator}>
              {t("BoltActivator.cancel")}
            </ButtonCancel>
            <ButtonSave onClick={handleConfirm}>
              {t("BoltActivator.save")}
            </ButtonSave>
          </ActivatorFooter>
        </>
      </Modal>
    </>
  );
};

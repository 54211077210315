import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setProfileNotification,
  setUserDashboardsPreferences,
  userSelect,
} from "../store/slices/userSlice";
import useApi from "./useApi";
import {
  appSetNewRelease,
  setAppIsReadOnly,
  setCustomItems,
  setLanguage,
} from "../store/slices/appSlice";
import i18n from "../i18n";
import { useCallback, useEffect, useState } from "react";
import { setSelectedIcons } from "../store/slices/navbarIconsSlice";

const getLanguageCode = (language: string) => {
  switch (language) {
    case "PORTUGUESE":
      return "pt";
    case "ENGLISH":
      return "en";
    case "SPANISH":
      return "es";
    default:
      return "pt";
  }
};

export default function useUser() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelect);
  const { id: userId, terms: isTermsAccepted } = user;

  const [shouldShowMissingInformationModal, setMissingInformationModal] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { request } = useApi({ path: `/users` });

  function shouldClearCache({ newVersion }: any) {
    const currentVersion = localStorage.getItem("retinaVersion");

    if (!currentVersion) {
      localStorage.setItem("retinaVersion", newVersion);
    }

    if (currentVersion && newVersion !== currentVersion) {
      const { pathname } = location;

      localStorage.setItem("retinaVersion", newVersion);

      if (pathname !== "/clear-cache") {
        navigate(`/clear-cache?from=${pathname}`);
        return;
      }
    }
  }

  const loadUser = useCallback(async (argsUser?: any) => {
    const pathParameters = argsUser?.id || userId;
    const response = await request({ method: "get", pathParameters });
    handleLoadUser(response);

    return response;
    // eslint-disable-next-line
  }, []);

  function handleLoadUser(response: any) {
    if (response.profile === "CLIENTE_MASTER_VIEW") {
      dispatch(setAppIsReadOnly(true));
    }

    shouldClearCache({ newVersion: response.retinaVersion });

    dispatch(setProfileNotification(response.configurationNotificationTypes));

    dispatch(setLanguage(response?.language));
    dispatch(setCustomItems(response.customItems));
    dispatch(
      setUserDashboardsPreferences(response?.dashboardsPreference || {}),
    );
    dispatch(setSelectedIcons(response.customItems?.icons ?? []));

    const language = getLanguageCode(response.language);
    const getLocalLanguage = localStorage.getItem("language");
    i18n.changeLanguage(
      getLocalLanguage && getLocalLanguage !== language
        ? getLocalLanguage
        : language,
    );
  }

  function loadNotifications() {
    if (process.env.REACT_APP_IS_LOCAL === "true") {
      return;
    }

    const hasServiceWorker = "serviceWorker" in navigator;

    if (hasServiceWorker && userId && Math.max(isTermsAccepted, 0)) {
      navigator.serviceWorker
        .register(`${process.env.REACT_APP_URL_HOST}/service-worker.js`)
        .then(async (serviceWorker) => {
          let subscription = await serviceWorker.pushManager.getSubscription();

          if (!subscription) {
            const publicKey =
              process.env.REACT_APP_PUSH_NOTIFICATION_PUBLIC_KEY;

            subscription = await serviceWorker.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: publicKey,
            });
          }
          await request({
            method: "post",
            pathParameters: `${userId}/push-subscribe`,
            body: { subscription },
          });
        })
        .catch((err) => {
          console.log("Failed to register service worker", err);
        });
    }
  }

  function loadVersion() {
    if (process.env.REACT_APP_IS_LOCAL === "true") {
      return;
    }

    request({ method: "get", pathParameters: userId }).then((response: any) => {
      shouldClearCache({ newVersion: response.retinaVersion });
    });
  }

  /**
   * @description Carrega informações de anúncios para o usuário atual. Esta função verifica se o aplicativo está em modo local e a necessidade de verificar novos lançamentos antes de fazer a solicitação.
   *
   * @example
   *  const { loadAnnouncements } = useUser();
   *  loadAnnouncements(setAnnouncementInformation);
   *
   * @param setAnnouncementInformation Uma função callback que é chamada com as informações dos anúncios após o carregamento bem-sucedido. Deve aceitar um parâmetro que é o objeto de resposta da solicitação.
   *
   * @returns {void} Não retorna nada.
   */

  function loadAnnouncements(setAnnouncementInformation: (args: any) => void) {
    if (process.env.REACT_APP_IS_LOCAL === "true") {
      return;
    }

    const checkRelease = process.env.REACT_APP_CHECK_RELEASE;
    const shouldCheckRelease = checkRelease && !!+checkRelease;

    if (userId && isTermsAccepted && shouldCheckRelease) {
      request({ method: "get", pathParameters: `${userId}/announcements` })
        .then((response) => {
          setAnnouncementInformation(response);
        })
        .catch(() => dispatch(appSetNewRelease(true)));
    }
  }

  /**
   * @description Confere se há notificações para que o usuário complete seu cadastro por completo (nome completo e telefone). Caso tenha, irá setar o stat "setMissingInformationModal" para true
   * Esse setState, e o state são exportados para fora para que possam ser usados.
   *  
   * @param apiResponse 
   * 
   * @example const { shouldOpenMissingInformationModal,
                      handleCloseMissingInformationModal,
                      shouldShowMissingInformationModal}   = useUser()
   */
  function shouldOpenMissingInformationModal(apiResponse: any) {
    const fullName = apiResponse?.name?.split(" ").length > 1;
    const hasPhone = apiResponse?.phone !== null;

    if(fullName && hasPhone) {
      return;
    }

    function deactiveJoyRide() {
      localStorage.setItem('retina:JoyrideCompanyFacilitie', 'false')
    } 

    const hasMissingInformation =
      apiResponse?.configurationNotificationTypes?.includes("USER_PROFILE");

    const lastTimeUserClickedToCloseModal = JSON.parse(
      localStorage.getItem("missingInformation") || "{}",
    );

    const today = new Date().getTime();

    const DAY_EPOCH = 60 * 60 * 24 * 1000;

    const hasPassedOneDaySinceUserRefuseToComplete =
      today - +lastTimeUserClickedToCloseModal.date > DAY_EPOCH;

    if (
      (hasMissingInformation &&
        hasPassedOneDaySinceUserRefuseToComplete &&
        user.id === lastTimeUserClickedToCloseModal.userId) ||
      !lastTimeUserClickedToCloseModal.date
    ) {
      deactiveJoyRide();
      setMissingInformationModal(true);
    }
  }

  return {
    loadUser,
    loadNotifications,
    loadVersion,
    loadAnnouncements,
    request,
    handleLoadUser,
    shouldOpenMissingInformationModal,
    handleCloseMissingInformationModal: () => setMissingInformationModal(false),
    shouldShowMissingInformationModal,
  };
}

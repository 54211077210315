import { useState } from "react";
import { Modal } from "../../components/Modal";
import useApi from "../../hooks/useApi";
import { Container, Welcome } from "./styles";
import { ESGprops } from "./types";
import { success } from "../../components/Toast";
import { blockInvalidCharacter } from "../../utils/blockInvalidCharacter";
import { useLocation } from "react-router-dom";
import queryString from "../../utils/queryString";
import { useTranslation } from "react-i18next";

export const ESGForm = ({
  show,
  handleClose,
  setShow,
  averageKWhConsumption,
  refresh,
}: ESGprops) => {
  const { t } = useTranslation();

  const location = useLocation();

  const companyId = location.search
    ? queryString.parse(location.search)?.companyId
    : "ibbx";

  const { request } = useApi({ path: `/companies/${companyId}/esg-summary` });

  const [value, setValue] = useState<any>(averageKWhConsumption || null);
  const [error, setError] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleChange = (e: any) => {
    // setValue(e.replaceAll(",", ".").replaceAll("e", ""));
    const newValue = e.replaceAll(",", ".").replaceAll("e", "");
    if (newValue.length <= 5) {
      setValue(newValue);
    }
    if (e) {
      setError(false);
    }
  };

  const handleConfirm = () => {
    if (value) {
      setProcessing(true);
      request({
        method: "put",
        body: {
          averageKWhConsumption: value,
        },
      }).then((response) => {
        success(`${t("ESGForm.confirmMessage")}`);
        setShow(false);
        setProcessing(false);
      });
    } else {
      setError(true);
    }
  };

  const handlePressEnter = (e: any) => {
    if (e.key === "Enter") {
      handleConfirm();
    }
  };
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      size="lg"
      handleConfirm={handleConfirm}
      backgroundColor={"#f6f8ee"}
      borderColor={"#f6f8ee"}
      confirmModalText={
        !averageKWhConsumption ? `${t("ESGForm.confirmExitModal")}` : ""
      }
      confirmLabel={processing ? `${t("ESGForm.saving")}` : ""}
      confirmCancel
    >
      <>
        <Container error={error}>
          <Welcome>
            <label>{`${t("ESGForm.welcomeTitle")}`}</label>
            <label>{`${t("ESGForm.metricsParagraph")}`}</label>
          </Welcome>
          <div>
            <label>{`${t("ESGForm.calcParagraph")}`}</label>
            <input
              onChange={(e) => handleChange(e.target.value)}
              placeholder={`${t("ESGForm.inputAverageCost")}`}
              type="number"
              className="no-spinners"
              onKeyDown={(e) => {
                blockInvalidCharacter(e, { positive: true });
                handlePressEnter(e);
              }}
              value={value}
            />
            {error && <label>{`${t("ESGForm.esgErrorMessage")}`}</label>}
          </div>
        </Container>
      </>
    </Modal>
  );
};

// import { useState, useRef } from "react";
import { useTheme } from "styled-components";
import {
  Body,
  BoltDetails,
  CardFooter,
  Container,
  ContainerContent,
  Header,
  SensorDetails,
  SensorImageContainer,
  SensorInformationsContainer,
  TxTime,
} from "./styles";
import { CardSensorProps } from "./types";
import { SensorIcon } from "../SensorIcon";
import { MdOutlineSensors } from "react-icons/md";
import { SlClock } from "react-icons/sl";
import { AiOutlineLoading } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ModalSensorStatus } from "../ModalSensorStatus";
import { TbBattery1, TbBattery3 } from "react-icons/tb";
import { isIOS } from "react-device-detect";

const renderNextTxTime = (txTime: any) => {
  return txTime.length ? (
    <div>
      <label style={{ marginLeft: "4px" }}>
        {new Intl.DateTimeFormat("pt-BR", {
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(txTime[0].date))}
        h
      </label>
    </div>
  ) : null;
};

const CardSensor = ({ position, txTime }: CardSensorProps) => {
  const [showRSSIHistoryModal, setShowRSSIHistoryModal] = useState(false);

  const [showBattteryChartModal, setShowBatteryChartModal] =
    useState<boolean>(false);

  const { colors } = useTheme();

  const { t } = useTranslation();

  const handleOpenRSSIHistoryModal = (e: any) => {
    e.preventDefault();

    setShowRSSIHistoryModal(true);
  };

  const handleCloseRSSIHistoryModal = () => {
    setShowRSSIHistoryModal(false);
  };

  const handleOpenBatteryChart = (e: any) => {
    e.preventDefault();
    setShowBatteryChartModal(true);
  };

  const handleCloseBatteryChart = () => {
    setShowBatteryChartModal(false);
  };

  return (
    <Container>
      <ContainerContent
        synchronized={position.schedule?.synchronized}
        to={`/positions/${position.positionId}`}
      >
        <SensorImageContainer
          synchronized={position.schedule?.synchronized}
          isIosDevices={isIOS}
        >
          {position.schedule?.synchronized ? (
            <div>
              <SensorIcon
                color={
                  position.schedule?.synchronized
                    ? colors.statusNormal
                    : colors.statusRisk
                }
                id={position.sensorTypeId}
              />
            </div>
          ) : (
            <div>
              <SensorIcon
                color={
                  position.schedule?.synchronized
                    ? colors.statusNormal
                    : colors.statusRisk
                }
                id={position.sensorTypeId}
              />
            </div>
          )}
        </SensorImageContainer>
        <SensorInformationsContainer>
          <Header>
            <SensorDetails>
              <span>{position?.positionName}</span>
            </SensorDetails>
            {position?.bolt && (
              <>
                <TxTime>
                  <div data-tip data-for="nextTX">
                    <div>
                      <AiOutlineLoading
                        color={
                          txTime[0]?.label === "D"
                            ? colors.iconsPrimary
                            : colors.iconsSecondary
                        }
                        size={20}
                      />
                      <SlClock size={14} />
                    </div>
                    <span>{txTime && renderNextTxTime(txTime)}</span>
                  </div>
                </TxTime>
                <ReactTooltip
                  id="nextTX"
                  type="dark"
                  place="top"
                  effect="solid"
                  // delayShow={500}
                >
                  {t("CardSensor.transmissionTime")}
                </ReactTooltip>
              </>
            )}
          </Header>
          <Body>
            <BoltDetails>
              <div>{position.bolt?.name}</div>
            </BoltDetails>
            <CardFooter>
              {position?.batteryVoltage && (
                <div onClick={handleOpenBatteryChart}>
                  {+position?.batteryVoltage > 3.3 ? (
                    <TbBattery3 size={16} />
                  ) : (
                    <TbBattery1 size={16} />
                  )}
                  <span>{position?.batteryVoltage}V</span>
                </div>
              )}
              {position.schedule?.rssi ? (
                <div onClick={handleOpenRSSIHistoryModal}>
                  {" "}
                  <MdOutlineSensors size={16} />
                  {/* <span>RSSI:&nbsp;</span> */}
                  <span>{position.schedule?.rssi.toFixed(2)}</span>
                </div>
              ) : null}
            </CardFooter>
          </Body>
        </SensorInformationsContainer>
      </ContainerContent>
      {showRSSIHistoryModal && (
        <ModalSensorStatus
          show={showRSSIHistoryModal}
          handleClose={handleCloseRSSIHistoryModal}
          position={position}
          detail={""}
        />
      )}
      {showBattteryChartModal && (
        <ModalSensorStatus
          show={showBattteryChartModal}
          handleClose={handleCloseBatteryChart}
          position={position}
          detail={"battery"}
        />
      )}
    </Container>
  );
};

export default CardSensor;

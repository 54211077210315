import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import theme from "../../styles/themes/default";

export const pumpSignal = keyframes`
from
{
  transform: scale(.65) ;
  opacity: 0;
}
50%
{
  opacity: 1;
}
to
{
  transform: scale(1.3);
  opacity: 0;
}
`;

export const Container = styled.div`
  position: relative;
  display: block;
  float: left;
  width: 20%;
  padding: 0 8px 16px 8px;

  @media screen AND (min-width: 1980px) {
    width: 14.25%;
  }

  @media screen AND (max-width: 1600px) {
    width: 25%;
  }

  @media screen AND (max-width: 1200px) {
    width: 33.3%;
  }

  @media screen AND (max-width: 1200px) {
    width: 49.8%;
  }

  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    width: 49.8%;
  }

  @media screen AND (max-width: 767px) {
    width: 100%;
    &:last-child {
      margin-bottom: 6rem;
    }
  }
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    width: 100%;
  }
`;

export const borderAnimation = keyframes`
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SensorImageContainer = styled.div<{
  synchronized?: boolean;
  isIosDevices?: boolean;
}>`
  width: 22%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${(props) => props.theme.colors.cardHighlight};

  div {
    position: relative;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.divBackgroundAlpha};
    border-radius: 6px;
    overflow: hidden;
  }
  div::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 140%;
    background-color: ${(props) =>
      props.synchronized ? theme.colors.statusNormal : theme.colors.statusRisk};
    filter: blur(5px);
    animation: ${borderAnimation} 5s linear infinite;
    ${({ isIosDevices }) =>
      isIosDevices &&
      css`
        display: none;
      `}
  }
  div::after {
    content: "";
    position: absolute;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    inset: 2px;
    border-radius: 4px;
  }

  svg {
    width: 30px;
    height: auto;
    stroke: ${(props) =>
      props.synchronized ? theme.colors.statusNormal : theme.colors.statusRisk};
    z-index: 3;
  }

  img {
    width: 30px;
    height: auto;
  }
`;

export const SensorInformationsContainer = styled.div`
  width: 78%;
  padding: 0px 8px;
`;

export const ContainerContent = styled(Link)<{ synchronized?: boolean }>`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  display: flex;
  align-items: center;
  height: 90px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  opacity: ${(props) => (props.synchronized ? "1" : "0.4")};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & > div {
  }

  @media (max-width: 767px) {
    margin-left: 0;
    width: 93vw;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  ${(props) => props.theme.fonts.primary.H3SemiBold}
`;
export const RotatingSpinner = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;
export const TxTime = styled.div`
  align-items: center;
  display: flex;
  span {
    ${(props) => props.theme.fonts.secondary.H1Regular};
  }

  div {
    display: flex;
    align-items: center;
    & > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 20px;
      height: 20px;

      span {
        align-self: center;
        margin-left: 10px;
      }

      svg:first-child {
        position: absolute;
        animation: ${RotatingSpinner} 2s linear infinite;
      }

      label {
        ${(props) => props.theme.fonts.primary.H5Regular};
      }
    }
  }
`;

export const SensorStatus = styled.div<{ show?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 3px 0;
  background-color: ${(props) => props.theme.colors.statusNormal};
  border-radius: 20px;
  opacity: ${(props) => (props.show ? "1" : "0")};
`;

export const ActionButton = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  color: ${(props) => props.theme.colors.iconsSecondary};
  height: 26px;
  width: 26px;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};

    border-radius: 60px;
    cursor: pointer;
  }

  & > * {
    pointer-events: none;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${(props) => props.theme.fonts.primary.H3SemiBold};

  & > div:nth-child(1) {
    ${(props) => props.theme.fonts.primary.H5Regular};
    margin-bottom: 4px;
  }
`;

export const SensorDetails = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 60%;
  margin-bottom: 4px;
`;

export const BoltDetails = styled.div`
  ${(props) => props.theme.fonts.primary.H4Regular};
  display: flex;
  align-items: center !important;
  justify-content: space-between;

  & > div:first-child {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90px;
    overflow-x: hidden;
  }

  span {
    ${(props) => props.theme.fonts.secondary.H1Regular};
  }
  svg {
    margin-right: 6px;
    animation: ${pumpSignal} 2.5s ease-in-out infinite;
  }
`;

export const CardFooter = styled.footer`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  span {
    ${(props) => props.theme.fonts.secondary.H1Regular};
  }
  svg {
    margin-right: 6px;
  }

  & div {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

import { useRef } from "react";

import useUser from "../../hooks/useUser";
import useApi from "../../hooks/useApi";

import { MdOutlineSupportAgent } from "react-icons/md";
import { Container } from "./style";

export function HelpButton() {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { request } = useApi({ path: "" });
  const { loadUser } = useUser();

  const handleRedirectToHelpCenterSSO = async () => {
    const user = await loadUser();

    request({
      method: "post",
      pathParameters: `zendesk/auth/${user.id}?type=helpCenter`,
    }).then((response) => {
      if (inputRef.current) {
        inputRef.current.value = response.token;
        if (formRef.current) {
          formRef.current.submit();
        }
      }
    });
  };

  return (
    <>
      <Container to="#" onClick={handleRedirectToHelpCenterSSO}>
        <MdOutlineSupportAgent />
      </Container>

      <form
        ref={formRef}
        action={`https://ibbxsupport.zendesk.com/access/jwt`}
        method="post"
        target="_blank"
      >
        <input ref={inputRef} type="hidden" name="jwt" />
      </form>
    </>
  );
}

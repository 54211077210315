import { IAsset } from "../../store/interfaces";
import theme from "../../styles/themes/default";

export const EMPTY_ASSET: IAsset = {
  id: 0,
  name: "",
  companyId: 0,
  companyName: "",
  facilityId: 0,
  facilityName: "",
  contract: "PaaS",
  sysStatusId: 1,
  operationStatus: "AVAILABLE",
  tabs: [],
  isReadOnly: false,
  deniedPermissions: {},
};

export const OPERATION_STATUS_COLOR: {
  PLANNED_STOP: string;
  UNPLANNED_STOP: string;
  AVAILABLE: string;
} = {
  PLANNED_STOP: theme.colors.statusAlert,
  UNPLANNED_STOP: theme.colors.statusRisk,
  AVAILABLE: theme.colors.statusNormal,
};

import styled from "styled-components";

export const Toogle = styled.button`
  border: none;
  background-color: transparent;
`;

export const DrawerContainer = styled.div`
  @media screen and (min-width: 767px) and (max-width: 1535px) {
    .EZDrawer__container {
      right: -92px !important;
    }
  }
`;

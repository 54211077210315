type options = {
  integer?: boolean;
  positive?: boolean;
  activator?: boolean;
  value?: any;
};

export const blockInvalidCharacter = (
  e: {
    key: string;
    preventDefault: () => any;
    target: any;
  },
  options?: options,
) => {
  const allowableKeys = [
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Enter"
  ];
  if (allowableKeys.includes(e.key)) {
    return;
  }

  let regex = /^[0-9.,-]$/;

  if (options?.positive) {
    regex = /^[0-9.,]$/;
  }

  if (options?.integer) {
    regex = /^[0-9-]$/;
  }

  if (options?.positive && options.integer) {
    regex = /^[0-9]$/;
  }

  if (options?.activator) {
    regex = /^[a-zA-Z0-9]+$/;
  }

  // Ensure hyphen is only allowed as the first character
  if (e.key === "-" && e.target.selectionStart !== 0) {
    e.preventDefault();
  }

  if ([",", "."].includes(e.key) && e.target.selectionStart === 0) {
    e.preventDefault();
  }

  if (!regex.test(e.key)) {
    e.preventDefault();
  }
};

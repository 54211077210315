import { Form, Formik, FormikProps } from "formik";
import { AssetTypeRegisterContainer, AssetTypeRegisterFooter } from "./styles";
import { FormikTextInput } from "../../../../components/textInput";
import { useTranslation } from "react-i18next";
import { AssetTypeDiagnostics } from "./AssetTypeDiagnostics";
import { AssetTypeImage } from "./AssetTypeImage";
import useApi from "../../../../hooks/useApi";
import { success } from "../../../../components/Toast";
import { useEffect, useRef, useState } from "react";
import ModalCompound from "../../../../components/ModalCompound";
import { DiagnosticsDrawer } from "../DiagnosticsDrawer";
import ButtonAddV2 from "../../../../components/ButtonAddV2";
import useTranslator from "../../../../hooks/useTranslator";

interface IAssetTypeRegister {
  originId?: number | null;
  assetTypeId?: number | null;
  setRegister: (value: any) => void;
  refresh: () => void;
}

export function AssetTypeRegister({
  assetTypeId,
  setRegister,
  refresh
}: IAssetTypeRegister) {
  const [isDiagnosticDrawerOpen, setIsDiagnosticsDrawerOpen] = useState(false);
  const [assetType, setAssetType] = useState<any>({
    nameInPT: "",
    nameInEN: "",
    nameInES: "",
    diagnostics: [],
  });

  const { t } = useTranslation();
  const translator = useTranslator("AssetTypePage");
  const { request, processing } = useApi({ path: "/asset-types" });

  const formRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    if (assetTypeId) {
      loadAssetType();
    }
  }, [assetTypeId]);

  function loadAssetType() {
    request({ method: "get", pathParameters: assetTypeId }).then((response) => {
      formRef.current?.setValues(response);
      setAssetType(response);
    });
  }

  function registerAssetType(body: any) {
    const pathParameters = assetTypeId;
    const method = assetTypeId ? "put" : "post";
    body.name = body.nameInPT || body.nameInEN || body.nameInES;

    request({ method, body, pathParameters }).then(() => {
      success(
        assetTypeId
        ? `${t("AssetTypePage.successMessagePut")}`
        : `${t("AssetTypePage.successMessagePost")}`,
      );
      setRegister({ show: false, assetTypeId: 0, originId: 0 });
      refresh();
    });
  }

  const handleSubmit = (values: any) => {
    if (!processing) {
      registerAssetType(values);
    }
  };

  return (
    <>
      <Formik
        initialValues={assetType}
        onSubmit={handleSubmit}
        innerRef={formRef}
        enableReinitialize
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          let errors: any = {};

          if (!values.nameInPT && !values.nameInEN && !values.nameInES) {
            errors.nameInPT = `${t("AssetTypePage.nameValidate")}`;
            errors.nameInEN = `${t("AssetTypePage.nameValidate")}`;
            errors.nameInES = `${t("AssetTypePage.nameValidate")}`;
          }

          return errors;
        }}
      >
        <Form
          style={{
            display: "initial",
            width: "100%",
            height: "inherit",
          }}
        >
          <AssetTypeRegisterContainer>
            <div>
              <h3>{translator("General Information")}</h3>
              <AssetTypeImage />
              <div className="flex column gap_0_5 w_100">
                <FormikTextInput
                  name="nameInPT"
                  label={translator("Name in Portuguese")}
                  loading={processing}
                />
                <FormikTextInput
                  name="nameInEN"
                  label={translator("Name in English")}
                  loading={processing}
                />
                <FormikTextInput
                  name="nameInES"
                  label={translator("Name in Spanish")}
                  loading={processing}
                />
              </div>
            </div>

            <div>
              <div className="flex space_between w_100">
                <h3>{translator("Linked Diagnostics")}</h3>
                <ButtonAddV2 onClick={() => setIsDiagnosticsDrawerOpen(true)} />
              </div>
              <AssetTypeDiagnostics processing={processing} />
            </div>
          </AssetTypeRegisterContainer>
          <AssetTypeRegisterFooter>
            <ModalCompound.Cancel
              handleClose={() =>
                setRegister({ show: false, originId: 0, assetTypeId: 0 })
              }
            />
            <ModalCompound.Save type="submit" />
          </AssetTypeRegisterFooter>
          {isDiagnosticDrawerOpen && (
            <DiagnosticsDrawer
              isOpen={isDiagnosticDrawerOpen}
              onClose={() => setIsDiagnosticsDrawerOpen(false)}
            />
          )}
        </Form>
      </Formik>
    </>
  );
}

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { AdviseContent, Body, FieldsContainer, Footer, SaveButton } from "./styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setProfileNotification,
  userSelect,
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { ButtonCancel } from "../../components/Modal/styles";
import useApi from "../../hooks/useApi";
import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import { FormikTextInput } from "../../components/textInput";
import { PhoneInputComponent } from "../../components/PhoneInputComponent";
import { error, success } from "../../components/Toast";
import { getUrlPattern } from "../../utils/getUrlPattern";

interface IMissingInformationModal {
  show: boolean;
  handleClose: () => void;
}

export const MissingInformationModal = ({
  show,
  handleClose,
}: IMissingInformationModal) => {
  const { t } = useTranslation();

  const location = useLocation();
  const params = useParams();

  const formRef = useRef<FormikProps<any>>(null);

  const user = useAppSelector(userSelect);

  const path = `/users/${user?.id}`;
  const { request, processing } = useApi({ path });
  const { request: requestUser } = useApi({ path: `/users/${user?.id}/profile`});

  const [initialValues, setInitialValues] = useState({ name: "", phone: "" });

  const getUserInfo = (userId: string) => {
    requestUser({ method: "get" }).then((response) => {
      const userInfo = response[0];
      setInitialValues({
        name: userInfo.name || "",
        phone: userInfo.phone || "",
      });
      formRef.current?.setValues(userInfo);
    });
  };

  const remindUserLater = () => {
    localStorage.setItem(
      "missingInformation",
      JSON.stringify({ userId: user?.id, date: new Date().getTime() }),
    );
    handleClose();
  };
  const dispath = useAppDispatch();

  const handleUpdateForm = (values: any) => {
    values.name = (values.name || "").trim();
    values.phone = (values.phone || "").trim();

    const { name, phone } = values;

    request({ method: "put", body: { name, phone } })
      .then((response) => {
        getUserInfo(user?.id);
        success(`${t("configuration.profile.updatedSuccessMessage")}`);
        dispath(setProfileNotification(""));
        handleClose();
      })
      .catch((err) => {
        error(`${t("configuration.profile.updatedFailedMessage")}`);
        const { data } = err?.response || {};
        if (Array.isArray(data)) {
          data.map(({ field, message }: any) =>
            formRef.current?.setFieldError(field, message),
          );
        }
      });
  };

  useEffect(() => {
    const urlPattern = getUrlPattern(location, params);
    if (user?.terms === 0) {
      return;
    }

    if (user?.id) {
      getUserInfo(user?.id);
    }
    // eslint-disable-next-line
  }, [user, location, user?.id]);

  return (
    <Modal
      show={show}
      handleClose={remindUserLater}
      // handleConfirm={handleUpdateForm}
      changeFotter
      size="lg"
    >
      <Formik
        initialValues={{}}
        innerRef={formRef}
        onSubmit={handleUpdateForm}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values }) => {
          const isSaveDisabled =
            values.name === initialValues.name &&
            values.phone === initialValues.phone;
          return (
            <Body>
              <h2>{t("Complete seu cadastro")}</h2>
              <AdviseContent>
                <span>{t("Attention")}! </span>{' '}
                <span>{t("Reminder incomplete forms.")}</span>
              </AdviseContent>
              <FieldsContainer>
                <FormikTextInput
                  name="name"
                  label={`${t("Full Name")}`}
                  loading={processing}
                  error={errors["name"]}
                />
                <PhoneInputComponent
                  name="phone"
                  label={
                    `${t("configuration.companyDetails.phone")} (WhatsApp)` ||
                    ""
                  }
                  loading={processing}
                />
              </FieldsContainer>

              <Footer>
                <ButtonCancel onClick={remindUserLater} type="button">
                  <span>{t("Remind me later")}</span>
                </ButtonCancel>

                <SaveButton type="submit" disabled={isSaveDisabled} >
                  <span>{t("Update")}</span>
                </SaveButton>
              </Footer>
            </Body>
          );
        }}
      </Formik>
    </Modal>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../hooks/useApi";
import { CheckList } from "../../../CompanyDetails/OccurrenceStandardFields/components/CheckList";
import { useField, useFormikContext } from "formik";
import { map } from "lodash";
import InputList from "../../../../../components/InputList";
import { FormikTextInput } from "../../../../../components/textInput";
import { any } from "cypress/types/bluebird";
import { removeNulls } from "../../../../../utils/arrayUtils";
import useTranslator from "../../../../../hooks/useTranslator";
import { AssetTypeDiagnosticsItemWrapper } from "./styles";
import { isMobile } from "../../../../../utils/mediaQuery";

interface IAssetTypeDiagnostics {
  processing: boolean;
}

interface IListDiagnostic {
  id: number;
  name: string;
  recommendation: string;
}

export function AssetTypeDiagnostics({ processing }: IAssetTypeDiagnostics) {
  const [diagnostics, setDiagnostics] = useState<IListDiagnostic[]>([]);

  const { t, i18n } = useTranslation();
  const translator = useTranslator("AssetTypePage");
  const { setFieldValue } = useFormikContext();
  const [{ value }] = useField("diagnostics");
  const { request } = useApi({ path: "/diagnostics" });

  useEffect(() => {
    loadDiagnostics();
  }, []);

  function loadDiagnostics() {
    request({
      method: "get",
      queryStringParameters: { linkedAssetTypeId: 0 },
    }).then((response: IListDiagnostic[]) => {
      setDiagnostics(response);
    });
  }

  const handleDelete = (index: number) => {
    const result = value.map((item: any, itemIndex: number) => {
      if (index !== itemIndex) {
        return item;
      }

      if (item?.id) {
        item.deleted = true;
        return item;
      }

      return null;
    });

    setFieldValue("diagnostics", removeNulls(result));
  };

  const buildInputItemMobile = (input: any, inputIndex: number) => {
    if (input.deleted) {
      return <></>;
    }

    return (
      <InputList.Column>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInPT]`}
            label={translator("Diagnostic in Portuguese")}
            order={i18n.language === "pt" ? 1 : 3}
            loading={processing}
          />
          <InputList.Delete
            handleClick={() => handleDelete(inputIndex)}
            hoverLabel={`${t("OccurrenceForm.diagnosticDeleteLabel")}`}
            order={4}
          />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInEN]`}
            label={translator("Diagnostic in English")}
            order={i18n.language === "en" ? 1 : 2}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInES]`}
            label={translator("Diagnostic in Spanish")}
            order={i18n.language === "es" ? 1 : i18n.language === "en" ? 2 : 3}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInPT]`}
            label={translator("Recommendation in Portuguese")}
            order={i18n.language === "pt" ? 1 : 3}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInEN]`}
            label={translator("Recommendation in English")}
            order={i18n.language === "en" ? 1 : 2}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInES]`}
            label={translator("Recommendation in Spanish")}
            order={i18n.language === "es" ? 1 : i18n.language === "en" ? 2 : 3}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
      </InputList.Column>
    );
  };

  const buildInputItem = (input: any, inputIndex: number) => {
    if (input.deleted) {
      return <></>;
    }

    return (
      <InputList.Column>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInPT]`}
            label={translator("Diagnostic in Portuguese")}
            order={i18n.language === "pt" ? 1 : 3}
            loading={processing}
          />
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInEN]`}
            label={translator("Diagnostic in English")}
            order={i18n.language === "en" ? 1 : 2}
            loading={processing}
          />
          <FormikTextInput
            name={`diagnostics[${inputIndex}][nameInES]`}
            label={translator("Diagnostic in Spanish")}
            order={i18n.language === "es" ? 1 : i18n.language === "en" ? 2 : 3}
            loading={processing}
          />
          <InputList.Delete
            handleClick={() => handleDelete(inputIndex)}
            hoverLabel={`${t("OccurrenceForm.diagnosticDeleteLabel")}`}
            order={4}
          />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInPT]`}
            label={translator("Recommendation in Portuguese")}
            order={i18n.language === "pt" ? 1 : 3}
            loading={processing}
          />
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInEN]`}
            label={translator("Recommendation in English")}
            order={i18n.language === "en" ? 1 : 2}
            loading={processing}
          />
          <FormikTextInput
            name={`diagnostics[${inputIndex}][sugestionInES]`}
            label={translator("Recommendation in Spanish")}
            order={i18n.language === "es" ? 1 : i18n.language === "en" ? 2 : 3}
            loading={processing}
          />
          <InputList.DeletePlaceholder order={4} />
        </InputList.Row>
      </InputList.Column>
    );
  };

  return (
    <div className="gap_1" style={{ height: "100%", width: "100%" }}>
      <InputList
        name={`diagnostics`}
        build={isMobile() ? buildInputItemMobile : buildInputItem}
        empty={{
          nameInPT: "",
          nameInEN: "",
          nameInES: "",
          sugestionInPT: "",
          sugestionInEN: "",
          sugestionInES: "",
        }}
        hideAddButton
        neverEmpty
      />
    </div>
  );
}

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// AWS
import { Amplify, API } from "aws-amplify";
import { awsConfig } from "../awsConfig";
import { error } from "../../components/Toast";
import { notifyDiscordError } from "../../utils/notifyDiscord";
import { PROFILES } from "./userSlice";
import { IPosition } from "../interfaces";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);
const apiRetinaCustomer = awsConfig?.API?.endpoints?.[0]?.name;

export const positionEmpty: IPosition = {
  hasCollectAfterActivate: false,
  isActivated: false,
  imageUrl: "",
  representativeId: 0,
  sysStatusId: 1,
  activatorId: null,
  theme: "",
  isConnected: false,
  id: 0,
  assetId: 0,
  companyId: 0,
  facilityId: 0,
  sysSensorTypeId: 0,
  positionSensorTypeId: 0,
  assetName: "",
  companyName: "",
  facilityName: "",
  positionName: "",
  positionSensorTypeName: "",
  tag: "",
  comments: "",
  logoUrl: "",
  sensorType: {},
  device: {},
  details: {},
  implantation: {},
  emphasys: "",
  sysBoardTypeId: 0,
  monitoringSolutionId: 0,
  isReadOnly: false,
  deniedPermissions: {},
  loadedAt: new Date().getTime(),
};

export interface PositionsFilterModel {
  [key: string]: any;
  emphasys: string;
  loaded: boolean;
  companyId: number;
  facilityId: number;
  assetId: number;
  sensorTypeId: number | null;
  battery: number;
  connectivity: number;
  contract: number;
  failure: boolean;
  activePoint: number;
  connected: number;
  alarmed: number;
  vibration: number;
  energy: number;
  pressure: number;
  temperature: number;
  lighting: number;
  flowrate: number;
  level: number;
  modbus: number;
  modularAnlogue: number;
  "position-name": string;
}

export const EMPTY_POSITIONS_FILTER: PositionsFilterModel = {
  loaded: false,
  companyId: 0,
  facilityId: 0,
  assetId: 0,
  sensorTypeId: 0,
  battery: 0,
  connectivity: 0,
  contract: 0,
  failure: false,
  activePoint: 0,
  connected: 0,
  alarmed: 0,
  emphasys: "",
  vibration: 0,
  energy: 0,
  pressure: 0,
  temperature: 0,
  lighting: 0,
  flowrate: 0,
  level: 0,
  modbus: 0,
  modularAnlogue: 0,
  "position-name": "",
};

interface IPagination {
  page: number;
  totalResults: number;
  totalPages: number;
}

interface SliceState {
  processing: boolean;
  positions: IPosition[];
  positionsFilter: PositionsFilterModel;
  pagination: IPagination;
  position: IPosition | null;
  userFilter: boolean;
  error?: {};
}

const initialState: SliceState = {
  processing: false,
  // processingStatusDetails: false,
  positions: [],
  positionsFilter: EMPTY_POSITIONS_FILTER,
  pagination: { page: 1, totalResults: 0, totalPages: 1 },
  position: null,
  userFilter: false,
};

export const STATUS_POSITION = {
  RISK: "RISCO",
  NORMAL: "NORMAL",
  ALERT: "ALERTA",
  ANALYSIS: "ANÁLISE",
};

export const getPositions = createAsyncThunk(
  "position/positionsGET",
  async (
    queryParams: {
      companyId?: string;
      facilityId?: string;
      assetId?: string;
      page?: number;
      limit?: number;
    },
    { getState },
  ) => {
    const { position } = getState() as RootState;

    const { positionsFilter } = position;

    const queryFilter = {
      battery: positionsFilter.battery !== 0 ? positionsFilter.battery : 0,
      connectivity:
        positionsFilter.connectivity !== 0 ? positionsFilter.connectivity : 0,
      contract: positionsFilter.contract !== 0 ? positionsFilter.contract : 0,
      activePoint:
        positionsFilter.activePoint !== 0 ? positionsFilter.activePoint : 0,
      sensorTypeId: positionsFilter.sensorTypeId,
      connected:
        positionsFilter.connected !== 0 ? positionsFilter.connected : 0,
      alarmed: positionsFilter.alarmed !== 0 ? positionsFilter.alarmed : 0,
      emphasys: positionsFilter.emphasys,
      "position-name": positionsFilter["position-name"],
    };

    const queryStringParameters: { [key: string]: string | number | null } = {
      ...queryParams,
      ...queryFilter,
    };

    // Remove null values
    Object.keys(queryStringParameters).forEach((key: string) => {
      if (!queryStringParameters[key]) {
        delete queryStringParameters[key];
      }
    });

    const response: { data: IPosition[]; pagination: any } = await new Promise(
      (resolve, reject) => {
        const path = `/positions`;
        API.get(apiRetinaCustomer, path, {
          queryStringParameters,
        })
          .then((positions) => {
            resolve(positions);
          })
          .catch(async (error) => {
            await notifyDiscordError({
              title: `Erro na API`,
              description: path,
            });
            reject(error);
          });
      },
    );

    return response;
  },
);

export const getMonitoringTypes = createAsyncThunk(
  "positions/monitoringTypesGET",
  async () => {
    const path = `/monitoringTypes`;

    const data: any = await new Promise((resolve, reject) => {
      API.get(apiRetinaCustomer, path, {})
        .then((response) => {
          resolve(response);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

export const getMonitoringType = createAsyncThunk(
  "positions/monitoringTypeGET",
  async (monitoringTypeId: number) => {
    const path = `/monitoringTypes/${monitoringTypeId}`;

    const data: any = await new Promise((resolve, reject) => {
      API.get(apiRetinaCustomer, path, {})
        .then((response) => {
          resolve(response);
        })
        .catch(async (error) => {
          await notifyDiscordError({
            title: `Erro na API`,
            description: path,
          });
          reject(error);
        });
    });
    return data;
  },
);

//Tipos de rolamento e códigos do rolamento:

export const getBearingType = createAsyncThunk("/bearing-types", async () => {
  const path = "/bearing-types";

  const data: any = await new Promise((resolve, reject) => {
    API.get(apiRetinaCustomer, path, {})
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        await notifyDiscordError({
          title: `Erro na API`,
          description: path,
        });
        reject(error);
      });
  });
  return data;
});

export const getBearingCode = createAsyncThunk("/bearing-codes", async () => {
  const path = "/bearing-codes";

  const data: any = await new Promise((resolve, reject) => {
    API.get(apiRetinaCustomer, path, {})
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        await notifyDiscordError({
          title: `Erro na API`,
          description: path,
        });
        reject(error);
      });
  });
  return data;
});

export const getUserFilter = (profile: string) => {
  const { EXECUTIVO_MASTER, MESA_ANALISTA, MESA_MASTER } = PROFILES;
  const filterString = sessionStorage.getItem("filter") || "{}";

  let filter = { ...EMPTY_POSITIONS_FILTER } as PositionsFilterModel;

  if (filterString !== "null") {
    filter = JSON.parse(filterString);
  } else {
    if ([EXECUTIVO_MASTER, MESA_ANALISTA, MESA_MASTER].includes(profile)) {
      filter.activePoint = 1;
      filter.alarmed = 1;
    }
  }

  filter.loaded = true;

  return filter;
};

const positionSlice = createSlice({
  name: "position",
  initialState: initialState,
  reducers: {
    setPositionsFilters: (
      state,
      action: PayloadAction<PositionsFilterModel | null>,
    ) => {
      state.positionsFilter = action.payload ?? EMPTY_POSITIONS_FILTER;
      state.userFilter = true;
      sessionStorage.setItem("filter", JSON.stringify(action.payload || {}));
    },
    resetUserFilter: (state) => {
      state.userFilter = false;
    },
    clearPositions: (state) => {
      state.positions = [];
    },
    setPosition: (state, action) => {
      state.processing = false;
      if (+action.payload.pagination.page === 1) {
        state.positions = [...action.payload.data];
      } else {
        state.positions = [...state.positions, ...action.payload.data];
      }
      state.pagination = action.payload.pagination;
    },
    setPositionsData: (state, action) => {
      state.positions = action.payload;
    },
    setProcessing: (state) => {
      state.processing = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPositions.pending, (state) => {
        state.processing = true;
      })
      .addCase(getPositions.fulfilled, (state, action) => {
        state.processing = false;
        if (action.payload.pagination.page === 1) {
          state.positions = [...action.payload.data];
        } else {
          state.positions = [...state.positions, ...action.payload.data];
        }
        state.pagination = action.payload.pagination;
      })
      .addCase(getPositions.rejected, (state, action) => {
        state.processing = false;
        error(action.error?.message);
      });
  },
});

export const positionSelect = (state: RootState) => state.position;

export const {
  setPositionsFilters,
  setPosition,
  clearPositions,
  resetUserFilter,
  setProcessing,
  setPositionsData,
} = positionSlice.actions;

export default positionSlice.reducer;

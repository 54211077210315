import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MdPersonOutline, MdLogout, MdClose } from "react-icons/md";
import { ReactComponent as BrazilFlag } from "../../images/brazil.svg";
import { ReactComponent as UsaFlag } from "../../images/united-states.svg";
import { ReactComponent as SpainFlag } from "../../images/spain.svg";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  userSelect,
  userLogout,
  PROFILES,
  setUserDashboardsPreferences,
} from "../../store/slices/userSlice";
import {
  setAppToggleDarkMode,
  appSelect,
  appSetCurrentFacility,
  appSetCurrentAsset,
  appSetCurrentPosition,
  appSetCurrentCompany,
  setDefaultLogo,
  // iconColors,
} from "../../store/slices/appSlice";

import { SwitchChange } from "../Switch";
import { Modal } from "../Modal";
import { TextTermsAndPolicies } from "../TermsAndPolicies";
import { error, success } from "../Toast";
import {
  Container,
  ConteinerIconsNavBar,
  DarkmodeArea,
  DarkmodeButton,
  LanguageButton,
  LanguageContainer,
  LogoutQuestion,
  MenuItem,
  MenuSep,
  ProfileButton,
  Triangle,
} from "./styles";
import { ImageUploadForm } from "../../modals/ImageUploadForm";
import { ModalConfigurationForm } from "../../modals/ConfigurationForm";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { isMobile, isMobileOnly } from "react-device-detect";
import useApi from "../../hooks/useApi";
import queryString from "../../utils/queryString";
import { Badge } from "../Badge";
import { OccurrenceGoTo } from "../OccurrenceGoTo";
import { EsgPanelButtonGoTo } from "../EsgPanelButtonGoTo";
import { HelpButton } from "../HelpPageButton";
import { NotificationButton } from "../NotificationButton";

const MenuProfile = ({ navbarColor }: { navbarColor?: string }) => {
  // Hooks
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const user = useAppSelector(userSelect);
  const location = useLocation();
  const { darkMode, currentCompany } = useAppSelector(appSelect);

  const { configurationNotificationTypes } = user || {};

  const ref = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);
  const [showModalLogo, setShowModalLogo] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalConfiguration, setShowModalConfiguration] = useState(false);
  const [theme_button, setTheme] = useState("dark");

  const [logo, setLogo] = useState("");

  const handleCloseModalLogout = () => setShowModalLogout(false);
  const handleCloseModalTerms = () => setShowModalTerms(false);

  function resetHiearchy() {
    if (!user.isClient) {
      dispatch(appSetCurrentCompany(null));
    }
    dispatch(appSetCurrentFacility(null));
    dispatch(appSetCurrentAsset(null));
    dispatch(appSetCurrentPosition(null));
    dispatch(setUserDashboardsPreferences({}));
  }
  const { request } = useApi({ path: "/representative-logo" });

  const { request: requestUser } = useApi({ path: `/users/${user.id}` });

  // eslint-disable-next-line
  function themeToggler() {
    theme_button === "light" ? setTheme("dark") : setTheme("light");
  }

  // Desconectar
  const logOut = () => {
    const params = queryString.parse(location.search);
    params.logout = "1";
    let newSearchParams = new URLSearchParams(params as any);
    setSearchParams(newSearchParams);

    dispatch(userLogout())
      .unwrap()
      .then(() => {
        if (!darkMode) dispatch(setAppToggleDarkMode(true));
        resetHiearchy();
        dispatch(setDefaultLogo(""));
      });
  };

  const handleDarkMode = (value: boolean) => {
    dispatch(setAppToggleDarkMode(value));
  };

  useOnClickOutSide(ref, () => setShow(false));

  useEffect(() => {
    if (!isMobile && show && !isHovered) {
      let closeMenu = setTimeout(() => setShow(false), 3000);
      return () => {
        clearTimeout(closeMenu);
      };
    }
  }, [show, isHovered]);

  const handleConfirmModalLogo = () => {
    if (!logo) {
      error("Nenhum logo selecionado!");
      return;
    }

    request({ method: "put", body: { data: logo } }).then(({ logoUrl }) => {
      success("Logo Alterada com sucesso!");
      dispatch(setDefaultLogo(logoUrl));
      setShowModalLogo(false);
    });
  };

  const handleConfirmModalLogout = () => {
    logOut();
  };

  const isMesaAnalista = PROFILES.MESA_ANALISTA === user.profile;

  const { i18n } = useTranslation();

  const getLanguageSelected = (option: string) => {
    switch (option) {
      case "pt":
        return "PORTUGUESE";
      case "en":
        return "ENGLISH";
      case "es":
        return "SPANISH";
      default:
        return "PORTUGUESE";
    }
  };

  const updateUserLanguageOnDB = (languageSelected: string) => {
    const language = getLanguageSelected(languageSelected);

    requestUser({ method: "put", body: { language }, skipToast: true });
  };

  const changeRetinaLanguage = (language: string) => {
    i18n.changeLanguage(language);
    updateUserLanguageOnDB(language);
    localStorage.setItem("language", language);
  };

  const handleChangeLanguage = () => {
    if (i18n.resolvedLanguage === "pt") {
      changeRetinaLanguage("en");
    } else if (i18n.resolvedLanguage === "en") {
      changeRetinaLanguage("es");
    } else if (i18n.resolvedLanguage === "es") {
      changeRetinaLanguage("pt");
    }
  };

  const renderLanguageOptions = () => {
    return (
      <LanguageContainer onClick={() => handleChangeLanguage()}>
        <span>{t("MenuProfile.language")}</span>

        <LanguageButton>
          {i18n.resolvedLanguage === "pt" ? (
            <>
              <BrazilFlag />
            </>
          ) : i18n.resolvedLanguage === "en" ? (
            <>
              <UsaFlag />
            </>
          ) : (
            <>
              <SpainFlag />
            </>
          )}
        </LanguageButton>
      </LanguageContainer>
    );
  };

  const isDevelopmentAndQa =
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "qa";

  const renderNavBarIconsInsideMenuProfile = () => {
    return (
      <>
        {isMobileOnly && (
          <ConteinerIconsNavBar>
            <OccurrenceGoTo />
            <EsgPanelButtonGoTo />
            {isDevelopmentAndQa && <HelpButton />}
            <NotificationButton />
          </ConteinerIconsNavBar>
        )}
      </>
    );
  };

  return (
    <div style={{ position: "relative" }} ref={ref}>
      {/* disabled in 25/07/2024 */}
      {!!configurationNotificationTypes && !show && <Badge />}
      <Modal
        handleClose={() => {
          setShowModalLogo(false);
          setLogo("");
        }}
        handleConfirm={handleConfirmModalLogo}
        show={showModalLogo}
      >
        <ImageUploadForm
          handleChangeImage={(newLogo: any) => setLogo(newLogo)}
          image={logo}
          placeholderEmpty={`${t("MenuProfile.clickToChoose")}`}
          placeholderFilled={`${t("MenuProfile.clickToChooseAnother")}`}
        />
      </Modal>

      <Modal
        handleClose={handleCloseModalTerms}
        show={showModalTerms}
        handleConfirm={handleCloseModalTerms}
        confirmLabel={`${t("dictionary.actions.Close")}`}
        hideCancelButton
        size="xl"
      >
        <TextTermsAndPolicies />
      </Modal>
      <Modal
        handleClose={handleCloseModalLogout}
        show={showModalLogout}
        handleConfirm={handleConfirmModalLogout}
        confirmLabel="Sim"
      >
        <LogoutQuestion>
          <MdLogout size={50} />
          <span>{t("MenuProfile.wishToLeave")}</span>
        </LogoutQuestion>
      </Modal>

      <ModalConfigurationForm
        show={showModalConfiguration}
        handleClose={() => setShowModalConfiguration(false)}
      />

      <ProfileButton
        onClick={() => setShow(!show)}
        active={show}
        navbarColor={navbarColor}
      >
        {show ? <MdClose size={30} /> : <MdPersonOutline size={30} />}
      </ProfileButton>
      {show ? (
        <Fragment>
          <Triangle></Triangle>
          <Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {renderNavBarIconsInsideMenuProfile()}
            <p>
              {t("MenuProfile.hello")} {user.name}!
            </p>
            <MenuSep />
            <DarkmodeArea>
              <DarkmodeButton>
                <SwitchChange
                  handleChange={handleDarkMode}
                  darkMode={!darkMode}
                />
              </DarkmodeButton>
            </DarkmodeArea>
            <MenuItem>{renderLanguageOptions()}</MenuItem>

            <MenuItem onClick={() => setShowModalTerms(true)}>
              {t("MenuProfile.terms")}
            </MenuItem>
            {user.profile === PROFILES.REPRESENTANTE && (
              <MenuItem onClick={() => setShowModalLogo(true)}>
                {t("MenuProfile.changeLogo")}
              </MenuItem>
            )}
            <MenuItem
              as={Link}
              to={`/configuration/${
                isMesaAnalista ? "ibbx" : currentCompany.id || "ibbx"
              }/profile`}
            >
              {t("MenuProfile.settings")}
              {user?.configurationNotificationTypes?.includes(
                "USER_PROFILE",
              ) && <Badge className="Badge" />}
            </MenuItem>
            <MenuItem onClick={() => setShowModalLogout(true)}>Logout</MenuItem>
          </Container>
        </Fragment>
      ) : null}
    </div>
  );
};

export default MenuProfile;

import styled from "styled-components";

interface IButtonAddV2Button {
  size: number;
  right?: number;
  top?: number;
}

export const ButtonAddV2Button = styled.button<IButtonAddV2Button>`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.colorTools};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: ${({ right, top }) => {
    if (right || top) {
      return "absolute";
    }
    return "relative"
  }};
  right: ${({ top }) => top && `${top}px`};
  right: ${({ right }) => right && `${right}px`};

  &:hover {
    background-color: ${({ theme }) => theme.colors.ibbxOrange};
  }

  svg {
    color: ${({ theme }) => theme.colors.colorTools};
    font-size: ${({ size}) => `${size}px`};
  }
`;

export const ROUTES_PATH = {
  COMPANIES: "/",
  FACILITIES: "/companies/:companyId/facilities",
  ASSETS: "/companies/:companyId/facilities/:facilityId/assets",
  ASSET: "/companies/:companyId/facilities/:facilityId/assets/:assetId",
  POSITIONS: "/positions/:positionId",
  PROFILE: "/configuration/:companyId/profile",
  COMPANY_DATA: "/configuration/:companyId/details",
  ASSET_TYPES: "/configuration/:companyId/asset-types",
  DIAGNOSTICS: "/configuration/:companyId/diagnostics",
  ALARMS: "/configuration/:companyId/alarms",
  USERS: "/configuration/:companyId/users",
  NEW_USER: "/configuration/:companyId/users/new",
  EDIT_USER: "/configuration/:companyId/users/edit/:userId",
  REPRESENTATIVES: "/configuration/:companyId/representatives",
  OCCURRENCES: "/occurrences",
  OCCURRENCE: "/assets/:assetId/occurrences/:occurrenceId",
  ESG: "/esgPanel",
  COLOR_THEME: "/configuration/:companyId/color_theme",
  NOTIFICATIONS: "/configuration/:companyId/notifications",
  HUB: "/hub",
  COMPANY_CONNECTIVY: "/companies/:companyId/connectivity",
  FACILITY_CONNETIVITY: "/companies/:companyId/facilities/:facilityId/connectivity"
};

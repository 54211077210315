import { useState } from "react";
import { useSegment } from "../../../hooks/useSegmentTrackEvent ";
import { DrawerComponent } from "../../DrawerComponent";
import { Container } from "./styles";
import { isMobileOnly } from "react-device-detect";
import { AnalyticsComponent } from "../AnalyticsComponent";
import { t } from "i18next";
import { SwitchToggle } from "../../SwitchToggle";
import { AnalyticsTab } from "../AnalyticsTab";
import { MosaicTab } from "../MosaicTab";
import { PiFrameCornersBold } from "react-icons/pi";
import { TbFrame } from "react-icons/tb";
import { VscMultipleWindows } from "react-icons/vsc";

interface IAddNewTab {
  url?: string;
  selectPermissionOptions?: any;
  tabs?: any;
  refresh: any;
  blocked?: any;
  onCloseMenu?: () => void;
}

export function AddNewTab({
  url,
  selectPermissionOptions,
  tabs,
  refresh,
  blocked,
  onCloseMenu,
}: IAddNewTab) {
  const { handleTrackEvent } = useSegment();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [multipleFrames, setmultipleFrames] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((prevS) => !prevS);

    handleTrackEvent({
      event: "Menu > Add Nova Aba",
    });
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Container>
      <DrawerComponent
        label={t("Analytics.Adicionar Abas") || ""}
        direction="right"
        isOpen={openDrawer}
        handleOpen={toggleDrawer}
        handleClose={handleClose}
        size={isMobileOnly ? 350 : 600}
      >
        <AnalyticsComponent>
          <AnalyticsComponent.Title
            title={t("Analytics.Adicionar Novo Analytics")}
          />
          <AnalyticsComponent.Beta />
          <SwitchToggle
            switcher={multipleFrames}
            setSwitcher={() => setmultipleFrames(!multipleFrames)}
            switchDirection="right"
            labelLeft={`${t("Analytics.Criar quadro único")}`}
            labelRight={`${t("Analytics.Criar multiplos quadros")}`}
            iconOn={<VscMultipleWindows />}
            iconOff={<PiFrameCornersBold />}
          />

          {multipleFrames ? (
            <MosaicTab
              refresh={refresh}
              selectPermissionOptions={selectPermissionOptions}
              blocked={blocked}
              onCloseMenu={handleClose}
            />
          ) : (
            <AnalyticsTab
              refresh={refresh}
              selectPermissionOptions={selectPermissionOptions}
              blocked={blocked}
              onCloseMenu={handleClose}
            />
          )}
        </AnalyticsComponent>
      </DrawerComponent>
    </Container>
  );
}

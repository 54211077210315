import { Placeholder } from "react-bootstrap";
import { useTheme } from "styled-components";

interface ILoadingInput {
  height?: string;
  width?: string;
}

export const LoadingInput = ({
  height = "36px",
  width = "100%",
}: ILoadingInput) => {
  const { colors } = useTheme();
  return (
    <Placeholder as="div" animation="glow" style={{ height, width }}>
      <Placeholder
        style={{
          width: "100%",
          height: "95%",
          background: colors.iconsSecondaryAlpha,
          borderRadius: "4px",
        }}
      />
    </Placeholder>
  );
};

import styled, { css } from "styled-components";

export const DateInputContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: 4px;
  label {
    color: ${({ theme }) => theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H5Regular};
  }
`;

interface IDateInputFieldContainer {
  label?: string;
  disabled?: boolean;
  error?: string;
}

export const DateInputFieldContainer = styled.div<IDateInputFieldContainer>`
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.fonts.primary.H4Regular};
  min-height: 34px;
  color: ${(props) => props.theme.colors.iconsPrimary};
  background: ${(props) => props.theme.colors.background};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
      &:hover {
        border: 1px solid transparent;
      }
    `};
  &:hover {
    border: 0.5px solid ${(props) => props.theme.colors.IconsTools};
  }

  &:focus-visible {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.IconsTools};
  }

  & .react-datepicker-wrapper {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  &.react-datepicker__input-container {
    padding: 0;
    margin: 0;
  }

  & .DatePicker-container {
    flex-direction: row !important;
    background-color: ${(props) => props.theme.colors.background};
    width: 100%;
    color: ${(props) => props.theme.colors.iconsSecondary};
    border-radius: 6px;
    border: none;
    margin: 0;
    padding: 8px;
    ${(props) => props.theme.fonts.primary.H4Regular};
    outline: none !important;
    height: 100%;
  }

  & .react-datepicker {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    width: 100%;
  }

  & .react-datepicker__month-container {
    flex-direction: row !important;
    width: 100% !important;
  }

  & .react-datepicker__header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-color: ${(props) => props.theme.colors.cardHighlight};
    & div {
      color: ${(props) => props.theme.colors.iconsSecondary} !important;
    }
  }

  & .DatePicker-container-calendar {
    z-index: 1000000000;
    width: 100%;
    min-width: 200px;
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-color: ${(props) => props.theme.colors.cardHighlight};

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      width: 100%;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__day {
      color: ${(props) => props.theme.colors.iconsSecondary};
      border-radius: 100%;
      &:hover {
        background-color: ${(props) => props.theme.colors.cardHighlight};
      }
      &--selected {
        background-color: ${(props) => props.theme.colors.ibbxOrange};
      }
    }
  }

  // Seletores de data e período dos gráficos globais
  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    top: 54px;

    div:first-child {
      margin-right: 0%;
    }
  }
`;

export const ContainerDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  & > div {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 6px;
    ${(props) => props.theme.fonts.primary.H5Bold};
    font-size: 16px !important;

    & > svg {
      margin-right: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.background};
      width: 20px;
      border-radius: 100vw;
      margin-block: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
      height: 100%;
      max-height: 5rem;
      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${(props) => props.theme.colors.iconsSecondary};
    }

    @media screen and (max-width: 767px) {
      font-size: 16px !important;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

import { t } from "i18next";
import { AssetOccurrence } from "../../pages/Occurrence/types";
import { IAsset } from "../../store/interfaces";

export type OccurrencePanelProps = {
  asset: IAsset;
  occurrence: AssetOccurrence;
  occurrenceId: any;
  diagnostics: any[];
  refresh?: () => void;
};

export const getActionTime = (dueDate: any, createdAt: any) => {
  if (!dueDate) {
    return t("occurrencesTab.occurrenceListItem.unassigned");
  }

  const dayMs = 86400000;
  const hoursMs = 3600000;
  const difDateEpoch =
    new Date(dueDate).getTime() - new Date(createdAt).getTime();

  if (difDateEpoch < 0) {
    return t("OccurrenceHistory.Imediato");
  }

  var label = t("OccurrenceHistory.dia");
  var value = 0;
  if (difDateEpoch < dayMs && difDateEpoch > dayMs * -1) {
    label = t("OccurrenceHistory.hora");
    value = Math.floor(difDateEpoch / hoursMs);
  } else {
    value = Math.floor(difDateEpoch / dayMs);
  }

  if (value !== 1) {
    label += "s";
  }

  return `${value} ${label}`;
};

export interface IFacility {
  id: number;
  name: string;
  companyId: number;
  companyName: string;
  addrress?: string;
  number?: string;
  facilyLogoUrl?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  complement?: string;
  activity?: string;
  contract?: "SOLaaS" | "PaaS";
  role?: string;
  email?: string;
  phone?: string;
  facilityContact?: any;
  representativeId?: number;
  logoUrl?: string;
  theme?: any;
  assetCustomFields?: any;
  statusId?: 1 | 2 | 3 | 4 | 5;
  statusCaption?: "RISCO" | "ANÁLISE" | "ALERTA" | "NORMAL";
  implantation?: any;
  tabs: any[];
  isReadOnly?: boolean,
  deniedPermissions?: any;
}

export const EMPTY_FACILITY: IFacility = {
  id: 0,
  name: "",
  companyId: 0,
  companyName: "",
  statusId: 1,
  statusCaption: "NORMAL",
  tabs: [],
};

export interface IAssetListItem {
  id: number;
  name: string;
  tag: string;
  facilitySectorId: number;
  order: number;
  facilityId: number;
  operationStatus: string;
  url: string;
  companyId: number;
  sectorName: string | null;
  sectorColor: string | null;
  countPositions: number;
  sysStatusId: 1 | 2 | 3 | 4 | 5;
  statusCaption: "RISCO" | "ANÁLISE" | "ALERTA" | "NORMAL";
  brokenOccurrenceId?: number | null;
  contract: string;
  isLightOffline?: boolean;
}

export type routePathParams = {
  companyId: string;
  facilityId: string;
};

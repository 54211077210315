import styled, { css } from "styled-components";

interface ContainerProps {
  direction?: "top" | "right" | "bottom" | "left";
}

interface LabelProps {
  direction?: "top" | "right" | "bottom" | "left";
}

export const Container = styled.div<ContainerProps>`
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: opacity 0.3s ease-in-out;

  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${(props) => props.theme.colors.iconsPrimary};
    opacity: 0.8;
    margin-right: 8px;
  }

  ${({ direction }) =>
    direction === "top" &&
    css`
      flex-direction: column-reverse;
    `}

  ${({ direction }) =>
    direction === "right" &&
    css`
      flex-direction: row;
    `}

  ${({ direction }) =>
    direction === "bottom" &&
    css`
      flex-direction: column;
    `}

  ${({ direction }) =>
    direction === "left" &&
    css`
      flex-direction: row-reverse;
    `}

  &:hover {
    /* opacity: 1;
    border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
    background-color: ${(props) => props.theme.colors.divBackgroundAlpha}; */
    transition: 0.3s;
  }
`;

export const Label = styled.label<LabelProps>`
  ${(props) => props.theme.fonts.primary.H4Regular};

  ${({ direction }) =>
    direction === "top" &&
    css`
      margin-bottom: 10px;
    `}

  ${({ direction }) =>
    direction === "right" &&
    css`
      margin-left: 10px;
    `}

  ${({ direction }) =>
    direction === "bottom" &&
    css`
      margin-top: 10px;
    `}

  ${({ direction }) =>
    direction === "left" &&
    css`
      margin-right: 10px;
    `}
`;

export const Switcher = styled.div<{ toggleSwitch?: boolean }>`
  position: relative;
  width: 3.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.iconsSecondaryAlpha};
  border-radius: 16px;

  border: 1px solid ${(props) => props.theme.colors.cardBackground};

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};
  }
`;

export const ToggleCircle = styled.div<{ toggleSwitch?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${(props) => props.theme.colors.colorTools};
  position: absolute;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  right: ${(props) => (props.toggleSwitch ? "5%" : "55%")};
  transition: 0.4s;

  svg {
    color: ${(props) => props.theme.colors.cardUnderlineLight} !important;
  }
`;

import styled from "styled-components";

export const InputCompoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label.text_center {
    text-align: center;
  }

  & > label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
  }

  input[type="number"],
  input[type="text"] {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border-radius: 4px;
    padding: 8px;
    height: 34px;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputCompoundFieldContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4px;

  label {
    ${(props) => props.theme.fonts.primary.H5Regular};
    color: ${({ theme }) => theme.colors.iconsPrimary};
    /* margin-bottom: 4px; */
  }

  span {
    ${(props) => props.theme.fonts.secondary.H3Regular};
    color: red;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    /* color: ${({ theme }) => theme.colors.cardHighlight}; */
  }

  input[type="number"],
  input[type="text"] {
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${({ theme }) => theme.fonts.primary.H4Regular};
    border-radius: 4px;
    padding: 8px;
    height: 34px;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  input {
    height: 36px;
  }

  @media screen and (max-width: 767px) {
    & > input {
      font-size: 1rem !important;
    }
  }
`;

export const InputCompoundLabelContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const InputCompoundLabel = styled.label`
  ${(props) => props.theme.fonts.primary.H5Regular};
  color: ${({ theme }) => theme.colors.iconsPrimary};
`;

export const InputCompoundCheckLabel = styled.label`
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
  gap: 6px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  /* padding: 2px 0; */
  align-items: flex-end;
  ${(props) => props.theme.fonts.primary.H5Regular};
  line-height: 13px;
  color: ${(props) => props.theme.colors.textSecondary};
  user-select: none;

  input {
    height: 13px;
    width: 13px;
  }

  & .form-check-input {
    cursor: pointer;
    margin-top: 0;
    padding: 0;
  }

  & .form-check {
    padding-left: 0;
    min-height: 0;
  }

  .form-check-input:checked {
    background-color: ${(props) => props.theme.colors.ibbxOrange} !important;
    border-color: ${(props) => props.theme.colors.ibbxOrange} !important;
  }

  .form-check-input {
    background-color: ${(props) => props.theme.colors.iconsSecondary};
    border-color: ${(props) => props.theme.colors.iconsSecondary};
  }
`;
